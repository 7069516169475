// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const defaultForm = {
  subzone_code: '',
  display_name: '',
  short_name: '',
  address: '',
  zone_id: '',
  conession_commerce_id: '63882e1dbb7e036a8ee93fe5',
  allowed_start_time: '',
  allowed_end_time: '',
  billing_type: '',
  subzone_type: '',
  latitude: 10,
  longitude: 10,
  // hourly_rate_properties: {
  rate_per_time_step: 0,
  time_step: 0,
  max_time: 0,
  // },
  // fixed_rate_properties: {
  rate_per_day: 0
  // }
}

const initialState = {
  data: [],
  statePagination: false,
  showModal: false,
  isUpdate: false,
  subzoneForm: defaultForm,
  updateForm: defaultForm,
  zoneList: [{ value: undefined, label: 'Seleccione' }]
}

export const superadminSubzoneSlice = createSlice({
  name: 'superadminSubzone',
  initialState,
  reducers: {
    setSubzones: (state, action) => {
      state.data = action.payload.items
      state.statePagination = action.payload.nextPageExists
    },
    setOpenModal: (state, action) => {
      state.showModal = action.payload
    },
    setsubSubzone: (state, action) => {
      state.updateForm = action.payload
    },
    setUpdate: (state, action) => {
      state.isUpdate = action.payload
    },
    setZone: (state, action) => {
      state.zoneList = action.payload
    }
  }
})

export const { setSubzones, setOpenModal, setSubzone, setUpdate, setZone } =
  superadminSubzoneSlice.actions

export const superadminSubzone = superadminSubzoneSlice.reducer
