// ** Icons Import
import {
  FileText,
  Circle,
  Shield,
  Navigation2,
  DollarSign,
  Users,
  Home,
  Table,
  XOctagon
} from 'react-feather'

export default [
  {
    id: 'Transactions',
    title: 'Transacciones',
    action: 'ente-level-0',
    resource: 'parkingMeterSystem',
    // action: 'concession-level-2',
    // resource: 'parking',
    icon: <Table size={20} />,
    navLink: '/parking-meters/transactions'
  },
  {
    id: 'Transactions',
    title: 'Transacciones',
    action: 'pos-level-2',
    resource: 'parkingMeterSystem',
    // action: 'concession-level-2',
    // resource: 'parking',
    icon: <Table size={20} />,
    navLink: '/parking-meters/transactions'
  },

  {
    header: 'Control Vehicular',
    action: 'ente-level-0',
    resource: 'parkingMeterSystem'
  },

  {
    id: 'Penalty',
    title: 'Penalización',
    action: 'ente-level-0',
    resource: 'parkingMeterSystem',
    // action: 'concession-level-2',
    // resource: 'parking',
    icon: <XOctagon size={20} />,
    navLink: '/parking-meters/penalty'
  },
  {
    id: 'Report',
    title: 'Reportes',
    action: 'ente-level-0',
    resource: 'parkingMeterSystem',
    // action: 'concession-level-2',
    // resource: 'parking',
    icon: <FileText size={20} />,
    navLink: '/parking-meters/reports'
  },

  {
    header: 'Administrador',
    action: 'concesion-level-1',
    resource: 'parkingMeterSystem'
    // action: 'concession-level-2',
    // resource: 'parking'
  },
  {
    id: 'Tickets',
    title: 'Admin',
    action: 'concesion-level-1',
    resource: 'parkingMeterSystem',
    // action: 'concession-level-2',
    // resource: 'parking',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'Areas',
        title: 'Areas',
        action: 'concesion-level-1',
        resource: 'parkingMeterSystem',
        // action: 'concession-level-2',
        // resource: 'parking',
        icon: <Circle size={12} />,
        navLink: '/parking-meters/area'
      },
      {
        id: 'Zonas',
        title: 'Zonas',
        action: 'concesion-level-1',
        resource: 'parkingMeterSystem',
        // action: 'concession-level-2',
        // resource: 'parking',
        icon: <Circle size={12} />,
        navLink: '/parking-meters/zones'
      },
      {
        id: 'Subzonas',
        title: 'Subzonas',
        action: 'concesion-level-1',
        resource: 'parkingMeterSystem',
        // action: 'concession-level-2',
        // resource: 'parking',
        icon: <Circle size={12} />,
        navLink: '/parking-meters/subzone'
      }
    ]
  },
  {
    header: 'Historial',
    action: 'concesion-level-1',
    resource: 'parkingMeterSystem'
    // action: 'concession-level-2',
    // resource: 'parking'
  },
  {
    id: 'Parking',
    title: 'Parquimetros',
    action: 'concesion-level-1',
    resource: 'parkingMeterSystem',
    // action: 'concession-level-2',
    // resource: 'parking'
    icon: <FileText size={20} />,
    children: [
      {
        id: 'History',
        title: 'Historial',
        action: 'concesion-level-1',
        resource: 'parkingMeterSystem',
        // action: 'concession-level-2',
        // resource: 'parking',
        icon: <Circle size={12} />,
        navLink: '/parking-meters/list'
      }
    ]
  }
]
