export const defaultFilter = {
  filterOptions: {
    input: '',
    role_id: ''
  },
  pagination: {
    page: 1,
    limit: 10
  }
}

const defaultValues = {
  name: '',
  last_name: '',
  username: '',
  email: '',
  phone: '',
  role_id: '',
  doc_type: 'V',
  doc_id: ''
}

export const initialPagination = {
  page: 0,
  show: 9,
  nextPageExists: false
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar operador' },
  update: { action: 'update', message: 'Actualizar datos del operador' },
  updateFiner: {
    action: 'update finder',
    message: 'Actualizar parquimetros'
  },
  updateInformant: {
    action: 'update informant',
    message: 'Actualizar datos del informante'
  },
  updateTow: {
    action: 'update tow',
    message: 'Actualizar datos del gruero'
  },
  selectPdUser: {
    action: 'select pd user',
    message: 'Seleccionar usuario de pagodirecto'
  },
  resetPassword: {
    action: 'reset password',
    message: 'Restablecer contraseña'
  },
  validateOTP: { action: 'select otp', message: 'Ingresar OTP' },
  reset: { action: 'reset', message: 'restablecer contraseña' },
  delete: { action: 'deleted', message: 'eliminar' },
  activate: { action: 'active', message: 'activar' },
  deactivate: { action: 'inactive', message: 'desactivar' },
  assignAccess: { action: 'assign access', message: 'Asignar acceso' }
}

const handleErrors = {
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  last_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  email: {
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'El formato del correo no es el adecuado'
    },

    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  phone: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  doc_id: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

export const initialState = {
  defaultValues,
  handleErrors,
  defaultFilter,
  defaultWorkTeamRoles: ['Supervisor', 'Master', 'Operador'],
  defaultParkingTeamRoles: [
    'Multador',
    'Informante',
    'Gruero',
    'Multador Supervisor',
    'Parquero'
  ],

  customerOptions: [],
  customerSelected: {},
  customOperator: {},

  pagination: { ...initialPagination },

  actions: defaultActions,
  loading: true,
  data: [],
  roles: [],
  nextPageExists: false,
  filters: defaultFilter,
  selectedOperator: null,
  updatedOperator: null,
  finerInfo: {}
}
