import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import { getUsersMetricService } from '../../../services/users/dashboard'
import {
  setActiveUsers,
  setComparativeData,
  setLoading,
  setNewUsers,
  setUserHistory,
  setUsersType
} from './slice'
import moment from 'moment'

const dailyHours = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
]

const weeklyDays = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom']
const formatData = (data, format) =>
  data.reduce(
    (acc, data) => {
      acc[0].push(moment(data.dateFormatted).format(format))
      acc[1].push(data.qty)
      return acc
    },
    [[], []]
  )

const getHourlyOrDailyData = (data, xAxis, format) =>
  xAxis.map((time) => {
    const found = data.find((data) => {
      const timeFromData = data.dateFormatted
        .split(' ')[1]
        .slice(0, format.length)
      return timeFromData === time
    })
    return found?.qty ?? 0
  })

const getWeeklyData = (data, weeklyNumberDaysByDays) =>
  weeklyNumberDaysByDays.map((day) => {
    const found = data.find((data) => {
      const dateFromData = data.dateFormatted.split(' ')[0]
      return dateFromData === day
    })
    return found?.qty ?? 0
  })

export const getUsersMetrics = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().usersDashboard
      const { ok, res, error } = await getUsersMetricService(filters)

      if (!ok) throw error

      const {
        active_users,
        newUsersDistribution,
        today_users_data,
        users_type
      } = res
      const { activeUsersHourlyOrDaily, activeUsersPeriodically } = active_users

      const periodHandlers = {
        daily: () => {
          const xAxis = dailyHours
          const currentData = activeUsersHourlyOrDaily[1]?.data || []
          const previousData = activeUsersHourlyOrDaily[0]?.data || []

          const yAxisCurrent = getHourlyOrDailyData(currentData, xAxis, 'HH:mm')
          const yAxisPrevious = getHourlyOrDailyData(
            previousData,
            xAxis,
            'HH:mm'
          )

          return { xAxis, yAxisCurrent, yAxisPrevious }
        },
        weekly: () => {
          const xAxis = weeklyDays
          const currentWeeklyStart = moment()
            .startOf('week')
            .format('YYYY-MM-DD')
          const currentWeeklyEnd = moment().endOf('week').format('YYYY-MM-DD')

          const weeklyNumberDaysByDays = [
            currentWeeklyStart,
            moment(currentWeeklyStart).add(1, 'days').format('YYYY-MM-DD'),
            moment(currentWeeklyStart).add(2, 'days').format('YYYY-MM-DD'),
            moment(currentWeeklyStart).add(3, 'days').format('YYYY-MM-DD'),
            moment(currentWeeklyStart).add(4, 'days').format('YYYY-MM-DD'),
            moment(currentWeeklyStart).add(5, 'days').format('YYYY-MM-DD'),
            currentWeeklyEnd
          ]

          const currentData = activeUsersHourlyOrDaily[0].data

          const yAxisCurrent = getWeeklyData(
            currentData,
            weeklyNumberDaysByDays
          )
          const yAxisPrevious = []

          return { xAxis, yAxisCurrent, yAxisPrevious }
        },
        range: () => {
          const currentData = activeUsersHourlyOrDaily

          const yAxisPrevious = []
          const [xAxis, yAxisCurrent] = formatData(currentData, 'YYYY-MM-DD')

          return { xAxis, yAxisCurrent, yAxisPrevious }
        }
      }

      const { xAxis, yAxisCurrent, yAxisPrevious } =
        periodHandlers[filters.period]()

      const [xAxisActiveUsers, yAxisActiveUsers] = formatData(
        activeUsersPeriodically,
        'YYYY-MM-DD'
      )
      const [xAxisNewUsers, yAxisNewUsers] = formatData(
        newUsersDistribution,
        'YYYY-MM-DD'
      )

      dispatch(setComparativeData({ yAxisCurrent, yAxisPrevious, xAxis }))
      dispatch(
        setActiveUsers({ xAxis: xAxisActiveUsers, yAxis: yAxisActiveUsers })
      )
      dispatch(setNewUsers({ xAxis: xAxisNewUsers, yAxis: yAxisNewUsers }))

      const userHistory = today_users_data?.users_history.map((data) => {
        return {
          date: moment(data.created_at).format('YYYY-MM-DD HH:mm'),
          username: data?.username
        }
      })

      dispatch(
        setUserHistory({
          list: userHistory,
          currentQty: today_users_data?.today_qty,
          previousQty: today_users_data?.yesterday_qty,
          variation: today_users_data?.variation
        })
      )

      dispatch(setUsersType(users_type))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
