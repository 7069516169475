import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'
import { getCommerceData } from '@utils'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import {
  setAreas,
  setCurrentPeriodAmount,
  setCurrentPeriodData,
  setIncome,
  setLastTickets,
  setLoading,
  setNextPageExists,
  setPreviusPeriodData,
  setSubzones,
  setTemporalAxisData,
  setTemporalPeriodData,
  setTickets,
  setUsdAmount,
  setZoneDistribution,
  setZones,
  setdAmount,
  setxAxisData
} from './slice'

const endpoints = {
  getTickets: `${parkingMetersURL}/admin/parkingmeter-tickets/list`,
  loadArea: `${parkingMetersURL}/admin/areas/list`,
  loadZones: `${parkingMetersURL}/admin/zones/list`,
  loadSubZones: `${parkingMetersURL}/admin/subzones/list`,
  comparativeDstribution: `${parkingMetersURL}/admin/ticket-metrics/comparative-distribution`,
  temporalDistribution: `${parkingMetersURL}/admin/ticket-metrics/temporal-distribution`,
  zoneDistribution: `${parkingMetersURL}/admin/ticket-metrics/best-subzones`,
  currentComparative: `${parkingMetersURL}/admin/ticket-metrics/comparative-operations/daily`,
  income: `${parkingMetersURL}/admin/ticket-metrics/income`,

  getTicketsPos: `${parkingMetersURL}/pos/parkingmeter-tickets/list`,
  loadAreaPos: `${parkingMetersURL}/pos/areas/list`,
  loadZonesPos: `${parkingMetersURL}/pos/zones/list`,
  loadSubZonesPos: `${parkingMetersURL}/pos/subzones/list`,
  comparativeDstributionPos: `${parkingMetersURL}/pos/ticket-metrics/comparative-distribution`,
  temporalDistributionPos: `${parkingMetersURL}/pos/ticket-metrics/temporal-distribution`,
  zoneDistributionPos: `${parkingMetersURL}/pos/ticket-metrics/best-subzones`,
  currentComparativePos: `${parkingMetersURL}/pos/ticket-metrics/comparative-operations/daily`,
  incomePos: `${parkingMetersURL}/pos/ticket-metrics/income`
}

const getSubzones = async (body) => {
  try {
    const commerceType = getCommerceData()
    const type = commerceType?.type

    const url =
      type === 'pos' ? endpoints.loadSubZonesPos : endpoints.loadSubZones

    const response = await apiMethods.authPost({
      url,
      body
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadSubzones = (filterBody) => {
  return async (dispatch, getState) => {
    try {
      const body = {
        pagination: {
          page: 1,
          limit: 100
        },
        filterOptions: {
          entity_commerces_ids: getState().ticketReducer.entity_commerces_ids,
          ...filterBody
        }
      }

      const { ok, res } = await getSubzones(body)

      if (ok) {
        const subzoneList = []

        res.data.items.map((subzone) => {
          subzoneList.push({
            value: subzone._id,
            label: `${subzone.display_name} - ${subzone.code}`
          })
        })

        dispatch(setSubzones(subzoneList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const getZone = async (body) => {
  try {
    const commerceType = getCommerceData()
    const type = commerceType?.type

    const url = type === 'pos' ? endpoints.loadZonesPos : endpoints.loadZones

    const response = await apiMethods.authPost({
      url,
      body
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadZones = (filterBody) => {
  return async (dispatch, getState) => {
    try {
      const body = {
        pagination: {
          page: 1,
          limit: 100
        },
        filterOptions: {
          entity_commerces_ids: getState().ticketReducer.entity_commerces_ids,
          ...filterBody
        }
      }

      const { ok, res } = await getZone(body)

      if (ok) {
        const zoneList = []

        res.data.items.map((area) => {
          zoneList.push({ value: area._id, label: area.display_name })
        })

        dispatch(setZones(zoneList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const getArea = async (body) => {
  try {
    const commerceType = getCommerceData()
    const type = commerceType?.type

    const url = type === 'pos' ? endpoints.loadAreaPos : endpoints.loadArea

    const response = await apiMethods.authPost({
      url,
      body
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadArea = () => {
  return async (dispatch, getState) => {
    try {
      const body = {
        pagination: {
          page: 1,
          limit: 100
        },
        filterOptions: {
          entity_commerces_ids: getState().ticketReducer.entity_commerces_ids
        }
      }

      const { ok, res } = await getArea(body)

      if (ok) {
        const areaList = []

        res.data.items.map((area) => {
          areaList.push({ value: area._id, label: area.display_name })
        })

        dispatch(setAreas(areaList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getTicket = () => {
  return async (dispatch, getState) => {
    try {
      const commerceType = getCommerceData()
      const type = commerceType?.type

      const url =
        type === 'pos' ? endpoints.getTicketsPos : endpoints.getTickets

      const { ok, res } = await apiMethods.authPost({
        url,
        body: getState().parkingDashboard.filter
      })

      if (ok) {
        dispatch(setLastTickets(res.data.items))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getTemporalDistribution = (query = 'daily', body) => {
  return async (dispatch, getState) => {
    try {
      const commerceType = getCommerceData()
      const type = commerceType?.type

      const url =
        type === 'pos'
          ? endpoints.temporalDistributionPos
          : endpoints.temporalDistribution

      const { ok, res } = await apiMethods.authPost({
        url: `${url}/${query}`,
        body
      })

      const xAxis = []
      const temporalPeriodData = []
      const temporalPeriodDataAmount = []

      if (ok && query === 'daily') {
        const temporalDistribution = res.data.temporal_distribution

        temporalDistribution.map((item) => {
          xAxis.push(item.dateKey)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })
      }

      if (ok && query === 'weekly') {
        const temporalDistribution = res.data.temporal_distribution

        temporalDistribution.map((item) => {
          xAxis.push(`De ${item.weekStart} a ${item.weekEnd}`)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })
      }

      if (ok && query === 'monthly') {
        const temporalDistribution = res.data.temporal_distribution

        temporalDistribution.map((item) => {
          xAxis.push(item.monthKey)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })
      }
      if (ok && query === 'custom') {
        res.data.map((item) => {
          xAxis.push(item.dateKey)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })

        // temp
        dispatch(setxAxisData(xAxis))
        dispatch(setCurrentPeriodData(temporalPeriodData))
        dispatch(setPreviusPeriodData([]))
      }

      dispatch(setTemporalAxisData(xAxis))
      dispatch(
        setTemporalPeriodData({
          tickets: temporalPeriodData,
          amount: temporalPeriodDataAmount
        })
      )

      dispatch(setLoading(false))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getComparativeDstribution = (query = 'daily', body) => {
  return async (dispatch, getState) => {
    try {
      if (query === 'custom') return

      const commerceType = getCommerceData()
      const type = commerceType?.type

      const url =
        type === 'pos'
          ? endpoints.comparativeDstributionPos
          : endpoints.comparativeDstribution

      const { ok, res } = await apiMethods.authPost({
        url: `${url}/${query}`,
        body
      })

      dispatch(setLoading(false))

      const xAxis = []
      const currentPeriodData = []
      const previusPeriodData = []
      const currentPeriodAmount = []

      if (ok && query === 'daily') {
        const usdAmount = res.data.current_period.usdAmount
        const tickets = res.data.current_period.tickets
        const amount = res.data.current_period.amount
        const currentPeriod = res.data.current_period.hourlyMetrics
        const previusPeriod = res.data.previous_period.hourlyMetrics

        for (const key in currentPeriod) {
          const xLabel = `${key.slice(-2)}:00`
          xAxis.push(xLabel)
          currentPeriodData.push(currentPeriod[key].tickets)
          previusPeriodData.push(previusPeriod[key].tickets)
          currentPeriodAmount.push(previusPeriod[key].amount)
        }

        dispatch(setUsdAmount(usdAmount))
        dispatch(setTickets(tickets))
        dispatch(setdAmount(amount))
        // dispatch(setCurrentPeriodAmount(currentPeriodAmount))
      }

      if (ok && query === 'weekly') {
        const currentPeriod = res.data.current_period.dailyMetrics
        const previusPeriod = res.data.previous_period.dailyMetrics

        currentPeriod.map((item) => {
          xAxis.push(item.dayKey)
          currentPeriodData.push(item.tickets)
        })
        previusPeriod.map((item) => {
          previusPeriodData.push(item.tickets)
        })
      }

      if (ok && query === 'monthly') {
        const currentPeriod = res.data.current_period.dailyMetrics
        const previusPeriod = res.data.previous_period.dailyMetrics

        currentPeriod.map((item) => {
          xAxis.push(item.dayKey)
          currentPeriodData.push(item.tickets)
        })
        previusPeriod.map((item) => {
          previusPeriodData.push(item.tickets)
        })
      }

      dispatch(setxAxisData(xAxis))
      dispatch(setCurrentPeriodData(currentPeriodData))
      dispatch(setPreviusPeriodData(previusPeriodData))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getZoneDistribution = (query = 'daily', body) => {
  return async (dispatch, getState) => {
    try {
      const commerceType = getCommerceData()
      const type = commerceType?.type

      const url =
        type === 'pos'
          ? endpoints.zoneDistributionPos
          : endpoints.zoneDistribution


      const { ok, res } = await apiMethods.authPost({
        url: `${url}/${query}`,
        body
      })

      dispatch(setZoneDistribution(res.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getCurrentComparation = (query = 'daily', body) => {
  return async (dispatch, getState) => {
    try {
      const commerceType = getCommerceData()
      const type = commerceType?.type

      const url =
        type === 'pos'
          ? endpoints.currentComparativePos
          : endpoints.currentComparative


      const { ok, res } = await apiMethods.authPost({
        url: `${url}`,
        body
      })

      dispatch(setCurrentPeriodAmount(res.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getIncome = (query = 'daily', body) => {
  return async (dispatch, getState) => {
    try {
      const commerceType = getCommerceData()
      const type = commerceType?.type

      const url =
        type === 'pos'
          ? endpoints.incomePos
          : endpoints.income


      const { ok, res } = await apiMethods.authPost({
        url: `${url}/${query}`,
        body
      })

      if (ok) {
        dispatch(setIncome(res.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
