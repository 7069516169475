import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setTransactions,
  setTransactionTagsOptions,
  setMobilePaid,
  setSelectedMobilePaid,
  setTransfers,
  setApproveTransactions,
  setSelectedApproveTransaction
} from './slice'

import { t } from 'i18next'
import {
  getMobilePaidTransactions,
  getTransactionsDetailServices
} from '../../../services/users/mobilePaid'
import {
  approveTransactionsService,
  getApproveTransactionsService
} from '../../../services/users/approve-transactions'

export const getApproveTransactions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().approveTransactions
      const { ok, res, error } = await getApproveTransactionsService(filters)

      if (!ok) throw error

      dispatch(setApproveTransactions(res?.transactions ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const approvePendingTransactions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedApproveTransaction } = getState().approveTransactions
     
      const body = {
        transaction_id: selectedApproveTransaction?._id
      }

      const { ok, res, error } = await approveTransactionsService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Transacción aprobada con éxito')
      dispatch(getApproveTransactions())
      dispatch(setSelectedApproveTransaction(res?.transaction))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
