// ** Icons Import
import { Circle, Package, Clipboard } from 'react-feather'

export default [
  {
    header: 'Peajes',
    action: 'pagodirecto-level-0',
    resource: 'Common'
  },
  {
    id: 'tolls-system',
    title: 'Lista de peajes',
    icon: <Clipboard size={20} />,
    action: 'pagodirecto-level-0',
    resource: 'Common',
    navLink: '/tolls/tolls'
  },
  {
    id: 'access',
    title: 'Accesos',
    icon: <Clipboard size={20} />,
    action: 'pagodirecto-level-0',
    resource: 'Common',
    navLink: '/tolls/access'
  },
  {
    id: 'bundles',
    title: 'Paquetes',
    icon: <Package size={20} />,
    action: 'pagodirecto-level-0',
    resource: 'Common',
    navLink: '/tolls/bundles/admin'
  },
  {
    id: 'Transactions',
    title: 'Transacciones',
    action: 'pagodirecto-level-0',
    resource: 'Common',
    icon: <Clipboard size={20} />,
    children: [
      {
        id: 'Paquetes',
        title: 'Paquetes',
        action: 'pagodirecto-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/tolls/bundles/history'
      },
      {
        id: 'History',
        title: 'Viajes',
        action: 'pagodirecto-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/tolls/tickets/list'
      }
    ]
  }
]
