import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd03, baseV1URL } from '@configs/apis'
import { resetSsposPassword } from '../../redux/sspos/sspos'

const endpoints = {
  autocomplete: `${baseV2URL}/superadmin/sspos/autocomplete`,
  list: `${baseV2URL}/superadmin/sspos/list`,
  create: `${baseV2URL}/superadmin/sspos`,
  edit: `${baseV2URL}/superadmin/sspos`,
  delete: `${baseV2URL}/superadmin/sspos`,
  resetPassword: `${baseV2URL}/superadmin/sspos/reset-password`,
  changePassword: `${baseV2URL}/superadmin/sspos/change-password`
}

export const createSspos = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.create,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getSsposList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.autocomplete,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getSsposByIdService = async (id) => {
  try {
    const response = await apiMethods.authGetFetch(`${endpoints.edit}?id=${id}`)
    return response
  } catch (error) {
    return error
  }
}

export const updateSsposService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: endpoints.edit,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteSsposService = async (body) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: endpoints.delete,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const resetSsposPasswordService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.resetPassword,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const changeSsposPasswordServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.changePassword,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

// export const createAdvertisingService = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetchFormData({
//       url: endpoints.createAdvertising,
//       formData: body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const getAdvertisingList = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetch({
//       url: endpoints.getAdvertising,
//       body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const deleteAdvertisingService = async (id) => {
//   try {
//     const response = await apiMethods.authDeleteFetch({
//       url: `${endpoints.deleteAdvertising}/${id}`
//     })

//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const updateAdvertisingService = async (body) => {
//   try {
//     const response = await apiMethods.authPatchFetchFormData({
//       url: endpoints.createAdvertising,
//       formData: body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const getCouponsUsesHistoryList = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetch({
//       url: endpoints.getCouponsUsesHistoryList,
//       body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const searchUserService = async (body) => {
//   try {
//     const response = await apiMethods.authGetFetch(
//       `${endpoints.searchUser}?input=${body}&show=0&page=100`
//     )
//     return response
//   } catch (error) {
//     return error
//   }
// }
