export const defaultFilter = {
  from_date: '',
  end_date: '',
  type: '',
  transaction_tag: [],
  customer_id: '60711dd88a0f3e001529d719',
  counterpart_id: '',
  commerce_counterpart_id: '',
  limit: 8,
  skip: 1
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  completed: { label: 'Completado', color: 'light-primary' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  withdraw: { action: 'withdraw', message: 'Retirar' },
  transfer: { action: 'transfer', message: 'Transferir' }
}

const defaultValues = {
  concept: '',
  amount: '',
  bank: { value: 'wallet', label: 'De Wallet a Paytech' },
  code: '',
  user: ''
}

const handleErrors = {
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  value: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },
  maximum_uses: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },

  vertical_type: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  new_user: {
    validate: (value) => value !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  service_names: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  expiration_date: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const transactionOptions = {
  debit: { label: 'Débito', color: 'light-danger' },
  credit: { label: 'Crédito', color: 'light-success' }
}

const initialState = {
  loading: false,

  data: [],
  selectedTransaction: null,
  selectedAccount: null,
  withdrawAccount: null,
  accountsOptions: [],
  transactionOptions,

  handleErrors,
  defaultValues,

  filters: defaultFilter,

  nextPageExists: false,
  actions: defaultActions
}

export default initialState
