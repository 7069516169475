import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'

import { setZone, setZones, setOpenModal, setUpdate, setArea } from './slice'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'

const endpoints = {
  loadAreaList: `${parkingMetersURL}/superadmin/areas`,
  loadZones: `${parkingMetersURL}/superadmin/zones/list`,
  addZones: `${parkingMetersURL}/superadmin/zones`,
  deleteZone: `${parkingMetersURL}/superadmin/zones`,
  updateZone: `${parkingMetersURL}/superadmin/areas/update`
}

const filters = {
  area_id: '',
  concession_commerce_id: '63882e1dbb7e036a8ee93fe5',
  pagination: {
    page: 1,
    limit: 6
  },
  sorting: {
    code: 'created_at',
    sortOrder: 'desc'
  },
  filterOptions: {
    code: '',
    display_name: ''
  }
}

const getZones = async (defaultFilters) => {
  try {
    const response = await apiMethods.authPostTemp({
      url: endpoints.loadZones,
      body: defaultFilters
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadZones = (defaultFilters) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await getZones(defaultFilters)

      if (ok) {
        dispatch(setZones(res.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const loadAreas = () => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authGet({
        url: endpoints.loadAreaList
      })

      if (ok) {
        const areaList = [{ value: undefined, label: 'Seleccione' }]

        res.data.items.map((area) => {
          areaList.push({ value: area._id, label: area.display_name })
        })

        dispatch(setArea(areaList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const addZone = (body) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authPostTemp({
        url: endpoints.addZones,
        body
      })

      if (ok) {
        const { res } = await getZones(filters)
        dispatch(setZones(res.data))

        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Zona añadida exitosamente'}
            type={'success'}
          />
        ))
      } else {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Ocurrió un error al registrar la zona'}
            type={'danger'}
          />
        ))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const openModal = (action) => {
  return async (dispatch) => {
    dispatch(setOpenModal(action))
  }
}

export const softDeleteZone = (id) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authDelete({
        url: `${endpoints.deleteZone}/${id}`
      })

      if (ok) {
        const { res } = await getZones(filters)
        dispatch(setZones(res.data))

        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Zona eliminada exitosamente'}
            type={'success'}
          />
        ))
      } else {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Ocurrió un error al eliminar la zona'}
            type={'danger'}
          />
        ))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
// export const setAreaForm = (body) => {
//   return async (dispatch) => {
//     dispatch(setArea(body))
//   }
// }

// export const setUpdateForm = (action) => {
//   return async (dispatch) => {
//     dispatch(setUpdate(action))
//   }
// }
