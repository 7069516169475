import { getCommerceData } from '@utils'

const commerce = getCommerceData()
const building = commerce?.parking?.building._id ?? ''

export const initialPagination = {
  page: 0,
  show: 8,
  nextPageExists: false
}

const dateRange = {
  fromDate: '',
  endDate: ''
}

const initialState = {
  commerce,
  building,
  loading: false,
  selectedInvoice: null,
  searchBy: 'lastest',
  invoices: [],
  status: ['paid', 'pending'],
  dateRange,
  pagination: { ...initialPagination }
}

export default initialState
