// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'


export const penaltySlice = createSlice({
  name: 'penaltyReducer',
  initialState,
  reducers: {
    setLoadingPenalty: (state, action) => {
      state.loading = action.payload
    },
    setPenaltyData: (state, action) => {
      state.data = action.payload
    },

    setPenaltyTypeOptions: (state, action) => {
      state.penaltyTypeOptions = action.payload
    },
    setSelectedPenalty: (state, action) => {
      state.selectedPenalty = action.payload
    },
    setNextPageExistsPenalty: (state, action) => {
      state.nextPageExists = action.payload
    },
    setFiltersPenalty: (state, action) => {
      state.filters = action.payload
    }
  }
})

export const {
  setLoadingPenalty,
  setPenaltyData,
  setPenaltyTypeOptions,
  setSelectedPenalty,
  setNextPageExistsPenalty,
  setFiltersPenalty
} = penaltySlice.actions

export const penaltyReducer = penaltySlice.reducer
