import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  getBuildingsLogs,
  updateBillingService,
  getBuildingsInfo
} from '@services/parking-lot/buildings'
import {
  setBillingBuildingInfo,
  setBuildings,
  setBuildingsLogs,
  setLoadingAccessLogs,
  setLoadingBuildings,
  setPagination,
  setSelectedAccess,
  setSelectedBuilding
} from './'
import {
  addAccessService,
  addBoxOfficeService,
  deleteAccessService,
  updateAccessService
} from '../../../services/parking-lot/buildings'
import { Toast } from 'reactstrap'
import { searchUserService } from '../../../services/parking-lot/coupons'

export const setInitialBuildingInfo = () => {
  return async (dispatch, getState) => {
    try {
      const buildingInfo = JSON.parse(
        window.localStorage.getItem('selectedBuilding')
      )
      const billing = buildingInfo ? buildingInfo?.building?.billing : {}
      dispatch(setBillingBuildingInfo(billing))
    } catch (error) {
    } finally {
    }
  }
}

export const getBuildings = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingBuildings(true))

      const { ok, res, error } = await getBuildingsInfo()

      const buildings = res.buildings.map((building) => {
        return {
          value: building._id,
          label: building.name,
          code: building?.code,
          building,
          _id: building._id
        }
      })

      dispatch(setBuildings(buildings))
    } catch (error) {
    } finally {
      dispatch(setLoadingBuildings(false))
    }
  }
}

export const getLogs = () => {
  return async (dispatch, getState) => {
    dispatch(setLoadingAccessLogs(true))

    const { filtersLogs, pagination } = getState().parkingLotBuildings
    const filters = {
      ...filtersLogs
    }

    if (filtersLogs.username) {
      const users = await searchUserService(filtersLogs.username)
      filters.username = users?.res?.users[0]?.username
    }

    const { ok, res, error } = await getBuildingsLogs(filters)

    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.description ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
      dispatch(setLoadingAccessLogs(false))
      return
    }

    const checkNextPage = await getBuildingsLogs({
      ...filtersLogs,
      skip: filtersLogs.skip + filtersLogs.limit
    })

    // Check if next page exists
    if (checkNextPage.res.logs.length > 0) {
      dispatch(
        setPagination({
          ...pagination,
          nextPageExists: true
        })
      )
    } else {
      dispatch(
        setPagination({
          ...pagination,
          nextPageExists: false
        })
      )
    }

    dispatch(setBuildingsLogs(res.logs))
    dispatch(setLoadingAccessLogs(false))
  }
}

export const updateBilling = (payload) => {
  return async (dispatch, getState) => {
    const buildingId =
      getState().parkingLotBuildings.selectedBuilding.building._id

    const body = {
      ...payload,
      building_id: buildingId
    }

    const { ok, res, error } = await updateBillingService(body)

    if (!ok) throw res

    dispatch(setSelectedBuilding(res))
    dispatch(setBillingBuildingInfo(res.building.billing))
    dispatch(getBuildings())

    toast((t) => (
      <BasicToastContent
        t={t}
        message={'Tarifas actualizadas'}
        type={'success'}
      />
    ))
  }
}

export const addAccess = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await addAccessService(payload)

      if (!ok) throw res

      toast((t) => (
        <BasicToastContent
          t={t}
          message={res?.message ?? 'Acceso agregado correctamente'}
          type={'success'}
        />
      ))
      dispatch(getBuildings())
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.message ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
    }
  }
}
export const updateAccess = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await updateAccessService(payload)

      console.log(res)

      if (!ok) throw error

      toast((t) => (
        <BasicToastContent
          t={t}
          message={res?.message ?? 'Acceso agregado correctamente'}
          type={'success'}
        />
      ))
      dispatch(getBuildings())
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.message ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
    }
  }
}

export const addBoxOffice = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await addBoxOfficeService(payload)

      if (!ok) throw error

      toast((t) => (
        <BasicToastContent
          t={t}
          message={res?.description ?? 'Taquilla agregada correctamente'}
          type={'success'}
        />
      ))
      dispatch(getBuildings())
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.message ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
    }
  }
}

export const deleteAccess = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await deleteAccessService(payload)

      if (!ok) throw error

      toast((t) => (
        <BasicToastContent
          t={t}
          message={res?.description ?? 'Acceso eliminado correctamente'}
          type={'success'}
        />
      ))
      dispatch(getBuildings())
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.message ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
    }
  }
}