import { getCommerceData } from '@utils'

const commerce = getCommerceData()
const building = commerce?.parking?.building._id ?? ''

export const initialPagination = {
  page: 0,
  show: 8,
  nextPageExists: false
}

const dateRange = {
  fromDate: '',
  endDate: ''
}

const initialState = {
  bundles: [],
  building,
  loading: false,
  loadingInvoices: false,
  selectedBundle: null,
  selectedHistoryBundle: null,
  selectedInvoiceHistoryBundle: null,
  activationLoading: false,
  loadingDetails: false,
  searchBy: 'date',
  searchInvoicesBy: 'lastest',
  bundlesTitles: [],
  titlesSelected: [],
  bundlesHistory: [],
  bundlesInvoicesHistory: [],
  pagination: { ...initialPagination },
  dateRange,
  searchParam: '',
  status: ['approved'],
  statusInvoices: ['paid', 'pending'],
  commerce
}

export default initialState
