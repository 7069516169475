const MICROSERVICES_01 = process.env.REACT_APP_BASE_URL_MICROSERVICES_01
const MICROSERVICES_02 = process.env.REACT_APP_BASE_URL_MICROSERVICES_02
const BASE = process.env.REACT_APP_BASE_URL
const REPORTS = process.env.REACT_APP_BASE_URL_REPORTS
const PHYSICALTICKET = process.env.REACT_APP_BASE_URL_TICKETS
const WITHDRAW = process.env.REACT_APP_BASE_URL_WITHDRAWALS
const COMMERCE_EXTENSION = process.env.REACT_APP_BASE_COMMERCE_URL

export const authenticationURL = `${MICROSERVICES_01}/auth-api`
export const parkingMetersURL = `${MICROSERVICES_01}/parking-meters-api`
export const tollsURL = `${MICROSERVICES_01}/toll-api`
export const couponsURL = `${MICROSERVICES_01}/micropd-01/v2`
export const micropd03 = `${MICROSERVICES_01}/micropd-03`
export const micropd04 = `${MICROSERVICES_01}/micropd-04`
export const micropd02 = `${MICROSERVICES_02}`
export const baseV1URL = `${BASE}/v1`
export const baseV2URL = `${BASE}/v2`
export const socketURL = `${BASE}/`
export const reportsURL = REPORTS
export const withdrawURL = WITHDRAW

export const commerceExtension = COMMERCE_EXTENSION

export const physicalTicket = `${PHYSICALTICKET}`
