import { DefaultRoute } from '@router/routes'
import moment from 'moment-timezone'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
}

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('operator')
export const getOperatorData = () =>
  JSON.parse(localStorage.getItem('operator'))
export const getCommerceData = () =>
  JSON.parse(localStorage.getItem('selectedCommerce'))
export const getBuildingData = () =>
  JSON.parse(localStorage.getItem('selectedBuilding'))
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */

export const commerceHierarchy = (loginData) => {
  const verificationObject = {
    customer: loginData?.role === 'customer',
    tollCommerce: loginData?.commerce_associated?.type === 'peaje',
    tollConcession: loginData?.commerce_associated?.type === 'concesion',
    superadmin: loginData?.commerce_associated?.category === 'pagodirecto'
  }

  const hierarchy = Object.keys(verificationObject).find(
    (key) => verificationObject[key]
  )
  if (!hierarchy) return ''
  return hierarchy
}
export const getHomeRouteForLoggedInUser = (loginData) => {
  // **type category, permissions

  if (!loginData) return '/login'
  const category = loginData?.commerce_associated?.category

  if (!category) return '/auth/not-auth'

  // !TODO: remove this when superadmin is implemented
  const isTaquillaUser = loginData?.commerce_role?.name === 'Taquilla'
  if (isTaquillaUser) return '/users/event'
  return DefaultRoute
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getVenezuelaHourPrefix = (date) => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Caracas',
    hour: 'numeric',
    hour12: false
  })
  const venezuelanTime = formatter.format(date)
  const venezuelanHour = venezuelanTime.split(':')[0]
  const metricHour = venezuelanHour === '24' ? '00' : venezuelanHour
  return metricHour
}

export const time_assets = [
  '00:00 hrs',
  '01:00 hrs',
  '02:00 hrs',
  '03:00 hrs',
  '04:00 hrs',
  '05:00 hrs',
  '06:00 hrs',
  '07:00 hrs',
  '08:00 hrs',
  '09:00 hrs',
  '10:00 hrs',
  '11:00 hrs',
  '12:00 hrs',
  '13:00 hrs',
  '14:00 hrs',
  '15:00 hrs',
  '16:00 hrs',
  '17:00 hrs',
  '18:00 hrs',
  '19:00 hrs',
  '20:00 hrs',
  '21:00 hrs',
  '22:00 hrs',
  '23:00 hrs'
]

export const days_of_weeks_assets = [
  'Lun',
  'Mar',
  'Mie',
  'Jue',
  'Vie',
  'Sáb',
  'Dom'
]

export const itemsPeriodChart = (period) => {
  const periodObj = {
    daily: {
      startOf: 'day',
      subtract: 1,
      qtyName: 'days',
      qtyItems: 'hours',
      itemName: 'hour',
      labels: [...time_assets],
      formatTime: 'H',
      arrayLen: 24
    },
    weekly: {
      startOf: 'isoWeek',
      subtract: 1,
      qtyName: 'weeks',
      qtyItems: 'days',
      itemName: 'day',
      labels: [...days_of_weeks_assets],
      formatTime: 'DD-MM-YYYY',
      arrayLen: 7
    },
    monthly: {
      startOf: 'month',
      subtract: 1,
      qtyName: 'months',
      qtyItems: 'days',
      itemName: 'day',
      formatTime: 'DD-MM-YYYY',
      arrayLen: 31
    }
  }
  const selectedPeriod = periodObj[period] || periodObj.daily
  const items = []
  const itemsLast = []
  const startOf = moment().tz('America/Caracas').startOf(selectedPeriod.startOf)
  const startOfBuffer = moment()
    .tz('America/Caracas')
    .startOf(selectedPeriod.startOf)
  const startOfLast = moment()
    .tz('America/Caracas')
    .startOf(selectedPeriod.startOf)
    .subtract(selectedPeriod.subtract, selectedPeriod.qtyName)

  const startOfLastBuffer = moment()
    .tz('America/Caracas')
    .startOf(selectedPeriod.startOf)
    .subtract(selectedPeriod.subtract, selectedPeriod.qtyName)

  for (let i = 0; i < selectedPeriod.arrayLen; i++) {
    items.push({
      [selectedPeriod.itemName]: moment(startOfBuffer).format(
        selectedPeriod.formatTime
      ),
      qty: 0,
      label:
        period !== 'monthly'
          ? selectedPeriod.labels[i]
          : moment(startOfBuffer).format('DD-MM-YYYY')
    })
    itemsLast.push({
      [selectedPeriod.itemName]: moment(startOfLastBuffer).format(
        selectedPeriod.formatTime
      ),
      qty: 0,
      label:
        period !== 'monthly'
          ? selectedPeriod.labels[i]
          : moment(startOfLastBuffer).format('DD-MM-YYYY')
    })
    startOfBuffer.add(1, selectedPeriod.qtyItems)
    startOfLastBuffer.add(1, selectedPeriod.qtyItems)
  }

  return {
    startOf,
    startOfLast,
    items,
    itemsLast
  }
}

export const mergeChartData = (period, image) => {
  const { items: currentDomain, itemsLast: previousDomain } =
    itemsPeriodChart(period)
  const { current_period: currentImage, previous_period: previousImage } = image

  if (period === 'daily') {
    const categories = currentDomain.map((domain) => {
      return domain.label
    })
    const currentPeriodData = currentDomain.map((domain, index) => {
      return currentImage && currentImage.hourlyArray
        ? currentImage.hourlyArray[index]
        : domain.qty
    })

    const previousPeriodData = previousDomain.map((domain, index) => {
      return previousImage && previousImage.hourlyArray
        ? previousImage.hourlyArray[index]
        : domain.qty
    })

    return { categories, currentPeriodData, previousPeriodData }
  }

  if (period === 'weekly' || period === 'monthly') {
    const categories = currentDomain.map((domain) => {
      return domain.label
    })
    const currentPeriodData = currentDomain.map((domain) => {
      let dataValue = domain.qty
      if (currentImage && currentImage.days && currentImage.days.length > 0) {
        dataValue = currentImage.days.find(
          (image) => image.dateFormatted === domain.day
        )
      }
      return dataValue ? parseInt(dataValue.tickets) : domain.qty
    })

    const previousPeriodData = previousDomain.map((domain) => {
      let dataValue = domain.qty
      if (
        previousImage &&
        previousImage.days &&
        previousImage.days.length > 0
      ) {
        dataValue = previousImage.days.find(
          (image) => image.dateFormatted === domain.day
        )
      }
      return dataValue ? parseInt(dataValue.tickets) : domain.qty
    })

    return { categories, currentPeriodData, previousPeriodData }
  }
}

export const barChartData = (ticketsDistribution) => {
  if (ticketsDistribution) {
    const categories = ticketsDistribution.map((value) => value.dateFormatted)
    const data = ticketsDistribution.map((value) => value.tickets)
    return { categories, data }
  }

  return { categories: [0, 1, 2, 3, 4, 5], data: [0, 0, 0, 0, 0, 0] }
}

export const totalQties = (image) => {
  const { current_period: currentImage, previous_period: previousImage } = image

  return {
    cTickets: currentImage && currentImage.tickets ? currentImage.tickets : 0,
    cAmount: currentImage && currentImage.amount ? currentImage.amount : 0,
    pTickets:
      previousImage && previousImage.tickets ? previousImage.tickets : 0,
    pAmount: previousImage && previousImage.amount ? previousImage.amount : 0
  }
}

export const vehicleType = (type) => {
  const types = {
    'light-car': 'Liviano',
    microbus: 'Microbus',
    bus: 'Autobus',
    'light-duty': 'Camioneta liviana',
    'heavy-duty-2-axles': 'Camión de 2 ejes',
    'heavy-duty-3-axles': 'Camión de 3 ejes',
    'heavy-duty-4-axles': 'Camión de 4 ejes',
    'heavy-duty-5-axles': 'Camión de 5 ejes',
    'heavy-duty-6-axles': 'Camión de 6 ejes'
  }

  return types[type] || 'N/A'
}

export const vehiclesOptions = [
  { value: 'light-car', label: 'Liviano' },
  { value: 'microbus', label: 'Microbus' },
  { value: 'bus', label: 'Autobus' },
  { value: 'light-duty', label: 'Camioneta liviana' },
  { value: 'heavy-duty-2-axles', label: 'Camión de 2 ejes' },
  { value: 'heavy-duty-3-axles', label: 'Camión de 3 ejes' },
  { value: 'heavy-duty-4-axles', label: 'Camión de 4 ejes' },
  { value: 'heavy-duty-5-axles', label: 'Camión de 5 ejes' },
  { value: 'heavy-duty-6-axles', label: 'Camión de 6 ejes' }
]

export const verifyDateTypeString = (
  value,
  format,
  returnSameIfString = true
) => {
  const isString = typeof value === 'string'
  const stringValue =
    returnSameIfString && isString
      ? value
      : moment(value, 'YYYY/MM/DD').format(format)

  if (isString) return stringValue
  return moment(new Date(value)).format(format)
}

export const dateAndHourFormatter = (date, hour) => {
  const formattedDate = verifyDateTypeString(date, 'YYYY/MM/DD')
  const formattedHour = verifyDateTypeString(hour, 'H:m')
  return moment(`${formattedDate} ${formattedHour}}`, 'YYYY/MM/DD H:m').format()
}

export const formatPrice = (x) => {
  if (typeof x === 'number') {
    try {
      x = x.toFixed(2)
    } catch (e) {
      return console.error(e)
    }
  }

  if (typeof x === 'string') {
    const parts = x.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join(',')
  }
}

export const getNameAndRole = (operator) => {
  const operatorRole = operator?.commerce_role?.name
  const roles = {
    Administrador: 'Administrador',
    Master: 'Gerente',
    Supervisor: 'Supervisor',
    Operador: 'Operador'
  }
  const role = roles[operatorRole] ?? 'Operador'
  const name = operator.username
  return { name, role }
}

export const convertKeysCase = (obj, format) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysCase)
  }

  const resultObj = {}

  if (format === 'snake') {
    Object.keys(obj).forEach((key) => {
      const snakeCaseKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`
      )
      resultObj[snakeCaseKey] = convertKeysCase(obj[key])
    })
  }

  if (format === 'camel') {
    Object.keys(obj).forEach((key) => {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase()
      )
      resultObj[camelCaseKey] = convertKeysCase(obj[key])
    })
  }
  return resultObj
}

export const minDiffCalculator = (nums) => {
  if (Array.isArray(nums) && nums.length === 0) return 100
  nums.sort((a, b) => a - b)
  let minDiff = Infinity
  for (let i = 0; i < nums.length - 1; i++) {
    const currentDiff = nums[i + 1] - nums[i]
    if (currentDiff < minDiff && currentDiff > 0) {
      minDiff = currentDiff
    }
  }

  return minDiff
}

export const formatterTransactions = (transactions) => {
  if (!Array.isArray(transactions) || transactions.length < 1)
    return { amount: 0, qty: 0, amountByQty: 0 }

  const sum = (a, b) => a + b

  const amountArray = transactions.map((item) => {
    return item.amount
  })
  const totalAmounts = amountArray.reduce(sum, 0)
  const qtyArray = transactions.map((item) => item.qty)
  const totalQty = qtyArray.reduce(sum, 0)
  const amount = totalAmounts / amountArray.length
  const qty = totalQty / qtyArray.length
  return {
    amount,
    qty,
    amountByQty: amount / qty
  }
}

export const getDates = (period) => {
  const today = moment().startOf('day')
  const lastMonthDay = moment().startOf('day').subtract(1, period)
  const daysDiff = today.diff(lastMonthDay, 'days')
  const days = []
  for (let i = 0; i <= daysDiff; i++) {
    days.push(moment(lastMonthDay).add(i, 'days').format('YYYY-MM-DD'))
  }
  return days
}

export const handlePhone = (phone) => {
  if (phone) {
    return phone.replace(/(\d{4})(\d{3})(\d{4})/, '$1-$2-$3')
  }
}

export const inputPrice = (x) => {
  x = x.replace(/[,.]/g, '') // string of only numbers
  const parts = []
  if (x.length < 3) {
    //read the decimal part
    parts[0] = 0
    parts[1] = x
  } else {
    parts[0] = x.slice(0, x.length - 2)
    if (parts[0].charAt(0) === '0') {
      // When the integer part have '0' in the first element, should be delete it
      parts[0] = parts[0].substring(1)
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') // Each three numbers are separate with a point
    parts[1] = x.slice(x.length - 2, x.length)
  }
  return parts.join(',') //Join integer and float part to show
}

export const handleSubmitAmount = (amount) => {
  if (amount) {
    return amount.replace(/\./g, '').replace(/,/g, '.')
  } else {
    return amount
  }
}

export const handleShowAmount = (amount) => {
  return parseFloat(amount).toLocaleString('de-DE')
}

export const handleShowAmountBs = (amount, currency = 'Bs') => {
  return `${currency}. ${new Intl.NumberFormat('de-DE').format(
    Math.floor(amount * 100) / 100
  )}`
}

export const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}
