// ** Icons Import
import {
  FileText,
  Circle,
  Navigation2,
  DollarSign,
  Users,
  Activity,
  Package,
  Clipboard
} from 'react-feather'

export default [
  {
    id: 'Tickets',
    title: 'Transacciones',
    action: 'concesion-level-1',
    resource: 'tollSystem',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'History',
        title: 'Viajes',
        action: 'concesion-level-1',
        resource: 'tollSystem',
        icon: <Circle size={12} />,
        navLink: '/tolls/tickets/list'
      },
      {
        id: 'Paquetes',
        title: 'Paquetes',
        action: 'concesion-level-1',
        resource: 'tollSystem',
        icon: <Circle size={12} />,
        navLink: '/tolls/bundles/history'
      }
    ]
  },
  {
    id: 'Tickets',
    title: 'Transacciones',
    action: 'peaje-level-0',
    resource: 'tollSystem',
    icon: <FileText size={20} />,
    children: [
      {
        id: 'History',
        title: 'Viajes',
        action: 'peaje-level-0',
        resource: 'tollSystem',
        icon: <Circle size={12} />,
        navLink: '/tolls/tickets/list'
      }
    ]
  },
  {
    header: 'Comercialización',
    action: 'common',
    resource: 'tollSystem'
  },
  {
    id: 'vehicles',
    title: 'Vehículos',
    icon: <Navigation2 size={20} />,
    action: 'concesion-level-0',
    resource: 'tollSystem',
    children: [
      {
        id: 'Registro',
        title: 'Registro',
        icon: <Circle size={12} />,
        action: 'concesion-level-0',
        resource: 'tollSystem',
        navLink: '/tolls/vehicles/register'
      }
    ]
  }
]
