import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getTowCompany: `${parkingMetersURL}/superadmin/commerce/towing-company/list`,
  createTowCompany: `${parkingMetersURL}/superadmin/commerce/towing-company`
}

export const getTowCompanyList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getTowCompany,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createTowCompany = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createTowCompany,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
