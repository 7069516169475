import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setBilling,
  setSelectedBilling,
  setCurrentExchangeRate,
  setPreviewBilling
} from './slice'
import {
  deleteDirectoryService,
  generateBillService,
  getDirectoryService,
  previewBillingService
} from '../../../services/wallet/history'

export const previewBilling = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { currentBillingForm } = getState().billing
      const { ok, res, error } = await previewBillingService(currentBillingForm)

      if (!ok) throw error

      toast.success(res.message ?? 'Factura generada correctamente')
      dispatch(setPreviewBilling(res.bill))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getBilling = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().billing.filters
      const { ok, res, error } = await getDirectoryService(filters)

      if (!ok) throw error

      dispatch(setBilling(res.billing_fiscal_directory))
      dispatch(setCurrentExchangeRate(res.exchange_usd_to_bs))
      dispatch(setNextPageExists(true))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteBilling = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedBilling } = getState().billing

      const body = {
        _id: selectedBilling._id
      }
      const { ok, res, error } = await deleteDirectoryService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Factura elimina correctamente')
      dispatch(getBilling())
      dispatch(setSelectedBilling(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const generateBill = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { currentBillingForm } = getState().billing
      const { ok, res, error } = await generateBillService(currentBillingForm)

      if (!ok) throw error

      toast.success(res.message ?? 'Factura generada correctamente')
      dispatch(setSelectedBilling(res.bill))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
