import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import {
  setAreas,
  setCommerce,
  setCommerceOptions,
  setData,
  setLoading,
  setNextPageExists,
  setSubzones,
  setZones
} from './slice'

import { getOperatorData } from '@utils'

const endpoints = {
  getTickets: `${parkingMetersURL}/admin/parkingmeter-tickets/list`,
  loadCommerce: `${parkingMetersURL}/admin/commerce/associated`,
  loadArea: `${parkingMetersURL}/admin/areas/list`,
  loadZones: `${parkingMetersURL}/admin/zones/list`,
  loadSubZones: `${parkingMetersURL}/admin/subzones/list`
}

const getCommerce = async (body) => {
  try {
    const response = await apiMethods.authGet({
      url: endpoints.loadCommerce,
      body
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadCommerce = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await getCommerce()

      if (ok) {
        const commerceIds = res.data.map((commerce) => commerce._id)
        const commerceOptions = res.data.map((commerce) => ({
          value: commerce._id,
          label: `${commerce.short_name}`
        }))

        dispatch(setCommerceOptions(commerceOptions))
        dispatch(setCommerce(commerceIds))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const getSubzones = async (body) => {
  try {
    const response = await apiMethods.authPost({
      url: endpoints.loadSubZones,
      body
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadSubzones = (filterBody) => {
  return async (dispatch, getState) => {
    try {
      const body = {
        pagination: {
          page: 1,
          limit: 100
        },
        filterOptions: {
          entity_commerces_ids: getState().ticketReducer.entity_commerces_ids,
          ...filterBody
        }
      }

      const { ok, res } = await getSubzones(body)

      if (ok) {
        const subzoneList = res.data.items.map((subzone) => {
          return {
            value: subzone._id,
            label: `${subzone.display_name} - ${subzone.code}`,
            area: subzone.area._id,
            zone: subzone.zone._id
          }
        })

        dispatch(setSubzones(subzoneList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const getZone = async (body) => {
  try {
    const response = await apiMethods.authPost({
      url: endpoints.loadZones,
      body
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadZones = (filterBody) => {
  return async (dispatch, getState) => {
    try {
      const body = {
        pagination: {
          page: 1,
          limit: 100
        },
        filterOptions: {
          entity_commerces_ids: getState().ticketReducer.entity_commerces_ids,
          ...filterBody
        }
      }

      const { ok, res } = await getZone(body)

      if (ok) {
        const zoneList = res.data.items.map((zone) => {
          return {
            value: zone._id,
            label: zone.display_name,
            area: zone.area._id
          }
        })

        dispatch(setZones(zoneList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const getArea = async (body) => {
  try {

    const response = await apiMethods.authPost({
      url: endpoints.loadArea,
      body
    })

    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadArea = () => {
  return async (dispatch, getState) => {
    try {
      const body = {
        pagination: {
          page: 1,
          limit: 100
        },
        filterOptions: {
          entity_commerces_ids: getState().ticketReducer.entity_commerces_ids
        }
      }

      const { ok, res } = await getArea(body)

      if (ok) {
        const areaList = res.data.items.map((area) => {
          return {
            value: area._id,
            label: area.display_name,
            commerce: area.entity_commerce._id
          }
        })

        dispatch(setAreas(areaList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getTickets = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.getTickets,
        body: getState().ticketReducer.filters
      })

      dispatch(setLoading(false))

      if (ok) {
        dispatch(setData(res.data.items))
        dispatch(setNextPageExists(res.data.nextPageExists))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
