import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getAreas: `${parkingMetersURL}/admin/areas/list`,
  getAreasPos: `${parkingMetersURL}/pos/areas/list`,
  getAreasSuperadmin: `${parkingMetersURL}/superadmin/areas/list`,
  createArea: `${parkingMetersURL}/superadmin/areas`,
  updateArea: `${parkingMetersURL}/superadmin/areas/update`,
  deleteArea: `${parkingMetersURL}/superadmin/areas`,
  associate: `${parkingMetersURL}/superadmin/areas/profit-distribution`,
  associatePenalty: `${parkingMetersURL}/superadmin/areas/penalty-profit-distribution`,
  associateTowPenalty: `${parkingMetersURL}/superadmin/areas/tow-profit-distribution`

}

export const associateCompanyToAreaService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.associate,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const associateCompanyPenaltyToAreaService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.associatePenalty,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const associateTowCompanyPenaltyToAreaService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.associateTowPenalty,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getAreasList = async (body) => {
  try {

    const response = await apiMethods.authPostFetch({
      url: endpoints.getAreasSuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getAreasAdmin = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getAreas,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const getAreasPos = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getAreasPos,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createArea = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createArea,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateAreaService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.updateArea,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteAreaService = async (id) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: `${endpoints.deleteArea}/${id}`
    })
    return response
  } catch (error) {
    return error
  }
}
