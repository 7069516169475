import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd02 } from '@configs/apis'

const endpoints = {
  getTransactions: `${baseV1URL}/superadmin/transactions/overtime`,
  approveTransactions: `${baseV1URL}/superadmin/transactions/overtime/status`
}

export const getApproveTransactionsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getTransactions}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const approveTransactionsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.approveTransactions}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
