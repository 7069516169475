import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  getClosingHistoryService,
  getComparativeDistributionService,
  getLatestTicketsService,
  getTemporalDistributionService
} from '../../../services/parking-lot/dashboard'
import {
  setClosingHistory,
  setCurrentPeriodData,
  setLastTickets,
  setLoading,
  setPreviousAmount,
  setPreviousTickets,
  setPreviusPeriodData,
  setTemporalAxisData,
  setTemporalPeriodData,
  setTicketsTotal,
  setTotalAmount,
  setxAxisData
} from './slice'

import { getVenezuelaHourPrefix } from '@utils'

export const getTemporalDistribution = (query = 'daily') => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { filters: body } = getState().parkingLotDashboard

      const { ok, res, error } = await getTemporalDistributionService(
        query,
        body
      )

      if (!ok) throw error

      const xAxis = []
      const temporalPeriodData = []
      const temporalPeriodDataAmount = []

      if (query === 'daily') {
        const temporalDistribution = res.temporal_distribution

        temporalDistribution.map((item) => {
          xAxis.push(item.dateKey)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })
      }

      if (query === 'weekly') {
        const temporalDistribution = res.temporal_distribution

        temporalDistribution.map((item) => {
          xAxis.push(`De ${item.weekStart} a ${item.weekEnd}`)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })
      }

      if (query === 'monthly') {
        const temporalDistribution = res.temporal_distribution

        temporalDistribution.map((item) => {
          xAxis.push(item.monthKey)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })
      }
      if (query === 'custom') {
        const temporalDistribution = res.temporal_distribution
        temporalDistribution.map((item) => {
          xAxis.push(item.dateKey)
          temporalPeriodData.push(item.tickets)
          temporalPeriodDataAmount.push(item.amount)
        })

        // temp
        dispatch(setxAxisData(xAxis))
        dispatch(setCurrentPeriodData(temporalPeriodData))
        dispatch(setPreviusPeriodData([]))
      }

      dispatch(setTemporalAxisData(xAxis))
      dispatch(
        setTemporalPeriodData({
          tickets: temporalPeriodData,
          amount: temporalPeriodDataAmount
        })
      )
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getComparativeDistribution = (query = 'daily') => {
  return async (dispatch, getState) => {
    try {
      if (query === 'custom') return

      dispatch(setLoading(true))

      const { filters: body } = getState().parkingLotDashboard

      const { ok, res, error } = await getComparativeDistributionService(
        query,
        body
      )

      if (!ok) throw error
      const currentPeriod = res.current_period
      const previusPeriod = res.previous_period

      let xAxis = []
      let currentPeriodData = []
      let previusPeriodData = []

      const tickets = currentPeriod.tickets
      const amount = currentPeriod.amount
      dispatch(setTotalAmount(amount))
      dispatch(setTicketsTotal(tickets))
      dispatch(setPreviousAmount(previusPeriod.amount))
      dispatch(setPreviousTickets(previusPeriod.tickets))

      if (query === 'daily') {
        const usdAmount = currentPeriod.usdAmount
        const tickets = currentPeriod.tickets
        const amount = currentPeriod.amounts
        const currentMetrics = currentPeriod.hourlyMetrics
        const previusMetrics = previusPeriod.hourlyMetrics

        xAxis = Object.keys(currentMetrics).map((key) => `${key.slice(-2)}:00`)
        currentPeriodData = Object.values(currentMetrics).map(
          (metric) => metric.tickets
        )
        previusPeriodData = Object.values(previusMetrics).map(
          (metric) => metric.tickets
        )
        const currentPeriodAmount = Object.values(previusMetrics).map(
          (metric) => metric.amount
        )

        const date = new Date()
        const hourPrefix = `h${getVenezuelaHourPrefix(date)}`
        let totalAmount = 0
        let totalTickets = 0
        for (const key in previusMetrics) {
          if (key > hourPrefix) break
          totalAmount += previusMetrics[key].amount
          totalTickets += previusMetrics[key].tickets
        }

        dispatch(setTotalAmount(amount))
        dispatch(setTicketsTotal(tickets))

        dispatch(setPreviousAmount(totalAmount))
        dispatch(setPreviousTickets(totalTickets))
      } else if (query === 'weekly' || query === 'monthly') {
        const currentMetrics = currentPeriod.dailyMetrics
        const previusMetrics = previusPeriod.dailyMetrics

        xAxis = currentMetrics.map((item) => item.dayKey)
        currentPeriodData = currentMetrics.map((item) => item.tickets)
        previusPeriodData = previusMetrics.map((item) => item.tickets)
      }

      dispatch(setxAxisData(xAxis))
      dispatch(setCurrentPeriodData(currentPeriodData))
      dispatch(setPreviusPeriodData(previusPeriodData))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getLatestTickets = (query = 'daily') => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { filters } = getState().parkingLotDashboard

      const body = {
        ...filters,
        page: 0,
        limit: 12
      }

      const { ok, res, error } = await getLatestTicketsService(query, body)

      if (!ok) throw error
      dispatch(setLastTickets(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getClosingHistory = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { filters } = getState().parkingLotDashboard

      const building_id = filters.buildings_ids[0]

      const body = {
        building_id,
        page: 0,
        limit: 12
      }

      const { ok, res, error } = await getClosingHistoryService(body)

      if (!ok) throw error
      dispatch(setClosingHistory(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
