// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn, getNameAndRole } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power,
  Sun,
  Moon
} from 'react-feather'

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'

const UserDropdown = ({ skin, setSkin }) => {
  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [operatorData, setOperatorData] = useState(null)
  const [{ name, role }, setOperatorMapped] = useState({ name: '', role: '' })
  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      const operatorLogged = JSON.parse(localStorage.getItem('operator'))
      setOperatorData(operatorLogged)
      setOperatorMapped(getNameAndRole(operatorLogged))
    }
  }, [])

  //** Vars
  const operatorAvatar = (operatorData && operatorData.avatar) || defaultAvatar
  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle
        href='/'
        tag='a'
        className='nav-link dropdown-user-link'
        onClick={(e) => e.preventDefault()}
      >
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{name}</span>
          <span className='user-status'>{role}</span>
        </div>
        <Avatar
          img={operatorAvatar}
          imgHeight='40'
          imgWidth='40'
          status='online'
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem
          tag={Link}
          onClick={() => {
            skin === 'dark' ? setSkin('light') : setSkin('dark')
          }}
          className='d-flex align-items-center justify-content-between'
        >
          {skin === 'light' && <Moon className='me-75' />}
          {skin === 'dark' && <Sun className='me-75' />}
          <span className='align-middle'>
            {skin === 'light' ? ' Oscuro' : ' Claro'}
          </span>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to='/login'
          onClick={() => dispatch(handleLogout())}
          className='d-flex align-items-center justify-content-between'
        >
          <Power size={14} className='me-75' />
          <span className='align-middle'>Cerrar sesión</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
