import io from 'socket.io-client'
import { socketURL } from '@configs/apis'
import { getOperatorData } from '@utils'

const socket = io(socketURL, {
  transports: ['websocket']
})

socket.on('connect', () => {
  socket.sendBuffer = []
})

export const initiateSocket = () => {
  console.log('new socket connection')
}

export const joinSocket = (token, cb) => {
  socket.on('join', (msg) => cb(msg))
  socket.emit('join', { token })
}

export const joinCommerceSocket = (token, cb) => {
  socket.on('join commerce', (msg) => cb(msg))
  socket.emit('join commerce', { token })
}

export const addSocketListener = (event, cb) => {
  socket.on(event, (msg) => {
    return cb(msg)
  })
}

export const removeSocketListener = (event = false, cb = false) => {
  if (!event && !cb) {
    socket.off()
    console.log('Socket disconnected')
  } else {
    if (typeof event === 'string' && typeof cb === 'function') {
      socket.off(event, (msg) => {
        return cb(msg)
      })
    } else if (typeof event === 'string') {
      socket.off(event)
    }
  }
}

export const markTicketAsPaid = (
  token,
  { ticket_number, username, building }
) => {
  socket.emit('mark as paid', {
    username,
    ticket: ticket_number,
    token,
    building: building._id
  })
}

export const historyMarkTicketAsPaid = ({
  ticket_number,
  username,
  building,
  paid_at,
  enable_ticket_type
}) => {
  const { token } = getOperatorData()
  socket.emit('mark as paid', {
    username,
    ticket: ticket_number,
    token,
    building: building._id,
    paid_at,
    enable_ticket_type
  })
}

export const calculateTicketAmount = (building, ticket) => {
  const { token } = getOperatorData()
  socket.emit('calculate amount', {
    building,
    ticket,
    token
  })
}

export const digitizeTicket = (token, ticketData) => {
  socket.emit('digitized ticket', {
    ...ticketData,
    token
  })
}

export const commerceDigitizeTicket = (ticketData) => {
  const { token } = getOperatorData()
  socket.emit('digitized ticket', {
    ...ticketData,
    token
  })
}

export const disconnectSocket = () => {
  socket.disconnect()
}
