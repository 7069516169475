import toast from 'react-hot-toast'

import { errors } from '@src/assets/constants/text'

import { BasicToastContent } from '@src/components/basic-toast-content'
import { parkingMetersURL } from '@configs/apis'
import { apiMethods } from '@services/methods'

import {
  setAreas,
  setCommerce,
  setCommerceOptions,
  setData,
  setEntities,
  setLoading,
  setNextPageExists,
  setSelectedTransactions,
  setSubzones,
  setTransactions,
  setZones
} from './slice'
import {
  getAreasAdmin,
  getAreasList,
  getAreasPos
} from '../../services/parking-meters/areas'
import {
  getSubzonesAdmin,
  getSubzonesList,
  getSubzonesPos
} from '../../services/parking-meters/subzones'
import {
  getZonesAdmin,
  getZonesList,
  getZonesPos
} from '../../services/parking-meters/zones'
import { getCommerceData, getNameAndRole } from '../../utility/Utils'
import { getEntitiesList } from '../../services/parking-meters/entity'

const endpoints = {
  getTickets: `${parkingMetersURL}/admin/parkingmeter-tickets/list`,
  getTicketsPos: `${parkingMetersURL}/pos/parkingmeter-tickets/list`,
  getTicketsSuperadmin: `${parkingMetersURL}/superadmin/parkingmeter-tickets/list`,

  loadCommerce: `${parkingMetersURL}/admin/commerce/associated`,

  closeTicket: `${parkingMetersURL}/admin/parkingmeter-tickets/stop-ticket`
}

export const getTickets = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().parkingMeters
      const { isSuperadmin } = getState().superadmin

      const commerceType = getCommerceData()
      const type = commerceType?.type

      let url
      if (type === 'pos') {
        url = endpoints.getTicketsPos
      } else {
        url = isSuperadmin
          ? endpoints.getTicketsSuperadmin
          : endpoints.getTickets
      }

      dispatch(setLoading(true))

      const { ok, res } = await apiMethods.authPost({
        url,
        body: filters
      })

      if (!ok) throw res

      dispatch(setLoading(false))
      dispatch(setTransactions(res?.data?.items ?? []))
      dispatch(setNextPageExists(res?.data?.nextPageExists))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible cargar los tickets'}
          type={'danger'}
        />
      ))
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const closeParkingMeterTicket = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedTransaction } = getState().parkingMeters
      const sendBody = {
        ticket_id: selectedTransaction?._id
      }

      const { ok, res } = await apiMethods.authPost({
        url: endpoints.closeTicket,
        body: sendBody
      })

      dispatch(setSelectedTransactions(res?.data?.ticket))
      dispatch(getTickets())
      toast.success(res?.message ?? 'Ticket cerrado correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getCommerce = () => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      if (isSuperadmin) return

      const { ok, res } = await apiMethods.authGet({
        url: endpoints.loadCommerce
      })

      if (!ok) throw res

      const commerceIds = res.data.map((commerce) => commerce._id)
      const commerceOptions = res.data.map((commerce) => ({
        value: commerce._id,
        label: `${commerce.short_name}`
      }))

      dispatch(setCommerceOptions(commerceOptions))
      dispatch(setCommerce(commerceIds))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible obtener la información'}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}

export const getEntitiesOptions = () => {
  return async (dispatch, getState) => {
    try {
      const { locationFilters: filters } = getState().parkingMeters

      const { isSuperadmin } = getState().superadmin

      if (!isSuperadmin) return

      const { ok, res } = await getEntitiesList({
        filterOptions: {
          fiscal_name: ''
        }
      })

      if (!ok) throw res

      const entityList = res.data.items.map((entity) => {
        return {
          value: entity._id,
          label: entity.name
        }
      })

      dispatch(setEntities(entityList))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible cargar las area'}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}

export const getAreasOptions = () => {
  return async (dispatch, getState) => {
    try {
      const { locationFilters: filters, entity_commerces_ids } =
        getState().parkingMeters

      const { isSuperadmin } = getState().superadmin

      const commerceType = getCommerceData()
      const type = commerceType?.type

      let fetchAreas
      if (type === 'pos') {
        fetchAreas = getAreasPos
      } else {
        fetchAreas = isSuperadmin ? getAreasList : getAreasAdmin
      }

      const { ok, res } = await fetchAreas(filters)

      if (!ok) throw res

      const areaList = res.data.items.map((area) => {
        return {
          value: area._id,
          label: area.display_name,
          commerce: area.entity_commerce._id
        }
      })

      dispatch(setAreas(areaList))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible cargar las area'}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}

export const getZonesOptions = () => {
  return async (dispatch, getState) => {
    try {
      const { locationFilters: filters } = getState().parkingMeters

      const { isSuperadmin } = getState().superadmin

      const commerceType = getCommerceData()
      const type = commerceType?.type

      let fetchZones
      if (type === 'pos') {
        fetchZones = getZonesPos
      } else {
        fetchZones = isSuperadmin ? getZonesList : getZonesAdmin
      }

      const { ok, res } = await fetchZones(filters)

      if (!ok) throw res

      const zoneList = res.data.items.map((zone) => {
        return {
          value: zone._id,
          label: zone.display_name,
          ...(!isSuperadmin && { area: zone.area._id })
        }
      })

      dispatch(setZones(zoneList))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible cargar las area'}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}

export const getSubzonesOptions = () => {
  return async (dispatch, getState) => {
    try {
      const { locationFilters: filters } = getState().parkingMeters
      const { isSuperadmin } = getState().superadmin

      const commerceType = getCommerceData()
      const type = commerceType?.type

      let fetchSubzones
      if (type === 'pos') {
        fetchSubzones = getSubzonesPos
      } else {
        fetchSubzones = isSuperadmin ? getSubzonesList : getSubzonesAdmin
      }

      const { ok, res } = await fetchSubzones(filters)

      if (!ok) throw res

      const subzoneList = res.data.items.map((subzone) => {
        return {
          value: subzone._id,
          label: `${subzone.display_name} - ${subzone.code}`,
          area: subzone.area._id,
          zone: subzone.zone._id
        }
      })

      dispatch(setSubzones(subzoneList))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible cargar las area'}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}
