import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  setNextPageExists,
  setSubzone,
  setLoading,
  setAssociatedCompany,
  setSelectedAssociatedCompany,
  setAssociatedCompanyOptions
} from './'

import {
  createTowCompany,
  getTowCompanyList
} from '../../../services/parking-meters/tow-company'
import {
  associateCompanyToAreaService,
  updateEntityService
} from '../../../services/parking-meters'
import {
  createAssociatedCompany,
  getAssociatedCompanyList,
  updateAssociatedCompanyService
} from '../../../services/parking-meters/associated-company'
import { set } from 'react-hook-form'

export const getAssociatedCompany = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().associatedCompany.filters

      const { ok, res, error } = await getAssociatedCompanyList(filters)

      if (!ok) throw error

      dispatch(setAssociatedCompany(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAssociatedCompanyOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().associatedCompany.filters
      const { pagination, ...filtersWithOutPagination } = filters

      const { ok, res, error } = await getAssociatedCompanyList(
        filtersWithOutPagination
      )

      if (!ok) throw error

      const associatedCompanyOptions = res.data.items.map((company) => ({
        value: company._id,
        label: company.name
      }))

      dispatch(setAssociatedCompanyOptions(associatedCompanyOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addAssociatedCompany = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createAssociatedCompany(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía creada correctamente')

      dispatch(getAssociatedCompany())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateAssociatedCompany = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await updateAssociatedCompanyService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía actualizada correctamente')

      const updatedEntity = res.data.updatedEntity

      dispatch(setSelectedAssociatedCompany(updatedEntity))
      dispatch(getAssociatedCompany())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateAssociatedCompanyStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedAssociatedCompany } = getState().associatedCompany

      const sendBody = {
        ...selectedAssociatedCompany,
        status:
          selectedAssociatedCompany.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = await updateAssociatedCompanyService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía actualizada correctamente')

      dispatch(getAssociatedCompany())
      dispatch(setSelectedAssociatedCompany(res?.data?.updatedEntity || null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteAssociatedCompany = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedAssociatedCompany } = getState().associatedCompany

      const sendBody = {
        ...selectedAssociatedCompany,
        status: 'deleted'
      }

      const { ok, res, error } = await updateAssociatedCompanyService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía eliminada correctamente')

      dispatch(getAssociatedCompany())
      dispatch(setSelectedAssociatedCompany(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
