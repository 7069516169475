import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAreas: (state, action) => {
      state.data = action.payload
    },
    setAreasFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload
    },
    setAreasOptions: (state, action) => {
      state.areasOptions = action.payload
    }
  }
})

export const {
  setAreasFilters,
  setLoading,
  setAreas,
  setNextPageExists,
  setSelectedArea,
  setAreasOptions
} = slice.actions

export const areas = slice.reducer
