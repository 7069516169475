const defaultActions = {
  add: { action: 'add', message: 'Agregar usuario' },
  addExtension: { action: 'addExt', message: 'Agregar extensión' },
  deleteExtension: { action: 'deleteExt', message: 'Eliminar extensión' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  update: { action: 'update', message: 'Actualizar usuario' },
  delete: { action: 'deleted', message: 'eliminar' },
  recharge: { action: 'recharge', message: 'Recargar wallet' },
  resetPassword: { action: 'resetPassword', message: 'restablecer' },
  debit: { action: 'debit', message: 'Debitar' },
  addPromotionTickets: {
    action: 'addPromotionTickets',
    message: 'Gestión de tickets '
  },
  deletePromotionTickets: {
    action: 'deletePromotionTickets',
    message: 'Eliminar tickets '
  }
}

const defaultVehicleActions = {
  add: { action: 'add', message: 'Agregar vehículo' },
  update: { action: 'update', message: 'Actualizar vehículo' },
  delete: { action: 'deleted', message: 'eliminar' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  reversePremium: { action: 'reversePremium', message: 'reversar premium' },
  reverseParkingMeter: {
    action: 'reverseParkingMeter',
    message: 'reversar parquímetros'
  },
  reverseToll: { action: 'reverseToll', message: 'reversar peaje' },
  reverseParking: { action: 'reverseParking', message: 'reversar parqueo' },
  reverseParkingOvertime: {
    action: 'reverseParkingOvertime',
    message: 'reversar sobre tiempo'
  }
}

export const initialState = {
  actions: defaultActions,
  actionsVehicle: defaultVehicleActions,
  loading: false,
  selectedCustomer: null,
  selectedExtension: null,
  selectedVehicle: null,
  selectedRecharge: null,
  selectedVehicleList: [],
  customersList: [],
  autocomplete: ''
}
