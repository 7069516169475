import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const prefix = '/wallet'

const Billing = lazy(() => import('@billingViews/billing'))
const History = lazy(() => import('@billingViews/history'))
const Accounts = lazy(() => import('@walletViews/accounts'))
const ConsolidatePosition = lazy(() =>
  import('@walletViews/consolidate-position')
)
const TodayMovements = lazy(() =>
  import('@walletViews/today-movements')
)
const Transfers = lazy(() =>
  import('@walletViews/transfers')
)
const TransfersHistory = lazy(() =>
  import('@walletViews/transfers-history')
)
const Audit = lazy(() =>
  import('@walletViews/audit')
)

// const SearchUsers = lazy(() => import('@usersViews/search-users'))
const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const ParkingLotRoutes = [
  {
    element: <Audit />,
    path: `${prefix}/audit`,
    meta: {
      actions: ['pagodirecto-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <Audit />,
    path: `${prefix}/audit`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  },
  {
    element: <Accounts />,
    path: `${prefix}/accounts`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  },
  {
    element: <ConsolidatePosition />,
    path: `${prefix}/consolidate-position`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  },
  {
    element: <TodayMovements />,
    path: `${prefix}/today-movements`,
    meta: {
      actions: ['pagodirecto-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <Transfers />,
    path: `${prefix}/transfers`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  },
  {
    element: <TransfersHistory />,
    path: `${prefix}/transfers-history`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  },
  {
    element: <Billing />,
    path: `${prefix}/billing`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  },
  {
    element: <History />,
    path: `${prefix}/history`,
    meta: {
      actions: [ability.MANAGER, ability.FINANCE],
      resources: ['Common']
    }
  }
]

export default ParkingLotRoutes
