import { apiMethods } from '@services/methods'
import { baseV2URL, baseV1URL } from '@configs/apis'
import { updateBilling } from '../../../redux/parking-lot/buildings'

const endpoints = {
  getBuildings: `${baseV1URL}/buildings`,
  getAccessLogs: `${baseV2URL}/superadmin/logs`,
  updateBilling: `${baseV2URL}/superadmin/buildings/rates`,
  access: `${baseV2URL}/superadmin/buildings/accesses`,
  addBoxOffice: `${baseV2URL}/superadmin/signup/taquilla`,
  deleteAccess: `${baseV2URL}/superadmin/buildings/accesses/delete`
}

export const getBuildingsLogs = async (filters) => {
  try {
    const url = endpoints.getAccessLogs
    const body = filters
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const updateBillingService = async (body) => {
  try {
    const url = endpoints.updateBilling
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getBuildingsInfo = async () => {
  try {
    const url = endpoints.getBuildings
    const { ok, res, error } = await apiMethods.authGetFetch(url)

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const updateAccessService = async (body) => {
  try {
    const url = endpoints.access
    const { ok, res, error } = await apiMethods.authPatchFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const addAccessService = async (body) => {
  try {
    const url = endpoints.access
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const addBoxOfficeService = async (body) => {
  try {
    const url = endpoints.addBoxOffice
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const deleteAccessService = async (body) => {
  try {
    const url = endpoints.deleteAccess
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}
