import {
  commerceDigitizeTicket,
  calculateTicketAmount,
  historyMarkTicketAsPaid
} from '@services/websocket'
import {
  setLoading,
  setTicketsList,
  setPagination,
  setTicketOptionLoading,
  setSelectedTicket,
  setTicketAmount
} from '@store/parking-lot/tickets'
import {
  autocomplete,
  dateRange,
  discard,
  refound,
  closeTicketService,
  commerceTicketsAvailability as ticketsAvailability
} from '@services/parking-lot/tickets'
import toast from 'react-hot-toast'
import { errors } from '@src/assets/constants/text'
import {
  checkTicketStatusService,
  getBuildingTicketAmountService,
  getBuildingsByTicketService,
  getInfoBuildingTicketService,
  getUserByDocumentService,
  payTicketsService
} from '../../../services/parking-lot/tickets'
import { BasicToastContent } from '@src/components/basic-toast-content'
import {
  setPhysicalTicketBuildingsOptions,
  setSelectedPhysicalBuilding,
  setSelectedPhysicalTicket
} from './slice'
import { getBuildingData } from '@utils'
import { t } from 'i18next'

const buildingData = getBuildingData()
const currentBuildingCode =
  buildingData && buildingData.building && buildingData.building.code

export const getUserByDocument = (document) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getUserByDocumentService(document)
      if (!ok) throw error

      const username = res?.data?.username
      const userFunds = res?.data?.funds
      if (!username) {
        toast.error('Usuario no encontrado')
        throw new Error('Usuario no encontrado')
      }

      dispatch(
        setSelectedPhysicalBuilding({
          username,
          sender_name: username,
          userFunds
        })
      )

      toast.success('Usuario encontrado')

      return res
    } catch (error) {
      throw error
    }
  }
}

export const getTicketInfo = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedPhysicalBuilding } = getState().parkingLotTickets

      const body = {
        building_code_pd: selectedPhysicalBuilding?.building_code_pd,
        ticket_number: selectedPhysicalBuilding?.ticket_number
      }

      dispatch(setLoading(true))

      const { ok, res, error } = await getInfoBuildingTicketService(body)

      if (!ok) throw error

      dispatch(setSelectedPhysicalTicket(res))

      toast.success(res.message ?? 'Ticket encontrado con éxito')
    } catch (error) {
      toast.error(error?.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const payTickets = (ticket) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedPhysicalBuilding } = getState().parkingLotTickets

      const body = {
        ...selectedPhysicalBuilding,
        exit_method: 'ticket_fisico',
        sender_name: selectedPhysicalBuilding.username,
        pd_fee: Number(selectedPhysicalBuilding.pd_fee),
        amount: Number(selectedPhysicalBuilding.amount)
      }

      const { ok, res, error } = await payTicketsService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Ticket pagado con éxito')
    } catch (error) {
      toast.error(error.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const setTicketAmountBuilding = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedPhysicalBuilding } = getState().parkingLotTickets
      const { isSuperadmin } = getState().superadmin
      const { building_code_pd, ticket_number } = selectedPhysicalBuilding

      const currentDate = new Date()
      const body = {
        enter_at: currentDate,
        building_code: building_code_pd,
        sspos: false
      }

      const { ok, res, error } = await getBuildingTicketAmountService(body)

      if (!ok) throw error

      const buildingInfo = {
        ...selectedPhysicalBuilding,
        pd_fee: res?.pd_fee.toFixed(2),
        amount: res?.amount.toFixed(2),
        entry_date: currentDate,
        sender_type: isSuperadmin ? 'SUPERADMIN' : 'ADMIN'
      }

      dispatch(setSelectedPhysicalBuilding(buildingInfo))
    } catch (error) {
      toast.error(error?.message ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const checkTicketStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedPhysicalBuilding } = getState().parkingLotTickets

      const building_code_pd = selectedPhysicalBuilding?.building_code_pd ?? ''
      const ticket_number = selectedPhysicalBuilding?.ticket_number ?? ''

      const body = {
        building_code_pd,
        ticket_number
      }

      const { ok, res, error } = await checkTicketStatusService(body)

      if (res?.code === 'INVALID') throw res
      if (!ok) throw error

      toast.success(res.message ?? 'Ticket valido')

      dispatch(setTicketAmountBuilding())
    } catch (error) {
      toast.error(error?.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getBuildingsByTicket = (ticket) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      const { selectedPhysicalBuilding } = getState().parkingLotTickets

      dispatch(setLoading(true))

      const body = {
        scanned_value: ticket
      }

      const { ok, res, error } = await getBuildingsByTicketService(body)

      if (!ok) throw error

      const buildingsOptions = res?.buildings?.map((building) => ({
        label: building.building_name,
        value: { ...building, scanned_value: ticket }
      }))

      if (isSuperadmin) {
        dispatch(setPhysicalTicketBuildingsOptions(buildingsOptions))
        toast.success(res.message ?? 'Estacionamientos encontrados')
        return
      }

      const isCurrentCodeInTheOptions = buildingsOptions.find(
        (building) => building.value.building_code_pd === currentBuildingCode
      )

      const selectedInfo = {
        ...selectedPhysicalBuilding,
        ...isCurrentCodeInTheOptions?.value
      }

      dispatch(setSelectedPhysicalBuilding(selectedInfo))
    } catch (error) {
      toast.error(error?.message ?? errors.GENERAL_ERR)
      throw error
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const digitizeTicket = (ticketData) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    commerceDigitizeTicket(ticketData)
  }
}

export const getTicketAmount = (ticket) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    calculateTicketAmount(ticket.building._id, ticket._id)
  }
}

export const ticketsHistory = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const {
      pagination,
      dateRange: dates,
      searchForm,
      commerce,
      searchParam,
      searchBy,
      building,
      selectedTicket
    } = getState().parkingLotTickets
    const promiseObj = {
      autocomplete,
      date: dateRange
    }
    const prom = promiseObj[searchBy]
    const { ok, res, error } = await prom({
      ...dates,
      ...searchForm,
      ...pagination,
      commerce,
      building,
      searchParam
    })
    if (!ok) {
      toast.error(error?.description ?? errors.GENERAL_ERR)
      dispatch(setLoading(false))
      return
    }
    const nextPageExists = res?.tickets?.length >= pagination.show
    dispatch(setPagination({ ...pagination, nextPageExists }))
    dispatch(setTicketsList(res?.tickets ?? []))
    const selectedTicketUpdated = res?.tickets?.find(
      (ticket) => ticket._id === selectedTicket?._id
    )
    dispatch(setSelectedTicket(selectedTicketUpdated ?? null))
    dispatch(setLoading(false))
    dispatch(setTicketOptionLoading(false))
  }
}

export const discardTicket = () => {
  return async (dispatch, getState) => {
    dispatch(setTicketOptionLoading(true))
    const { _id: id, username } = getState().parkingLotTickets.selectedTicket

    const { ok, reponse, error } = await discard({ id, username })
    dispatch(setTicketOptionLoading(false))
    if (!ok) {
      toast.error(error?.description ?? errors.GENERAL_ERR)
      return
    }
    dispatch(setSelectedTicket(null))
    dispatch(ticketsHistory())
    toast.success('Ticket descartado con éxito')
  }
}

export const commerceTicketsAvailability = (customer) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await ticketsAvailability({ customer })
      if (!ok) throw error
      if (res.ticket) {
        dispatch(setSelectedTicket(res.ticket))
        dispatch(setTicketAmount(res.ticket.amount))
        if (res.ticket.status === 'ready') dispatch(getTicketAmount(res.ticket))
      }
    } catch (err) {
      console.log(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const chargeTicket = (paidAt, enableTicketType, fromDigitize) => {
  return async (dispatch, getState) => {
    const ticket = getState().parkingLotTickets.selectedTicket
    dispatch(setTicketOptionLoading(true))
    historyMarkTicketAsPaid({
      ...ticket,
      enable_ticket_type: enableTicketType,
      paid_at: paidAt
    })
    if (fromDigitize) {
      const selectedCustomer = getState().customers.selectedCustomer
      dispatch(commerceTicketsAvailability(selectedCustomer._id))
    }
  }
}

export const refoundTicket = () => {
  return async (dispatch, getState) => {
    dispatch(setTicketOptionLoading(true))
    const {
      _id: ticket_id,
      paid_by,
      regular_premium_earning_counted
    } = getState().parkingLotTickets.selectedTicket

    const body = {
      ticket_id,
      paid_by,
      has_premium: regular_premium_earning_counted
    }

    const { ok, reponse, error } = await refound(body)
    dispatch(setTicketOptionLoading(false))
    if (!ok) {
      toast.error(error?.description ?? errors.GENERAL_ERR)
      return
    }
    dispatch(setSelectedTicket(null))
    dispatch(ticketsHistory())
    toast.success('Ticket descartado con éxito')
  }
}

export const closeTicket = (data) => {
  return async (dispatch, getState) => {
    dispatch(setTicketOptionLoading(true))

    const { ok, reponse, error } = await closeTicketService(data)
    dispatch(setTicketOptionLoading(false))
    if (!ok) {
      toast.error(error?.description ?? errors.GENERAL_ERR)
      return
    }
    dispatch(setSelectedTicket(null))
    dispatch(ticketsHistory())
    toast.success('Ticket cerrado con éxito')
  }
}
