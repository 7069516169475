import { apiMethods } from '@services/methods'

import { reportsURL, baseV1URL } from '@configs/apis'
const endpoints = {
  verifiedRegisteredUsers: `${reportsURL}/verified-registered-users`,
  unverifiedRegisteredUsers: `${reportsURL}/unverified-registered-users`,
  userActivities: `${reportsURL}/user-activities`,
  transactionsCustomer: `${reportsURL}/transactions-customer`,
  servicePayment: `${reportsURL}/services-payments`,
  walletRecharges: `${reportsURL}/wallet-recharges`,
  transactionsCreditPremium: `${reportsURL}/transactions-credit-premium`,
  premiumTicketsQtyByUser: `${reportsURL}/ticketspremium-qty-amount-byuser`,
  approvedRechargesSuperadmin: `${reportsURL}/approved-recharges-superadmin`,
  tickets: `${reportsURL}/tickets`,
  unverifiedRegisterdUsersByOffice: `${reportsURL}/verif-unverif-registered-taquilla`,
  transactionsSchools: `${reportsURL}/transactions-schools`,
  parkingLogs: `${reportsURL}/building-logs-entrance-exit`,
  ticketsBuilding: `${reportsURL}/tickets-building-general`,
  commerces: `${baseV1URL}/child/info`,
  parkingLogsBuildingPhysicalAccess: `${reportsURL}/tktfisico-building-daterange`,
  parkingLogsUserPhysicalAccess: `${reportsURL}/tktfisico-username-daterange`,
  parkingMeters: `${reportsURL}/parkingmeters-tickets`,
  transactionsCommercesTransfers: `${reportsURL}/transactions-commerces-transfers`,
  userParkingTickets: `${reportsURL}/user-parking-tickets`,
  tollTickets: `${reportsURL}/toll-tickets`,
  transactionsCustomerInsurance: `${reportsURL}/transactions-customer-insurance`
}

export const transactionsCustomerInsurance = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.transactionsCustomerInsurance,
    body
  })

export const userParkingTickets = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.userParkingTickets,
    body
  })

export const tollTickets = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.tollTickets,
    body
  })

export const transactionsCommercesTransfers = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.transactionsCommercesTransfers,
    body
  })

export const parkingLogsBuildingPhysicalAccess = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.parkingLogsBuildingPhysicalAccess,
    body
  })
export const parkingLogsUserPhysicalAccess = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.parkingLogsUserPhysicalAccess,
    body
  })

export const parkingMetersLogs = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.parkingMeters,
    body
  })

export const parkingMeters = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.parkingMeters,
    body
  })

export const verifiedRegisteredUsers = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.verifiedRegisteredUsers,
    body
  })

export const unverifiedRegisteredUsers = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.unverifiedRegisteredUsers,
    body
  })

export const transactionsCustomer = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.transactionsCustomer,
    body
  })

export const servicePayment = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.servicePayment,
    body
  })

export const walletRecharges = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.walletRecharges,
    body
  })

export const transactionsCreditPremium = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.transactionsCreditPremium,
    body
  })

export const premiumTicketsQtyByUser = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.premiumTicketsQtyByUser,
    body
  })

export const approvedRechargesSuperadmin = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.approvedRechargesSuperadmin,
    body
  })

export const tickets = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.tickets,
    body
  })

export const unverifiedRegisteredUsersByOffice = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.unverifiedRegisterdUsersByOffice,
    body
  })

export const transactionsSchools = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.transactionsSchools,
    body
  })

export const parkingLogs = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.parkingLogs,
    body
  })

export const ticketsBuilding = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.ticketsBuilding,
    body
  })

export const getCommerces = async (body) =>
  await apiMethods.authPostFetch({
    url: endpoints.commerces,
    body
  })

export const userActivities = async (formData) =>
  await apiMethods.authPostFetchFormData({
    url: endpoints.userActivities,
    formData
  })
