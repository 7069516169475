import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import {
  setLoadingReports,
  setNextPageExistsReports,
  setReportsData
} from './slice'

const endpoints = {
  getReports: `${parkingMetersURL}/admin/penalties/reports/list`,
  getReportsSuperadmin: `${parkingMetersURL}/superadmin/penalties/reports/list`
}

export const getReports = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingReports(true))
      const { isSuperadmin } = getState().superadmin

      const url = isSuperadmin
        ? endpoints.getReportsSuperadmin
        : endpoints.getReports

      const { ok, res } = await apiMethods.authPost({
        url,
        body: getState().reportPenaltyReducer.filters
      })

      dispatch(setLoadingReports(false))
      dispatch(setReportsData(res.data.items))
      dispatch(setNextPageExistsReports(res.data.nextPageExists))
    } catch (error) {
      console.log(error)
    }
  }
}
