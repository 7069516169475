import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setCoupons: (state, action) => {
      state.data = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setLoadingUsers: (state, action) => {
      state.loadingUsers = action.payload
    },
    setCouponsFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedCoupons: (state, action) => {
      state.selectedCoupon = action.payload
    },
    clearState: (state) => {
      state.selectedCoupon = null
    },
    setCouponUsers: (state, action) => {
      state.couponUsers = action.payload
    },
    setPrivateCoupons: (state, action) => {
      state.privateCoupons = action.payload
    },
    setCouponsUsesHistory: (state, action) => {
      state.couponsUsesHistory = action.payload
    },
    setPrivateCouponsFilters: (state, action) => {
      state.filtersCouponsPrivate = action.payload
    },
    setCouponsUsesHistoryFilters: (state, action) => {
      state.filtersCouponsUsesHistory = action.payload
    },
    setSelectedCouponsUsesHistory: (state, action) => {
      state.selectedCouponsUsesHistory = action.payload
    },
    setSelectedCouponsPrivate: (state, action) => {
      state.selectedCouponsPrivate = action.payload
    }
  }
})

export const {
  setCoupons,
  setLoading,
  setCouponsFilters,
  setNextPageExists,
  setSelectedCoupons,
  clearState,
  setCouponUsers,
  setPrivateCoupons,
  setCouponsUsesHistory,
  setPrivateCouponsFilters,
  setCouponsUsesHistoryFilters,
  setSelectedCouponsUsesHistory,
  setSelectedCouponsPrivate,
  setLoadingUsers
} = slice.actions

export const coupons = slice.reducer
