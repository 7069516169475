import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  createCouponService,
  deleteCouponService,
  getCouponDetailsService,
  getCouponsList,
  getCouponsUsesHistoryList,
  searchUserService,
  updateCouponService
} from '../../../services/parking-lot/coupons'
import {
  setCoupons,
  setNextPageExists,
  setLoading,
  setCouponUsers,
  setSelectedCoupons,
  setCouponsUsesHistory,
  setLoadingUsers
} from './slice'

export const getCoupons = (isPrivate = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = isPrivate
        ? getState().coupons.filtersCouponsPrivate
        : getState().coupons.filters
      const { ok, res, error } = await getCouponsList(filters)

      if (!ok) throw error

      dispatch(setCoupons(res.coupons.items))
      dispatch(setNextPageExists(res.coupons.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCouponsUsesHistory = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().coupons.filtersCouponsUsesHistory
      const { ok, res, error } = await getCouponsUsesHistoryList(filters)

      if (!ok) throw error

      dispatch(setCouponsUsesHistory(res.items))
      dispatch(setNextPageExists(res.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteCoupon = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedCoupon = getState().coupons.selectedCoupon
      const body = {
        coupon_id: selectedCoupon._id
      }

      const { ok, res, error } = await deleteCouponService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Cupón eliminado correctamente')

      const isPrivate = selectedCoupon.coupon_type === 'private'
      dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const createCoupon = (body, isPrivate = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createCouponService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Cupón creado correctamente')

      dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCoupon = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const selectedCoupon = getState().coupons.selectedCoupon

      const { ok, res, error } = await updateCouponService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Cupón editado correctamente')

      dispatch(setSelectedCoupons(res.updatedCoupon))

      const isPrivate = selectedCoupon.coupon_type === 'private'
      dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCouponStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const selectedCoupon = getState().coupons.selectedCoupon

      const payload = {
        coupon_id: selectedCoupon._id,
        name: selectedCoupon.name,
        status: selectedCoupon.status === 'active' ? 'inactive' : 'active'
      }

      const body = new FormData()

      for (const key in payload) {
        body.append(key, payload[key])
      }

      const { ok, res, error } = await updateCouponService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Cupón editado correctamente')

      dispatch(setSelectedCoupons(res.updatedCoupon))

      const isPrivate = selectedCoupon.coupon_type === 'private'
      dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const searchUser = (body) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await searchUserService(body)
      dispatch(setLoadingUsers(true))

      const usersOptions = res.users.map((user) => ({
        label: `${user.username}`,
        value: user._id,
        email: user.email
      }))

      dispatch(setCouponUsers(usersOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoadingUsers(false))
    }
  }
}

export const getCouponDetails = (id) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await getCouponDetailsService(id)

      if (!ok) throw error

      dispatch(setSelectedCoupons(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}
