import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setAccountPosition,
  setData,
  setAccountStatus,
  setAccountStatusOptions
} from './slice'
import { getBillingHistoryService } from '../../../services/wallet/history'
import {
  accountStatusService,
  amountHistoryService,
  bankTransactionService
} from '../../../services/wallet/consolidate-position'

export const getAccountStatusService = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().billingHistory.filters
      const { ok, res, error } = await accountStatusService(filters)

      if (!ok) throw error

      dispatch(setAccountStatus(res?.data?.bank_amounts ?? []))

      const accountStatusOptions = res?.data?.bank_amounts.map((item) => ({
        value: item?.account_number,
        label: item?.name
      }))
      dispatch(setAccountStatusOptions(accountStatusOptions))
    } catch (err) {
      toast.error(
        err?.description ?? 'Error al buscar los datos de las cuentas bancarias'
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAmountHistoryService = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().billingHistory.filters
      const { ok, res, error } = await amountHistoryService(filters)

      if (!ok) throw error
      dispatch(setData(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getTodayBankTransactions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().billingHistory.filters
      const { ok, res, error } = await bankTransactionService(filters)

      if (!ok) throw error

      dispatch(setAccountPosition(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
