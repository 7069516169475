import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setSelectedExtensionList,
  setSchoolOptions,
  setSelectedUserExtension,
  setSelectedEExtension
} from './slice'
import {
  createExtensionService,
  deleteCustomerExtensionService,
  getSchoolsOptionsServices,
  updateExtensionService
} from '../../../services/users/extensions/extensions'
import { searchUserService } from '../../../services/parking-lot/coupons'
import {
  setCustomersList,
  setSelectedCustomer,
  setSelectedExtension
} from '../../customers/slice'
import { getUserByDocumentService } from '../../../services/parking-lot/tickets'
import { isEmptyObject } from 'jquery'
import { setSelectedUser } from '../pay-event'
import { addCustomerWithoutPasswordService, rechargeWalletService } from '../../../services/customers'


export const getUserByDocument = (document) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getUserByDocumentService(document)
      if (!ok) throw error

      const username = res?.data

      if (isEmptyObject(username) || !username) {
        toast.error('Usuario no encontrado')
        throw new Error('Usuario no encontrado')
      }

      dispatch(setSelectedUserExtension(username))
      dispatch(setSelectedUser(username))

      toast.success('Usuario encontrado')

      return res
    } catch (error) {
      throw error
    }
  }
}

export const addNewCustomerWithoutPassword = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const docNumber = body?.doc?.slice(1) ?? ''

      const { ok, res, error } = await addCustomerWithoutPasswordService(body)

      if (!ok) throw error
      dispatch(getUserByDocument(docNumber))
    } catch (err) {
      toast.error(err?.extra.message ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}


export const rechargeWalletExt = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedRecharge } = getState().customers
      const { selectedUserExtension } = getState().extensions

      const userId = selectedUserExtension?._id ?? ''
      const username = selectedUserExtension?.username ?? ''
      const docNumber =
        selectedUserExtension?.profile?.identification?.doc_id ?? ''

      const amount = selectedRecharge.amount
        .replace(/\./g, '')
        .replace(/,/g, '.')

      const sendBody = {
        ...selectedRecharge,
        amount,
        username,
        customer: userId
      }

      const { ok, res, error } = await rechargeWalletService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Recarga exitosa')
      dispatch(getUserByDocument(docNumber))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setSelectedRecharge(null))
    }
  }
}

export const getSchoolsOptions = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await getSchoolsOptionsServices()
      if (!ok) throw error

      console.log(res)

      const schoolsOptions = res.commerces.map((school) => ({
        label: school?.school_name || 'Sin nombre',
        value: school?._id
      }))

      dispatch(setSchoolOptions(schoolsOptions))
    } catch (err) {
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateExtensionStatus = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedCustomer, selectedExtension } = getState().customers

      const sendBody = {
        user_id: selectedCustomer._id,
        extension_id: selectedExtension._id,
        status: selectedExtension.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = await updateExtensionService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensión actualizada correctamente')

      const updatedSelectedExtension = res.children.find(
        (extension) => extension._id === selectedExtension._id
      )

      dispatch(setSelectedExtension(updatedSelectedExtension))
      dispatch(setSelectedExtensionList(res.children))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateExtension = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await updateExtensionService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensión actualizada correctamente')

      const selectedExtension = getState().customers.selectedExtension

      const updatedSelectedExtension = res.children.find(
        (extension) => extension._id === selectedExtension._id
      )

      dispatch(setSelectedExtension(updatedSelectedExtension))
      dispatch(setSelectedExtensionList(res.children))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const createExtension = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createExtensionService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensión creada correctamente')

      const {
        ok: okUser,
        res: response,
        error: errors
      } = await searchUserService(body.username)

      const selectedExtension = res.children.find(
        (extension) => extension.bracelet_id === body.bracelet_id
      )

      dispatch(setCustomersList(response.users))
      dispatch(setSelectedUserExtension(response.users[0]))
      dispatch(setSelectedExtensionList(res.children))
      dispatch(setSelectedEExtension(selectedExtension))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteCustomerExtension = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedExtension } = getState().customers
      const { selectedUserExtension } = getState().extensions
      dispatch(setLoading(true))

      const body = {
        user_id: selectedUserExtension._id,
        extension_id: selectedExtension._id
      }

      const { ok, res, error } = await deleteCustomerExtensionService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Extensión eliminada exitosamente')

      dispatch(setSelectedExtensionList(res.children))
      dispatch(setSelectedExtension(null))
    } catch (err) {
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const enableCustomerExtension = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedExtension, selectedCustomer } = getState().customers
      dispatch(setLoading(true))

      const body = {
        user_id: selectedCustomer._id,
        extension_id: selectedExtension._id,
        is_pin_enable: !selectedExtension.is_pin_enable
      }

      const { ok, res, error } = await updateExtensionService(body)
      if (!ok) throw error

      toast.success(res.message ?? 'Extensión editada exitosamente')

      console.log(res)
      const updatedSelectedExtension = res.children.find(
        (extension) => extension._id === selectedExtension._id
      )

      dispatch(setSelectedExtension(updatedSelectedExtension))
      dispatch(setSelectedExtensionList(res.children))
    } catch (err) {
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getExtensions = (isPrivate = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().extensions.filters

      const { ok, res, error } = await getAdvertisingList(filters)

      if (!ok) throw error

      // dispatch(setExtensions(res.data))
      // dispatch(setNextPageExists(false))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
