export const defaultFilter = {
  username: '',
  type: '',
  status: [],
  page: 1,
  skip: 0,
  limit: 10
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar extensión' },
  approved: {
    action: 'approved',
    message: 'Esta seguro de aprobar la transacción'
  },
  rejected: {
    action: 'rejected',
    message: 'Esta seguro de rechazar la transacción'
  },
  deferred: {
    action: 'deferred',
    message: 'Esta seguro de diferir la transacción'
  },

  enableTDC: { action: 'enableTDC', message: 'Habilitar TDC' },

  mobile_payment: { action: 'mobile_payment', message: 'Esta seguro de aprobar la transacción' },
  bank_transfer: { action: 'bank_transfer', message: 'Esta seguro de aprobar la transacción' },
  superadmin: { action: 'superadmin', message: 'Esta seguro de aprobar la transacción' },
  credicard: { action: 'credicard', message: 'Esta seguro de aprobar la transacción' },
  TDC: { action: 'TDC', message: 'Esta seguro de aprobar la transacción' }
}

const enableRechargeOptions = {
  approved: { label: 'Aprobado', color: 'light-success' },
  pending: { label: 'Pendiente', color: 'light-warning' },
  processing: { label: 'En proceso', color: 'light-danger' },
  rejected: { label: 'Rechazado', color: 'light-danger' }
}

const initialState = {
  loading: false,
  data: [],
  selectedEnableRecharge: null,

  filters: defaultFilter,
  enableRechargeOptions,
  nextPageExists: false,
  actions: defaultActions
}

export default initialState
