export const defaultFilter = {
  period: 'daily',
  start_date: null,
  end_date: null
}

const initialState = {
  loading: false,
  data: [],
  filters: defaultFilter,
  comparativeData: {
    yAxisCurrent: [],
    yAxisPrevious: [],
    xAxis: []
  },
  comparativeTotal: {
    current: {},
    previous: {}
  },

  temporalDistribution: {
    yAxis: [],
    xAxis: []
  },
  buildingDistribution: {
    xAxisCurrentBuilding: [],
    yAxisCurrentBuilding: [],
    xAxisPreviousBuilding: [],
    yAxisPreviousBuilding: []
  },

  ticketsHistory: [],
  todayTickets: {
    currentQty: '',
    previousQty: '',
    variation: ''
  },
  usersType: {}
}

export default initialState
