import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setTransactions,
  setTransactionTagsOptions,
  setTransactionsInsurance,
  setMetrics
} from './slice'

import { getTransactionsConstantsService } from '../../../services/users/transactions'
import { t } from 'i18next'
import {
  getInsuranceMetricsServices,
  getInsuranceTransactionsServices
} from '../../../services/insurance/transactions'

export const getInsuranceTransactions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { filters } = getState().transactionsInsurance

      const { ok, res, error } = await getInsuranceTransactionsServices(filters)

      if (!ok) throw error

      dispatch(setTransactionsInsurance(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getInsuranceMetrics = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { filters } = getState().transactionsInsurance

      const { ok, res, error } = await getInsuranceMetricsServices(filters)

      if (!ok) throw error
      dispatch(setMetrics(res?.data))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
