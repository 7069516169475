import { lazy } from 'react'

const DashboardSuperadmin = lazy(() => import('@views/superadmin/dashboard'))

const Tolls = lazy(() => import('@views/tolls/tolls'))

const AdminBundle = lazy(() => import('@views/tolls/adminBundle'))

const AdminAccess = lazy(() => import('@views/tolls/access'))

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const SuperadminRoutes = [
  // dashboards
  {
    path: '/superadmin/dashboard',
    element: <DashboardSuperadmin />,
    meta: {
      actions: ['pagodirecto-level-2'],
      resources: ['superadmin']
    }
  },
  {
    path: '/tolls/tolls',
    element: <Tolls />,
    meta: {
      actions: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resources: ['Common']
    }
  },
  {
    path: '/tolls/bundles/admin',
    element: <AdminBundle />,
    meta: {
      actions: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resources: ['Common']
    }
  },
  {
    path: '/tolls/access',
    element: <AdminAccess />,
    meta: {
      actions: [ability.MANAGER, ability.SYSTEM, ability.SATC],
      resources: ['Common']
    }
  }
]

export default SuperadminRoutes
