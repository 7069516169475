import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  associateCompanyPenaltyToAreaService,
  associateCompanyToAreaService,
  associateTowCompanyPenaltyToAreaService,
  createArea,
  deleteAreaService,
  getAreasList,
  updateAreaService
} from '../../../services/parking-meters'
import {
  setAreas,
  setAreasOptions,
  setLoading,
  setNextPageExists,
  setSelectedArea
} from './'

export const getAreas = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().areas.filters

      const { ok, res, error } = await getAreasList(filters)

      if (!ok) throw error

      dispatch(setAreas(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAreasOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().areas.filters
      const { pagination, ...filtersWithOutPagination } = filters

      const { ok, res, error } = await getAreasList(filtersWithOutPagination)

      if (!ok) throw error

      const areasOptions = res.data.items.map((area) => ({
        value: area._id,
        label: area.display_name,
        entity_commerce_id: area.entity_commerce._id
      }))

      dispatch(setAreasOptions(areasOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addArea = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createArea(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Área creada correctamente')

      dispatch(getAreas())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateArea = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedArea = getState().areas.selectedArea

      const sendBody = {
        ...body,
        _id: selectedArea._id
      }

      const { ok, res, error } = await updateAreaService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Área actualizada correctamente')

      dispatch(getAreas())
      dispatch(setSelectedArea(res.data))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateAreaStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedArea = getState().areas.selectedArea

      const sendBody = {
        ...selectedArea,
        active: !selectedArea.active
      }

      const { ok, res, error } = await updateAreaService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Área actualizada correctamente')

      dispatch(getAreas())
      dispatch(setSelectedArea(res.data))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteArea = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedArea = getState().areas.selectedArea
      const areaId = selectedArea._id

      const { ok, res, error } = await deleteAreaService(areaId)

      if (!ok) throw error
      toast.success(res.message ?? 'Área eliminada correctamente')

      dispatch(getAreas())
      dispatch(setSelectedArea(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const associatedCompanyToArea = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const sendBody = {
        area_id: body?.subzone_id,
        allow_subzone_distribution: body?.allow_subzone_distribution,
        profit_distribution: body?.profit_distribution
      }

      const { ok, res, error } = await associateCompanyToAreaService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía asociada correctamente')

      dispatch(setSelectedArea(res.data))
      dispatch(getAreas())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const associatedCompanyPenaltyToArea = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const sendBody = {
        area_id: body?.subzone_id,
        allow_penalty_subzone_distribution: body?.allow_subzone_distribution,
        penalty_profit_distribution: body?.profit_distribution
      }

      const { ok, res, error } = await associateCompanyPenaltyToAreaService(
        sendBody
      )

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía asociada correctamente')

      dispatch(setSelectedArea(res.data))
      dispatch(getAreas())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const associatedTowCompanyPenaltyToArea = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const sendBody = {
        area_id: body?.subzone_id,
        tow_penalty_profit_distribution: body?.profit_distribution
      }

      const { ok, res, error } = await associateTowCompanyPenaltyToAreaService(
        sendBody
      )

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía asociada correctamente')

      dispatch(setSelectedArea(res.data))
      dispatch(getAreas())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
