import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import { setNextPageExists, setLoading, setBillingHistory } from './slice'
import { getBillingHistoryService } from '../../../services/wallet/history'

export const getBillingHistory = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().billingHistory.filters
      const { ok, res, error } = await getBillingHistoryService(filters)

      if (!ok) throw error

      const modifiedBills = res.bills.map((bill) => ({
        ...bill,
        _id: bill.bill_id,
        billing_id: undefined // Optional: remove the billing_id property
      }))

      dispatch(setBillingHistory(modifiedBills))
      dispatch(setNextPageExists(true))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
