import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload
    },
    setSelectedExtension: (state, action) => {
      state.selectedExtension = action.payload
    },
    setSelectedVehicle: (state, action) => {
      state.selectedVehicle = action.payload
    },
    setSelectedVehicleList: (state, action) => {
      state.selectedVehicleList = action.payload
    },
    setCustomersList: (state, action) => {
      state.customersList = action.payload
    },
    setAutocomplete: (state, action) => {
      state.autocomplete = action.payload
    },
    setSelectedRecharge: (state, action) => {
      state.selectedRecharge = action.payload
    }
  }
})

export const {
  setLoading,
  setSelectedCustomer,
  setCustomersList,
  setAutocomplete,
  setSelectedExtension,
  setSelectedVehicle,
  setSelectedVehicleList,
  setSelectedRecharge
} = slice.actions

export const customers = slice.reducer
