import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setCouponUsers,
  setSelectedAdvertising,
  setLoadingUsers,
  setAdvertising,
  setServicesOptions,
  setAdvertisingOptions
} from './slice'
import {
  createAdvertisingService,
  deleteAdvertisingService,
  getAdvertisingList,
  getServices,
  updateAdvertisingService
} from '../../../services/users/advertising/advertising'

export const loadServicesOptions = () => {
  return async (dispatch) => {
    try {
      // const { ok, res, error } = await getServices()
      // if (!ok) throw error
      // const servicesOptions = res.service_information
      //   .filter((service) => service.active)
      //   .map((service) => ({
      //     label: `${service.name}`,
      //     value: service.name
      //   }))
      // dispatch(setServicesOptions(servicesOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getAdvertising = (isPrivate = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().advertising.filters

      const { ok, res, error } = await getAdvertisingList(filters)

      if (!ok) throw error

      dispatch(setAdvertising(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAllAdvertising = (isPrivate = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().advertising.filters
      const { pagination, ...rest } = filters

      const { ok, res, error } = await getAdvertisingList(rest)

      const advertisingOptions = res.data.map((item) => ({
        label: item?.campaign_name,
        value: item?._id
      }))

      if (!ok) throw error

      dispatch(setAdvertisingOptions(advertisingOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteAdvertising = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedAdvertising = getState().advertising.selectedAdvertising
      const { ok, res, error } = await deleteAdvertisingService(
        selectedAdvertising._id
      )

      if (!ok) throw error
      toast.success(res.message ?? 'Publicidad eliminado correctamente')

      dispatch(getAdvertising())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const createAdvertising = (body, isPrivate = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createAdvertisingService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Publicidad creada correctamente')

      dispatch(getAdvertising())

      // dispatch(getAdvertising(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateAdvertising = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await updateAdvertisingService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Publicidad editado correctamente')

      dispatch(setSelectedAdvertising(res.data))
      dispatch(getAdvertising())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateAdvertisingStatus = () => {
  return async (dispatch, getState) => {
    try {
      const selectedAdvertising = getState().advertising.selectedAdvertising

      const payload = {
        id: selectedAdvertising._id,
        status: selectedAdvertising.status === 'active' ? 'inactive' : 'active'
      }

      const body = new FormData()

      for (const key in payload) {
        body.append(key, payload[key])
      }

      dispatch(setLoading(true))
      const { ok, res, error } = await updateAdvertisingService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Estado editado correctamente')

      dispatch(getAdvertising())
      dispatch(setSelectedAdvertising(res.data))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
