// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const tollsSlices = createSlice({
  name: 'tolls',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setTolls: (state, action) => {
      state.tolls = action.payload
    },
    setTollsList: (state, action) => {
      state.tollsList = action.payload
    },

    setConcessionsList: (state, action) => {
      state.concessionsList = action.payload
    },

    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },

    setVehiclesTypes: (state, action) => {
      state.vehiclesTypes = action.payload
    },
    setIsSuperadmin: (state, action) => {
      state.isSuperadmin = action.payload
    },

    setTollRates: (state, action) => {
      state.tollRates = action.payload
    },

    setSelectedToll: (state, action) => {
      state.selectedToll = action.payload
    },
    setAccessList: (state, action) => {
      state.accessList = action.payload
    },

    setSelectedAccess: (state, action) => {
      state.selectedAccess = action.payload
    },
    setStateOptions: (state, action) => {
      state.stateOptions = action.payload
    }
  }
})

export const {
  setLoading,
  setFilters,
  setNextPageExists,
  setVehiclesTypes,
  setIsSuperadmin,
  setTolls,
  setTollsList,
  setConcessionsList,
  setTollRates,
  setSelectedToll,
  setStateOptions,
  setAccessList,  
  setSelectedAccess
} = tollsSlices.actions

export const tolls = tollsSlices.reducer
