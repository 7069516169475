// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const defaultFilter = {
  pagination: {
    page: 1,
    limit: 11
  },
  filterOptions: {
    areas_ids: [],
    zones_ids: [],
    subzones_ids: [],
    entity_commerces_ids: []
  }
}

const initialState = {
  data: [],
  loading: true,
  nextPageExists: false,
  filters: defaultFilter,
  commercesOptions: [],
  areaOptions: [],
  zonesOptions: [],
  subzonesOptions: [],
  selectedTicket: {},
  entity_commerces_ids: []
}

export const ticketSlice = createSlice({
  name: 'ticketReducer',
  initialState,
  reducers: {
    setCommerce: (state, action) => {
      state.entity_commerces_ids = action.payload
    },
    setCommerceOptions: (state, action) => {
      state.commercesOptions = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedTicket: (state, action) => {
      state.selectedTicket = action.payload
    },
    setAreas: (state, action) => {
      state.areaOptions = action.payload
    },
    setZones: (state, action) => {
      state.zonesOptions = action.payload
    },
    setSubzones: (state, action) => {
      state.subzonesOptions = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setFilters,
  setAreas,
  setZones,
  setSubzones,
  setLoading,
  setData,
  setSelectedTicket,
  setNextPageExists,
  setCommerce,
  setCommerceOptions
} = ticketSlice.actions

export const ticketReducer = ticketSlice.reducer
