import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setEntity: (state, action) => {
      state.data = action.payload
    },
    setEntityFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedEntity: (state, action) => {
      state.selectedEntity = action.payload
    },
    setEntitiesOptions: (state, action) => {
      state.entitiesOptions = action.payload
    }
  }
})

export const {
  setEntityFilters,
  setLoading,
  setEntity,
  setNextPageExists,
  setSelectedEntity,
  setEntitiesOptions
} = slice.actions

export const entities = slice.reducer
