import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL } from '@configs/apis'

const endpoints = {
  getCouponsList: `${couponsURL}/superadmin/coupon/list`,
  getCouponsUsesHistoryList: `${couponsURL}/superadmin/coupon/redeemedCouponHistory`,
  createCoupon: `${couponsURL}/superadmin/coupon/create`,
  deleteCoupon: `${couponsURL}/superadmin/coupon/remove`,
  updateCoupon: `${couponsURL}/superadmin/coupon/edit`,
  searchUser: `${baseV2URL}/users/autocomplete`,
  getCouponDetails: `${couponsURL}/superadmin/coupon/get`
}

export const getCouponsList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getCouponsList,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getCouponDetailsService = async (id) => {
  try {
    const response = await apiMethods.authGetFetch(
      `${endpoints.getCouponDetails}/${id}`
    )

    return response
  } catch (error) {
    return error
  }
}

export const getCouponsUsesHistoryList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getCouponsUsesHistoryList,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createCouponService = async (body) => {
  try {
    const response = await apiMethods.authPostFetchFormData({
      url: endpoints.createCoupon,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateCouponService = async (body) => {
  try {
    const response = await apiMethods.authPutFetchFormData({
      url: endpoints.updateCoupon,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteCouponService = async (body) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: endpoints.deleteCoupon,
      body
    })

    return response
  } catch (error) {
    return error
  }
}

export const searchUserService = async (body) => {
  try {
    const response = await apiMethods.authGetFetch(
      `${endpoints.searchUser}?input=${body}&show=0&page=100`
    )
    return response
  } catch (error) {
    return error
  }
}
