export const initialState = {
  loading: false,
  params: {
    reportValue: '',
    file: null,
    fromDate: '',
    endDate: '',
    transaction_tag: '',
    schoolId: '',
    buildingCode: ''
  }
}
