const defaultValues = {
  subzone_code: '',
  display_name: '',
  short_name: '',
  address: '',
  zone_id: '',
  allowed_start_time: '',
  allowed_end_time: '',
  billing_type: '',
  subzone_type: '',
  latitude: '',
  longitude: '',
  max_spots: '',

  time_step: '',
  max_time: '',

  rate_per_day: '',

  standard_penalty_usd: '',
  tow_penalty_usd: '',

  max_minutes: '',
  usd_rate: '',
  penalty_factor: '',
  tow_penalty_factor: '',
  grace_minutes: '',

  active: true
}

export const defaultFilter = {
  date_range: {
    fromDate: null,
    untilDate: null
  },
  filterOptions: {
    // campaign_name: ''
  },
  pagination: {
    page: 1,
    limit: 10
  }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar subzona' },
  update: { action: 'update', message: 'Actualizar subzona' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  delete: { action: 'deleted', message: 'eliminar' },
  updateProfitDistribution: {
    action: 'updateProfitDistribution',
    message: 'Actualizar'
  },
  penaltyProfitDistribution: {
    action: 'penaltyProfitDistribution',
    message: 'Actualizar distribución de penalidades'
  },
  towProfitDistribution: {
    action: 'towProfitDistribution',
    message: 'Actualizar distribución de tow'
  },
  updateTowProfitDistribution: {
    action: 'updateTowProfitDistribution',
    message: 'Actualizar'
  },
  updatePenaltyProfitDistribution: {
    action: 'penaltyProfitDistributionStatus',
    message: 'Actualizar'
  },
  updateRates: {
    action: 'updateRates',
    message: 'Actualizar configuración de pago'
  },
  associatedCompany: {
    action: 'updateAssociatedCompany',
    message: 'Actualizar empresa asociada'
  },
  updateHours: {
    action: 'updateHours',
    message: 'Actualizar horario de operación'
  }
}

const defaultSubzoneTypeOptions = [
  { value: 'residential', label: 'Residencial' },
  { value: 'commercial', label: 'Comercial' }
]

const defaultSubzoneBillingType = [
  { value: 'fixed-rate', label: 'Tarifa plana' },
  { value: 'hourly', label: 'Tarifa por hora' }
]

const handleErrors = {
  grace_minutes: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El valor mínimo es 0'
    }
  },
  code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    maxLength: {
      value: 5,
      message: 'Debe tener una longitud de 5'
    },
    minLength: {
      value: 5,
      message: 'Debe tener una longitud de 5'
    }
  },
  subzone_code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  short_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  display_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  zone_id: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  address: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  allowed_start_time: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  allowed_end_time: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  subzone_type: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  billing_type: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  max_spots: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 1,
      message: 'El valor mínimo es 1'
    }
  },
  standard_penalty_usd: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El valor mínimo es 0'
    }
  },
  tow_penalty_usd: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El valor mínimo es 0'
    }
  }
}

const initialState = {
  loading: false,
  loadingUsers: false,

  data: [],
  subzoneOptions: [],

  filters: defaultFilter,

  selectedSubzone: null,

  nextPageExists: false,
  actions: defaultActions,

  handleErrors,

  subzoneTypeOptions: defaultSubzoneTypeOptions,
  subzoneBillingType: defaultSubzoneBillingType,

  defaultValues
}

export default initialState
