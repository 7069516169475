import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const bundlesAdminSlice = createSlice({
  name: 'bundlesAdmin',
  initialState,
  reducers: {
    handleModalBundle: (state, action) => {
      state.isModalOpenn = action.payload
    },
    setConcessionOptions: (state, action) => {
      state.concessionOptions = action.payload
    },
    setBundles: (state, action) => {
      state.data = action.payload
    },
    setTypeOptions: (state, action) => {
      state.typeOptions = action.payload
    },
    setSelectedBundle: (state, action) => {
      state.selectedBundle = action.payload
    },

    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setIsAddBundleOpen: (state, action) => {
      state.isAddBundleOpen = action.payload
    }
  },
  extraReducers: (builder) => {}
})

export const {
  handleModalBundle,
  setConcessionOptions,
  setBundles,
  setTypeOptions,
  setSelectedBundle,
  setNextPageExists,
  setFilters,
  setIsAddBundleOpen
} = bundlesAdminSlice.actions

export const bundlesAdmin = bundlesAdminSlice.reducer
