import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import {
  setCommercesAssociated,
  setMovementsList,
  setLoadingList,
  setLoadingWithdraw,
  setNextPageExists,
  defaultFilter
} from '@store/wallet'

import { commercesAssociatedService, walletUtils } from '@services/wallet'
import { setBankAccounts, setChildrenBill, setTotalAmount } from './slice'
import { getChildrenBillsService } from '../../services/wallet'
import { getCommerceData } from '../../utility/Utils'

export const getChildrenBills = () => {
  return async (dispatch, getState) => {
    const { formFilters } = getState().wallet

    const { ok, res, error } = await getChildrenBillsService(formFilters)

    dispatch(setTotalAmount(res?.data?.pendingWithdrawAmount))
    dispatch(setChildrenBill(res?.data?.withdrawBills))
    dispatch(setNextPageExists(res?.data?.nextPageExist))
  }
}

export const getCommercesAssociated = () => {
  return async (dispatch) => {
    dispatch(setLoadingList(true))
    const commerce = await commercesAssociatedService()
    dispatch(setCommercesAssociated(commerce))
    dispatch(setLoadingList(false))
  }
}

export const getTransactionList = () => {
  return async (dispatch, getState) => {
    dispatch(setLoadingList(true))

    try {
      const { category, type } = getCommerceData()
      // TODO: CAMBIAR
      if (category === 'tecnologia') {
        return
      }

      const request = walletUtils.getTransactionsListService()
      const { res, ok } = await request(getState().wallet.formFilters)
      dispatch(setLoadingList(false))

      if (ok) {
        const nextPageExists =
          res?.data?.transactions?.nextPageExists ||
          res?.data?.transactions?.items?.length >
            defaultFilter.pagination.limit
        if (res?.data?.amounts && res?.data?.amounts?.length > 0) {
          const amounts = res.data.amounts
          const amountObject = amounts?.find(
            (amount) => amount.currency === 'BS'
          )
          const amount = amountObject?.funds ? amountObject?.funds : 0
          dispatch(setTotalAmount(amount))
        }
        dispatch(setMovementsList(res.data.transactions.items))

        if (res.data.bankAccounts)
          dispatch(setBankAccounts(res.data.bankAccounts))
        if (res.data.balance) dispatch(setTotalAmount(res.data.balance))
        dispatch(setNextPageExists(nextPageExists))
      }
    } catch (error) {
    } finally {
      dispatch(setLoadingList(false))
    }
  }
}

export const dispatchWithdraw = () => {
  return async (dispatch, getState) => {
    // dispatch(setLoadingWithdraw(true))
    const { fn: request, category } = walletUtils.getWithdrawService()
    const commerce_id = getState().auth.operator.commerce_associated._id
    const res = await request({
      ...getState().wallet.withdrawObject,
      commerce_id
    })

    const { ok } = res
    const message = ok
      ? 'Retiro exitoso de fondos'
      : res?.error?.message ??
        res?.message ??
        'Ocurrió un error al realizar el retiro'

    const type = ok ? 'success' : 'danger'
    // dispatch(setLoadingWithdraw(false))

    const dispatchByCategory = {
      peaje: getCommercesAssociated,
      parquimetro: getTransactionList
    }
    const nextStep = dispatchByCategory[category] || getTransactionList
    dispatch(nextStep())
    toast((t) => <BasicToastContent t={t} message={message} type={type} />)
  }
}
