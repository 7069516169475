import {
  setLoading,
  setLoadingTickets,
  setCommerces,
  setSelectedCommerce
} from '@store/parking-lot/commerces'
import {
  plCommercesAutocomplete,
  plCommercesByDate,
  plCommercesTickets,
  groupByDate
} from '@services/parking-lot/commerces'

import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

export const getCommerces = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { searchParam } = getState().plCommerces
    const { selectedCommerce } = getState().commerces
    const building = selectedCommerce?.parking?.building?._id
    const { ok, res, error } = await plCommercesAutocomplete({
      searchParam,
      building
    })

    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.description ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
      dispatch(setLoading(false))
      return
    }
    const commercesGroupedByDate = groupByDate(
      res.tickets_digitized_by_commerce
    )
    dispatch(setCommerces(commercesGroupedByDate))

    dispatch(setLoading(false))
  }
}

export const getCommercesByDate = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { dateRange, pagination } = getState().plCommerces
    const { selectedCommerce } = getState().commerces
    const building = selectedCommerce?.parking?.building?._id
    const { ok, res, error } = await plCommercesByDate({
      building,
      ...dateRange,
      ...pagination
    })
    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.description ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
      dispatch(setLoading(false))
      return
    }

    const commercesGroupedByDate = groupByDate(
      res.tickets_digitized_by_commerce
    )
    dispatch(setCommerces(commercesGroupedByDate))
    dispatch(setLoading(false))
  }
}

export const getCommercesTickets = (commerce, date) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingTickets(true))
    const selectedCommerce = getState().plCommerces.selectedCommerce
    const { ok, res, error } = await plCommercesTickets({
      commerce,
      date
    })
    dispatch(setLoadingTickets(false))
    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error?.description ?? errors.GENERAL_ERR}
          type={'danger'}
        />
      ))
      dispatch(
        setSelectedCommerce({
          ...selectedCommerce,
          ticketList: []
        })
      )
      return
    }
    dispatch(
      setSelectedCommerce({
        ...selectedCommerce,
        ticketList: [...res.tickets]
      })
    )
  }
}
