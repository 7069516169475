import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'transactionsInsurance',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTransactionsInsurance: (state, action) => {
      state.data = action.payload
    },
    setTransactionsInsuranceFilters: (state, action) => {
      state.filters = action.payload
    },
    setSelectedInsuranceTransaction: (state, action) => {
      state.selectedTransaction = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setMetrics: (state, action) => {
      state.metrics = action.payload
    }
  }
})

export const {
  setLoading,
  setTransactionsInsurance,
  setTransactionsInsuranceFilters,
  setNextPageExists,
  setSelectedInsuranceTransaction,
  setMetrics
} = slice.actions

export const transactionsInsurance = slice.reducer
