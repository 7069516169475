export const defaultFilter = {
  limit: 10,
  skip: 1,
  input: ''
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  completed: { label: 'Completado', color: 'light-primary' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar nueva factura' },
  update: { action: 'update', message: 'Actualizar datos del cupon' },
  delete: {
    action: 'deleted',
    message: '¿Esta seguro de eliminar el elemento?'
  },
  message: {
    action: 'message',
    message:
      'Unidigital tarda de 3 a 5 min en generar un número de control después de generar la factura, por favor sé paciente'
  },
  updateStatus: { action: 'updateStatus', message: 'actualizar' }
}

const defaultValues = {
  doc_id: '',
  name: '',
  alias: '',
  email: '',
  phone: '',
  address: '',
  currency: { value: 'VES', label: 'VES' },
  save_billing_fiscal_info: false
}

const handleErrors = {
  doc_id: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  address: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    validate: (value) => value.includes('@') && value.includes('.')
  },
  alias: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  phone: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const couponsOptions = [
  { value: 'public', label: 'Publico' },
  { value: 'private', label: 'Privado' },
  { value: 'referred', label: 'Referido' },
  { value: 'referrer', label: 'Referente' }
]

const step2defaultValues = {
  qty_l1: '',
  code_l1: '',
  amount_usd_l1: '',
  amount_bs_l1: '',
  description_l1: 'Recaudación en cuenta de terceros: Estacionamientos',
  is_exempt_l1: false,
  qty_l2: '',
  code_l2: '',
  amount_usd_l2: '',
  amount_bs_l2: '',
  description_l2: 'Servicio PagoDirecto',
  is_exempt_l2: false
}

const step2handleErrors = {

}

const initialState = {
  loading: false,

  data: [],
  selectedBilling: null,

  currentBillingForm: null,
  previewBilling: null,

  handleErrors,
  step2handleErrors,
  defaultValues,
  step2defaultValues,

  couponsOptions,

  filters: defaultFilter,

  nextPageExists: false,
  currentExchangeRate: '',
  actions: defaultActions
}

export default initialState
