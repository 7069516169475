import { apiMethods } from '@services/methods'
import { baseV2URL } from '@configs/apis'
import { authPatchFetchFormData } from '../../methods/methods'

const urlBase = `${baseV2URL}/commerces/pending-transaction`
const urlBaseLean = `${baseV2URL}/commerces/pending-transaction-lean`

const endpoints = {
  list: `${urlBaseLean}/list`,
  byFile: `${urlBase}/file`
}

const defaultPagination = {
  page: 1,
  limit: 10
}

const defaultStatus = ['pending', 'paid']

export const listDigitalAccountsService = async ({ page, limit, status }) => {
  try {
    const statusArray = status && status.length > 0 ? status : defaultStatus
    return await apiMethods.authPostFetch({
      url: endpoints.list,
      body: {
        page: page ? page : defaultPagination.page,
        limit: limit ? limit : defaultPagination.limit,
        status: statusArray
      }
    })
  } catch (error) {
    throw error
  }
}

export const createDigitalAccountService = async ({
  amount,
  concept,
  currency,
  transactionTag,
  input,
  control,
  type
}) => {
  try {
    if (
      !amount ||
      !concept ||
      !currency ||
      !transactionTag ||
      !type ||
      !control ||
      !input
    ) {
      throw new Error('Faltan campos que son obligatorios')
    }
    if (typeof amount !== 'number') {
      throw new Error('El monto debe ser un número')
    }
    if (currency !== 'USD' && currency !== 'VES') {
      throw new Error('Tipo de moneda no admitido')
    }

    const body = {
      amount,
      concept,
      currency,
      transaction_tag: transactionTag,
      type,
      control
    }
    if (type === 'customer') {
      body['input'] = input
    } else {
      body['email'] = input
    }
    return await apiMethods.authPostFetch({
      url: urlBase,
      body
    })
  } catch (error) {
    throw error
  }
}

export const createDigitalAccountByFileService = async (file) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    return await apiMethods.authPatchFetchFormData({
      url: endpoints.byFile,
      formData
    })
  } catch (error) {
    throw error
  }
}

export const findDigitalAccountService = async (id) => {
  try {
    if (!id) {
      throw new Error('Falta el id de la cuenta digital')
    }
    return await apiMethods.authGetFetch({
      url: `${urlBaseLean}/${id}`
    })
  } catch (error) {
    return { error, ok: false, res: null }
  }
}
