export const defaultFilter = {
  date_from: null,
  date_to: null,
  transaction_type: 'wallet',
  reference: ''
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  completed: { label: 'Completado', color: 'light-primary' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar cupon' },
  update: { action: 'update', message: 'Actualizar datos del cupon' },
  delete: { action: 'deleted', message: 'eliminar' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' }
}

const defaultValues = {
  concept: '',
  amount: '',
  bank: { value: 'wallet', label: 'De Wallet a Paytech' },
  code: '',
  user: ''
}

const handleErrors = {
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  value: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },
  maximum_uses: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },

  vertical_type: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  new_user: {
    validate: (value) => value !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  service_names: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  expiration_date: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const couponsOptions = [
  { value: 'public', label: 'Publico' },
  { value: 'private', label: 'Privado' },
  { value: 'referred', label: 'Referido' },
  { value: 'referrer', label: 'Referente' }
]

const bankTransfersOptions = [
  { value: 'wallet', label: 'Wallet' },
  { value: 'service', label: 'Servicio' },
  { value: 'third-bancamiga', label: 'Terceros Bancamiga' },
  { value: 'third-other', label: 'Terceros otros bancos' }
]

const initialState = {
  loading: false,

  data: [],
  selectedBankTransfer: null,
  selectedBankAccount: null,
  transferBody: null,

  bankTransfersOptions,

  handleErrors,
  defaultValues,

  couponsOptions,

  filters: defaultFilter,

  nextPageExists: false,
  actions: defaultActions
}

export default initialState
