const deafultFilter = {
  commerces: [],
  sortOrder: 'desc',
  page: 1,
  limit: 8,
  sortBy: 'created_at',
  fromDate: '',
  untilDate: '',
  mixed_autocomplete: '',
  type: []
  
}

const filterByOptions = [
  {
    value: 'concessions',
    label: 'Concesión',
    color: '#00B8D9',
    isFixed: true
  },
  { value: 'tolls', label: 'Peaje', color: '#00B8D9', isFixed: true }
]

export const initialState = {
  loading: false,
  data: [],
  selectedTrip: null,
  filters: deafultFilter,
  isSuperadmin: false,

  filterByOptions,

  nextPageExists: false
}
