const defaultValues = {
  code: '',
  display_name: '',
  short_name: '',
  description: '',
  active: true,
  area_id: ''
}

export const deafultFilter = {
  pagination: {
    page: 1,
    limit: 10
  },
  sorting: {
    code: 'created_at',
    sortOrder: 'desc'
  },
  filterOptions: {
    code: '',
    display_name: ''
  }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar zona' },
  update: { action: 'update', message: 'Actualizar zona' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  delete: { action: 'deleted', message: 'eliminar' }
}

const defaultSubzoneTypeOptions = [
  { value: 'residential', label: 'Residencial' },
  { value: 'commercial', label: 'Comercial' }
]

const defaultSubzoneBillingType = [
  { value: 'fixed-rate', label: 'Tarifa plana' },
  { value: 'hourly', label: 'Tarifa por hora' }
]

const handleErrors = {
  code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  display_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  short_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  area_id: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  description: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const initialState = {
  loading: false,
  loadingUsers: false,

  data: [],
  zonesOptions: [],
  filters: deafultFilter,

  selectedZone: null,

  nextPageExists: false,
  actions: defaultActions,

  handleErrors,

  subzoneTypeOptions: defaultSubzoneTypeOptions,
  subzoneBillingType: defaultSubzoneBillingType,

  defaultValues
}

export default initialState
