import { lazy } from 'react'
import { Navigate } from 'react-router-dom'


const prefix = '/parking-lot'
const Dashboard = lazy(() => import('@parkingLotViews/dashboard'))

const Coupons = lazy(() => import('@parkingLotViews/coupons'))
const CouponsPrivate = lazy(() => import('@parkingLotViews/coupons-private'))
const CouponsUsesHistory = lazy(() =>
  import('@parkingLotViews/coupons-uses-history')
)

const BuildingList = lazy(() => import('@parkingLotViews/buildings'))
const BoxOffice = lazy(() => import('@parkingLotViews/box-office'))

const ClosingHstory = lazy(() => import('@parkingLotViews/closing-history'))
const TicketsHistory = lazy(() => import('@parkingLotViews/tickets-history'))
const TicketsDigitize = lazy(() => import('@parkingLotViews/tickets-digitize'))
const PayTickets = lazy(() => import('@parkingLotViews/pay-ticket'))
const TicketsEnable = lazy(() => import('@parkingLotViews/tickets-enable'))
const Invoices = lazy(() => import('@parkingLotViews/invoices'))
const ConnectionReports = lazy(() =>
  import('@parkingLotViews/connection-reports')
)
const Commerces = lazy(() => import('@parkingLotViews/commerces'))
const Reports = lazy(() => import('@parkingLotViews/reports'))
const Settings = lazy(() => import('@parkingLotViews/settings'))
const PremiumActivation = lazy(() =>
  import('@parkingLotViews/premium-activation')
)
const PremiumHistory = lazy(() => import('@parkingLotViews/premium-history'))
const ValidateTicket = lazy(() => import('@parkingLotViews/tickets-validate'))

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const ParkingLotRoutes = [
  {
    element: <ValidateTicket />,
    path: `${prefix}/validate-ticket`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.PROMOTER,
        ability.SATC,
        ability.ATC,
        'estacionamiento-level-0'
      ],
      resources: ['Common']
    }
  },
  {
    element: <BuildingList />,
    path: `${prefix}/buildings`,
    meta: {
      actions: [ability.MANAGER],
      resources: ['Common']
    }
  },
  {
    element: <BoxOffice />,
    path: `${prefix}/box-office`,
    meta: {
      actions: [ability.MANAGER, ability.SATC, ability.SYSTEM],
      resources: ['Common']
    }
  },
  {
    element: <Coupons />,
    path: `${prefix}/coupons`,
    meta: {
      actions: [ability.MANAGER, ability.MARKETING],
      resources: ['Common']
    }
  },
  {
    element: <CouponsUsesHistory />,
    path: `${prefix}/coupons-uses-history`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.MARKETING, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <CouponsPrivate />,
    path: `${prefix}/coupons-private`,
    meta: {
      actions: ['pagodirecto-level-2'],
      resources: ['Common']
    }
  },

  {
    element: <Dashboard />,
    path: `${prefix}/dashboard`,
    meta: {
      actions: ['estacionamiento-level-2'],
      resources: ['Common']
    }
  },
  {
    element: <ClosingHstory />,
    path: `${prefix}/closing-history`,
    meta: {
      actions: ['estacionamiento-level-2'],
      resources: ['Common']
    }
  },
  {
    element: <TicketsHistory />,
    path: `${prefix}/tickets-history`,
    meta: {
      actions: [
        'estacionamiento-level-0',
        ability.MANAGER,
        ability.SATC,
        ability.ATC
      ],
      resources: ['Common']
    }
  },
  {
    element: <TicketsDigitize />,
    path: `${prefix}/tickets-digitize`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.SATC,
        ability.ATC,
        'estacionamiento-level-0',
        'comercio-estacionamiento-prepaid',
        'comercio-estacionamiento-postpaid'
      ],
      resources: ['Common']
    }
  },
  {
    element: <PayTickets />,
    path: `${prefix}/tickets-pay`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.PROMOTER,
        ability.SATC,
        ability.ATC,
        'estacionamiento-level-0',
        'comercio-estacionamiento-prepaid',
        'comercio-estacionamiento-postpaid'
      ],
      resources: ['Common']
    }
  },
  {
    element: <TicketsEnable />,
    path: `${prefix}/tickets-enable`,
    meta: {
      actions: [
        'pagodirecto-level-2',
        'estacionamiento-level-0',
        'comercio-estacionamiento-prepaid',
        'comercio-estacionamiento-postpaid'
      ],
      resources: ['Common']
    }
  },
  {
    element: <Invoices />,
    path: `${prefix}/invoices`,
    meta: {
      actions: ['comercio-estacionamiento-postpaid'],
      resources: ['Common']
    }
  },
  {
    element: <PremiumActivation />,
    path: `${prefix}/premium/activation`,
    meta: {
      actions: [ability.MANAGER, ability.SATC, 'estacionamiento-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <PremiumHistory />,
    path: `${prefix}/premium/history`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.SATC,
        ability.ATC,
        'estacionamiento-level-0'
      ],
      resources: ['Common']
    }
  },

  {
    element: <ConnectionReports />,
    path: `${prefix}/connection-reports`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.PROMOTER,
        ability.SATC,
        ability.ATC,
        'estacionamiento-level-0'
      ],
      resources: ['Common']
    }
  },
  {
    element: <Commerces />,
    path: `${prefix}/commerces`,
    meta: {
      actions: ['estacionamiento-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <Reports />,
    path: `${prefix}/reports`,
    meta: {
      actions: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC,
        'estacionamiento-level-0'
      ],
      resources: ['Common']
    }
  },
  {
    element: <Settings />,
    path: `${prefix}/settings`,
    meta: {
      actions: ['estacionamiento-level-0'],
      resources: ['Common']
    }
  }
]

export default ParkingLotRoutes
