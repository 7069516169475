import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setLoading,
  setSelectedUser,
  setValidateEventDetails,
  setEvents,
  setSelectedEvent,
  setStockOptions,
  setExchangeRate,
  setExtraFields
} from './slice'

import { t } from 'i18next'

import { getUserByDocumentService } from '../../../services/parking-lot/tickets'
import { isEmptyObject } from 'jquery'
import { setSelectedRecharge } from '../../customers/slice'
import { rechargeWalletService } from '../../../services/customers'
import {
  buyEventService,
  getEventDetailService,
  getEventListService,
  getExchangeRateService,
  unValidateEventDetails,
  validateEventDetails
} from '../../../services/users/events'

export const getExchangeRate = () => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getExchangeRateService()
      if (!ok) throw error

      dispatch(setExchangeRate(res.exchange_usd_to_bs))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getEvents = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await getEventListService()
      if (!ok) throw error

      const eventsOptions = res?.events?.map((event) => {
        const eventDate = new Date(event.event_date)
        const formattedDate = eventDate.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })

        return {
          value: event._id,
          label: `${event.title} - ${formattedDate}`,
          event
        }
      })
      dispatch(setEvents(eventsOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getEventStock = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedEvent, selectedUser } = getState().payEvent

      const body = {
        user_id: selectedUser?._id,
        event_id: selectedEvent?._id
      }

      const { ok, res, error } = await getEventDetailService(body)

      if (!ok) throw error

      dispatch(setSelectedEvent(res.event))

      const stockOptions = res.event.stock.map((stock) => ({
        value: stock._id,
        label: stock.name,
        stock
      }))

      const extraFields = res.event?.details?.extra_purchase_fields ?? []
      console.log(extraFields)

      dispatch(setStockOptions(stockOptions))
      dispatch(setExtraFields(extraFields))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getValidateEventTicketDetails = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await validateEventDetails(body)

      if (!ok) throw error
      if (res.event_ticket === null) throw error

      toast.success('Ticket validado ')
      dispatch(setValidateEventDetails(res.event_ticket))
    } catch (err) {
      toast.error(err?.description ?? 'Ticket no encontrado')
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const unValidateEventTicketDetails = (body) => {
  return async (dispatch, getState) => {
    try {
      const { validateEventDetails } = getState().payEvent

      dispatch(setLoading(true))
      const { ok, res, error } = await unValidateEventDetails(body)

      if (!ok) throw error

      const event_ref = validateEventDetails?.event_ref

      const validTicket = {
        ...res.event_tickets,
        event_ref
      }

      dispatch(setValidateEventDetails(validTicket))
      toast.success('Evento validado exitosamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const payUserEvent = (document) => {
  return async (dispatch, getState) => {
    try {
      const {
        selectedUser,
        selectedStock,
        selectedEvent,
        selectedReceptor,
        formExtraFields
      } = getState().payEvent

      // date
      const body = {
        event_id: selectedEvent._id,
        product_id: selectedStock._id,
        event_details: {
          ...selectedReceptor
        },
        user_id: selectedUser._id,
        ...formExtraFields
      }

      const { ok, res, error } = await buyEventService(body)
      if (!ok) throw error

      toast.success('Compra exitosa')
    } catch (error) {
      toast.error(error?.description ?? errors.GENERAL_ERR)
      throw error
    }
  }
}

export const getUserByDocument = (document) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getUserByDocumentService(document)
      if (!ok) throw error

      const username = res?.data

      if (isEmptyObject(username) || !username) {
        toast.error('Usuario no encontrado')
        throw new Error('Usuario no encontrado')
      }

      dispatch(setSelectedUser(username))

      toast.success('Usuario encontrado')

      return res
    } catch (error) {
      throw error
    }
  }
}

export const rechargeWalletEvent = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedRecharge } = getState().customers
      const { selectedUser } = getState().payEvent

      const userId = selectedUser?._id ?? ''
      const username = selectedUser?.username ?? ''
      const docNumber = selectedUser?.profile?.identification?.doc_id ?? ''

      const amount = selectedRecharge.amount
        .replace(/\./g, '')
        .replace(/,/g, '.')

      const sendBody = {
        ...selectedRecharge,
        amount,
        username,
        customer: userId
      }

      const { ok, res, error } = await rechargeWalletService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Recarga exitosa')
      dispatch(getUserByDocument(docNumber))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setSelectedRecharge(null))
      dispatch(setLoading(false))
    }
  }
}
