import { apiMethods } from '@services/methods'
import { couponsURL } from '@configs/apis'

const endpoints = {
  getBillingHistory: `${couponsURL}/superadmin/unidigital/bill/list`,
  getDirectory: `${couponsURL}/superadmin/unidigital/directory`,
  deleteDirectory: `${couponsURL}/superadmin/unidigital/directory/delete`,
  previewBilling: `${couponsURL}/superadmin/unidigital/bill/preview`,
  generateBill: `${couponsURL}/superadmin/unidigital/bill`
}

export const generateBillService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.generateBill,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const previewBillingService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.previewBilling,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getBillingHistoryService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getBillingHistory,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getDirectoryService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getDirectory,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const deleteDirectoryService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.deleteDirectory,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
