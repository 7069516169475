const defaultValues = {
  username: '',
  email: '',
  phone: '',
  pre_username: '',
  custom_name: '',
  local_number: '',
  name: '',
  last_name: '',
  phone_profile: '',
  doc_id: '',
  doc_type: 'V',
  short_name: ''
}

export const defaultFilter = {
  filterOptions: {
    fiscal_name: ''
  },
  pagination: {
    page: 1,
    limit: 10
  },
  sorting: {
    code: 'created_at',
    sortOrder: 'desc'
  }
}
export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar Compañía gruera' },
  update: { action: 'update', message: 'Actualizar Compañía gruera' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  delete: { action: 'deleted', message: 'eliminar' }
}

const defaultSubzoneTypeOptions = [
  { value: 'residential', label: 'Residencial' },
  { value: 'commercial', label: 'Comercial' }
]

const defaultSubzoneBillingType = [
  { value: 'fixed-rate', label: 'Tarifa plana' },
  { value: 'hourly', label: 'Tarifa por hora' }
]

const handleErrors = {
  username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    pattern: {
      value: /^\S+@\S+$/i,
      message: 'Correo invalido'
    }
  },
  phone: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  pre_username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  custom_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  local_number: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  last_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  phone_profile: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  doc_id: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const initialState = {
  loading: false,
  loadingUsers: false,

  data: [],

  filters: defaultFilter,

  selectedTowCompany: null,

  nextPageExists: false,
  actions: defaultActions,

  handleErrors,

  defaultValues
}

export default initialState
