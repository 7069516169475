export const groupByDate = (data) => {
  const indexedByDate = data.reduce((acc, obj) => {
    if (acc[obj.date]) {
      acc[obj.date] = [...acc[obj.date], obj]
    } else {
      acc[obj.date] = [obj]
    }
    return acc
  }, {})

  return Object.keys(indexedByDate).map((key) => ({
    date: key,
    commerces: indexedByDate[key]
  }))
}
