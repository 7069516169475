export const defaultFilter = {
  pagination: {
    page: 1,
    limit: 9
  }
}

// area, zone and subzones
export const defaultLocationFilters = {
  filterOptions: {
    areas_ids: [],
    zones_ids: [],
    subzones_ids: [],
    entity_commerces_ids: []
  }
}

export const parkingLotDefaultFilter = {
  fromDate: '',
  endDate: '',
  status: null,
  type: '',
  transaction_tag: '',
  query: '',
  ...defaultFilter
}

export const withdrawObject = {
  bank_account_id: '',
  commerces: [],
  currency: '',
  amount: 0
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar extensión' },
  update: { action: 'update', message: 'Actualizar extensión' },
  blocked: { action: 'blocked', message: 'actualizar' },
  delete: { action: 'deleted', message: 'eliminar' },
  enablePin: { action: 'enablePin', message: 'actualizar' },
  close: { action: 'close', message: 'cerrar' }
}

export const initialState = {
  isLoadingWallet: false,
  isLoadingWithdraw: false,
  isSaving: false,
  errorMsg: null,
  nextPageExists: false,
  formFilters: defaultFilter,
  filters: defaultFilter,
  locationFilters: defaultLocationFilters,
  withdrawObject,
  commercesAssociated: [],
  movementsList: [],
  totalAmount: 0,
  bankAccounts: [],
  transactions: [],
  selectedTransaction: null,
  entity_commerces_ids: [],
  commercesOptions: [],


  entitiesOptions: [],

  actions: defaultActions
}
