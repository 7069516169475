import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'sspos',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSspos: (state, action) => {
      state.data = action.payload
    },
    setSsposFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedSspos: (state, action) => {
      state.selectedSspos = action.payload
    }
  }
})

export const {
  setSsposFilters,
  setLoading,
  setSspos,
  setNextPageExists,
  setSelectedSspos
} = slice.actions

export const sspos = slice.reducer
