import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'approveTransactions',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setApproveTransactions: (state, action) => {
      state.data = action.payload
    },
    setSelectedApproveTransaction: (state, action) => {
      state.selectedApproveTransaction = action.payload
    },
    setApproveTransactionsFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    }

  }
})

export const {
  setLoading,
  setApproveTransactions,
  setSelectedApproveTransaction,
  setApproveTransactionsFilters,
  setNextPageExists
} = slice.actions

export const approveTransactions = slice.reducer
