import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setTransactions,
  setTransactionTagsOptions,
  setEventOptions
} from './slice'

import { t } from 'i18next'
import {
  getMobilePaidTransactions,
  getTransactionsDetailServices
} from '../../../services/users/mobilePaid'
import { transactionsApproveServices } from '../../../services/commerces'
import {
  getTransactions,
  setSelectedTransaction
} from '../../users/transactions'
import {
  notificationConstantsServices,
  sendMassiveNotificationServices,
  sendNotificationServices
} from '../../../services/commerces/withdrawal-history'

export const getMobilePaid = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().mobilePaid
      const { ok, res, error } = await getMobilePaidTransactions(filters)

      if (!ok) throw error

      dispatch(setNextPageExists(res?.nextPageExists ?? false))
      dispatch(setMobilePaid(res?.mobilePayments ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getTransactionDetails = (id) => {
  return async (dispatch, getState) => {
    try {
      const { selectedMobilePaid } = getState().mobilePaid
      const body = { mobile_payment_id: id }
      const { ok, res, error } = await getTransactionsDetailServices(body)

      if (!ok) throw error
      const state = {
        ...selectedMobilePaid,
        ...res?.recharge,
        _id: res?.recharge?.mobile_payment_ref
      }
      dispatch(setSelectedMobilePaid(state ?? null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const transactionsApprove = (reference) => {
  return async (dispatch, getState) => {
    try {
      const { selectedTransaction } = getState().transactions

      const body = {
        bill_id: selectedTransaction?.bill_ref?._id,
        pay_date: new Date(),
        pay_ref: reference,
        withdraw_id: selectedTransaction._id
      }

      const { ok, res, error } = await transactionsApproveServices(body)

      if (!ok) throw error

      toast.success(res.message)

      dispatch(setSelectedTransaction(res.withdraw))
      dispatch(getTransactions())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const getNotificationCenterConstants = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await notificationConstantsServices()

      if (!ok) throw error
      const eventsOptions = res?.events?.map((event) => ({
        label: event,
        value: event
      }))

      dispatch(setEventOptions(eventsOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const sendNotification = (data) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await sendNotificationServices(data)

      if (!ok) throw error

      toast.success(res.message)
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}

export const sendMassiveNotification = (data) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await sendMassiveNotificationServices(data)

      if (!ok) throw error

      toast.success(res.message)
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}
