import { apiMethods } from '@services/methods'
import { baseV2URL } from '@configs/apis'

const endpoints = {
  usersMetric: `${baseV2URL}/superadmin/dashboard/parking`
}

export const getCommercesMetricService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.usersMetric}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
