import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'parkingLotInvoices',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedInvoice: (state, action) => {
      state.selectedInvoice = action.payload
    },
    setInvoices: (state, action) => {
      state.invoices = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    }
  }
})

export const {
  setLoading,
  setSelectedInvoice,
  setInvoices,
  setStatus,
  setSearchBy,
  setDateRange,
  setPagination
} = slice.actions

export const parkingLotInvoices = slice.reducer
