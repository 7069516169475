import { apiMethods } from '@services/methods'
import { baseV2URL } from '@configs/apis'

const endpoints = {
  comparativeDistribution: `${baseV2URL}/parking/ticket-metrics/comparative-distribution`,
  temporalDistribution: `${baseV2URL}/parking/ticket-metrics/temporal-distribution`,
  ticketHistory: `${baseV2URL}/parking/tickets/latest`,
  closingHistory: `${baseV2URL}/parking/ticket-metrics/building-closing-history`
}

export const getTemporalDistributionService = async (query = 'daily', body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: `${endpoints.temporalDistribution}/${query}`,
      body
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getComparativeDistributionService = async (
  query = 'daily',
  body
) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: `${endpoints.comparativeDistribution}/${query}`,
      body
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const getLatestTicketsService = async (query = 'daily', body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: `${endpoints.ticketHistory}`,
      body
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const getClosingHistoryService = async (body) => {
  try {
    const { ok, res, error } = await apiMethods.authPostFetch({
      url: `${endpoints.closingHistory}`,
      body
    })

    return { ok, res, error }
  } catch (error) {
    return error
  }
}
