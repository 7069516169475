import { setLoading } from '@store/reports'
import { getReport } from '@services/reports/utils'
import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'

export const getReportFile = () => {
  return async (dispatch, getState) => {
    const { reports } = getState()
    dispatch(setLoading(true))

    const res = await getReport(reports.params)

    const message = res?.ok
      ? res?.res?.message || 'Cambios realizados con éxito'
      : res?.error?.description ||
        res?.error?.message ||
        'Ocurrió un error intente más tarde'

    toast((t) => (
      <BasicToastContent
        t={t}
        message={message}
        type={res?.result || 'danger'}
      />
    ))

    dispatch(setLoading(false))
  }
}
