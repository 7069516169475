import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setZone: (state, action) => {
      state.data = action.payload
    },

    setZonesFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload
    },
    setZonesOptions: (state, action) => {
      state.zonesOptions = action.payload
    }
  }
})

export const {
  setZonesFilters,
  setLoading,
  setZone,
  setNextPageExists,
  setSelectedZone,
  setZonesOptions
} = slice.actions

export const zones = slice.reducer
