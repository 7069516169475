import {
  setLoading,
  setSelectedDigitalAccount,
  setNextPageExists,
  setDigitalAccountList
} from './slice'
import { errors } from '@src/assets/constants/text'
import {
  listDigitalAccountsService,
  createDigitalAccountService,
  findDigitalAccountService,
  createDigitalAccountByFileService
} from '@services/commerces/digital-account'

import toast from 'react-hot-toast'

export const createDigitalAccountsByFile = (file) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createDigitalAccountByFileService(file)
      if (!ok) throw error
      toast.success(res.message ?? 'Cuentas digitales creadas correctamente')
    } catch (err) {
      console.log({ err })
      toast.error(err?.description ?? err?.message ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getDigitalAccounts = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await listDigitalAccountsService({})

      if (!ok) throw error
      dispatch(setDigitalAccountList(res.pending_transactions))
      dispatch(setNextPageExists(res.next_page_exist))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const createDigitalAccount = (
  { amount, concept, currency, transactionTag, input, control },
  fromUser
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const data = {
        amount: parseFloat(amount),
        concept,
        currency,
        transactionTag,
        input,
        control
      }
      if (fromUser) {
        data['type'] = 'customer'
      } else {
        data['type'] = 'email'
      }
      const { ok, res, error } = await createDigitalAccountService(data)
      if (!ok) throw error

      toast.success(res.message ?? 'Cuenta digital creada correctamente')
      dispatch(setSelectedDigitalAccount(res))
      dispatch(getDigitalAccounts())
    } catch (err) {
      console.log({ err })
      toast.error(err?.description ?? err?.message ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
export const findDigitalAccount = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await findDigitalAccountService(id)
      if (!ok) throw error

      dispatch(setSelectedDigitalAccount(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
