import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setTransactions,
  setTransactionTagsOptions,
  setMobilePaid,
  setSelectedMobilePaid,
  setTransfers,
  setSelectedTransfer
} from './slice'

import { t } from 'i18next'
import {
  getMobilePaidTransactions,
  getTransactionsDetailServices
} from '../../../services/users/mobilePaid'
import {
  changeTransferStatusService,
  getTransfersService
} from '../../../services/users/transfers'

export const getTransfers = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().transfers
      const { ok, res, error } = await getTransfersService(filters)

      if (!ok) throw error

      dispatch(setNextPageExists(res?.data?.nextPageExists ?? false))
      dispatch(setTransfers(res?.data?.movements ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const changeTransferStatus = (state) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedTransfer } = getState().transfers

      const body = {
        movement_id: selectedTransfer?._id,
        status: state?.action?.action,
        username: state?.username ?? null
      }
      const { ok, res, error } = await changeTransferStatusService(body)

      if (!ok) throw error

      toast.success(res?.message ?? 'El estado fue modificado correctamente')

      dispatch(setSelectedTransfer(res?.data?.movement))
      dispatch(getTransfers())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
