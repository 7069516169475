export const initialSelectedBuilding = () => {
  const item = window.localStorage.getItem('selectedBuilding')
  return item ? JSON.parse(item) : {}
}

const initialBillingInfo = () => {
  const buildingInfo = JSON.parse(
    window.localStorage.getItem('selectedBuilding')
  )
  const billing = buildingInfo ? buildingInfo?.building?.billing : {}
  return billing
}

export const initialPagination = {
  page: 0,
  show: 9,
  nextPageExists: false
}

export const defaultFiltersLogs = {
  username: null,
  moduleName: null,
  dateFrom: null,
  dateTo: null,
  skip: 0,
  limit: 9
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar acceso' },
  updateFee: { action: 'updateFee', message: 'Actualizar tarifas' },
  update: { action: 'update', message: 'Actualizar acceso' },
  delete: { action: 'deleted', message: 'Eliminar acceso' }
}

const initialActiveAmountsOptions = {
  hourAmount: { key: 'hour_amount', label: 'Hora' },
  secondHourAmount: { key: 'second_hour_amount', label: 'Segunda Hora' },
  thirdHourAmount: { key: 'third_hour_amount', label: 'Tercera Hora' },
  fourthHourAmount: { key: 'fourth_hour_amount', label: 'Cuarta Hora' },
  fifthHourAmount: { key: 'fifth_hour_amount', label: 'Quinta Hora' },
  sixthHourAmount: { key: 'sixth_hour_amount', label: 'Sexta Hora' },
  flatAmount: { key: 'flat_amount', label: 'Tarifa plana' },
  secondFlatAmount: {
    key: 'second_flat_amount',
    label: 'Segunda tarifa plana'
  },
  extraAmount: { key: 'extra_amount', label: 'Tarifa extra' },
  overnightAmount: { key: 'overnight_amount', label: 'Pernocta' }
}

const initialState = {
  // loadings
  loadingAccess: true,
  loadingAccessLogs: false,
  loadginAccessDetails: false,
  loadingBuildings: false,

  billingBuildingInfo: initialBillingInfo(),
  activeAmountsOptions: initialActiveAmountsOptions,

  nextPageExists: false,
  pagination: { ...initialPagination },

  buildingsOptions: [],
  selectedBuildingAccess: [],

  filtersLogs: defaultFiltersLogs,
  buildingsLogs: [],

  buildingsDetails: null,

  selectedBuilding: initialSelectedBuilding(),
  buildings: [],

  selectedAccess: null,

  selectedAction: {
    action: '',
    isConfirmModalOpen: false,
    isFormModalOpen: false
  },
  actions: defaultActions
}

export default initialState
