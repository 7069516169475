import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'plCommerces',
  initialState,
  reducers: {
    setSelectedCommerce: (state, action) => {
      state.selectedCommerce = action.payload
    },
    setCommerces: (state, action) => {
      state.commerces = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setSearchParam: (state, action) => {
      state.searchParam = action.payload
    },
    setLoadingTickets: (state, action) => {
      state.loadingTickets = action.payload
    },
    setSelectedTicket: (state, action) => {
      state.selectedTicket = action.payload
    }
  }
})

export const {
  setSelectedCommerce,
  setCommerces,
  setLoading,
  setDateRange,
  setPagination,
  setSearchParam,
  setLoadingTickets,
  setSelectedTicket
} = slice.actions

export const plCommerces = slice.reducer
