import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const prefix = '/services'

const Reports = lazy(() => import('@servicesViews/reports'))

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const ServicesRoutes = [
  {
    element: <Reports />,
    path: `${prefix}/service-reports`,
    meta: {
      actions: [ability.MANAGER, ability.SALES],
      resources: ['Common']
    }
  }
]

export default ServicesRoutes
