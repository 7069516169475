// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'parkingLotBuildings',
  initialState,
  reducers: {
    setSelectedBuilding: (state, action) => {
      state.selectedBuilding = action.payload
      localStorage.setItem('selectedBuilding', JSON.stringify(action.payload))
    },
    setBuildings: (state, action) => {
      state.buildings = action.payload
    },
    setBuildingsOptions: (state, action) => {
      state.buildingsOptions = action.payload
    },
    setSelectedBuildingAccess: (state, action) => {
      state.selectedBuildingAccess = action.payload
    },

    setBuildingsDetails: (state, action) => {
      state.buildingsDetails = action.payload
    },

    setFiltersLogs: (state, action) => {
      state.filtersLogs = action.payload
    },
    setBuildingsLogs: (state, action) => {
      state.buildingsLogs = action.payload
    },

    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },

    // Manage loadings
    setLoadingAccess: (state, action) => {
      state.loadingAccess = action.payload
    },
    setLoadingAccessLogs: (state, action) => {
      state.loadingAccessLogs = action.payload
    },
    setLoadginAccessDetails: (state, action) => {
      state.loadginAccessDetails = action.payload
    },
    setLoadingBuildings: (state, action) => {
      state.loadingBuildings = action.payload
    },
    setActiveAmounts: (state, action) => {
      state.activeAmounts = action.payload
    },

    setBillingBuildingInfo: (state, action) => {
      state.billingBuildingInfo = action.payload
    },
    setSelectedAction: (state, action) => {
      state.selectedAction = action.payload
    },
    setSelectedAccess: (state, action) => {
      state.selectedAccess = action.payload
    }
  }
})

export const {
  // Manage loadings
  setLoadingAccess,
  setLoadingAccessLogs,
  setLoadginAccessDetails,
  setLoadingBuildings,

  setFiltersLogs,
  setBuildingsLogs,

  setSelectedBuilding,
  setBuildings,
  setBuildingsOptions,
  setSelectedBuildingAccess,
  setBuildingsDetails,
  setNextPageExists,
  setPagination,

  setActiveAmounts,
  setBillingBuildingInfo,
  setSelectedAction,
  setSelectedAccess
} = slice.actions

export const parkingLotBuildings = slice.reducer
