export const defaultFilter = {
  date_range: {
    fromDate: null,
    untilDate: null
  },
  filterOptions: {
    campaign_name: ''
  },
  pagination: {
    page: 1,
    limit: 10
  }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultValues = {
  code: '',
  display_name: '',
  short_name: '',
  description: '',
  active: true,
  entity_commerce_id: '',
  pos_commerce_id: '6440a2e11e3f70e45ead7e6e',
  entity_percentage: '',
  pagodirecto_percentage: '',
  concession_percentage: ''
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar área' },
  update: { action: 'update', message: 'Actualizar área' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  updatePercents: {
    action: 'updatePercents',
    message: 'Actualizar porcentajes'
  },
  associatedCompany: {
    action: 'updateAssociatedCompany',
    message: 'Actualizar empresa asociada'
  },
  associatedPenalty: {
    action: 'updateAssociatedPenalty',
    message: 'Actualizar empresa asociada'
  },
  associatedTowPenalty: {
    action: 'associatedTowPenalty',
    message: 'Actualizar empresa asociada grúas'
  },
  delete: { action: 'deleted', message: 'eliminar' }
}

const defaultSubzoneTypeOptions = [
  { value: 'residential', label: 'Residencial' },
  { value: 'commercial', label: 'Comercial' }
]

const defaultSubzoneBillingType = [
  { value: 'fixed-rate', label: 'Tarifa plana' },
  { value: 'hourly', label: 'Tarifa por hora' }
]

const handleErrors = {
  code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  subzone_code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  short_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  display_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  entity_commerce_id: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  description: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  entity_percentage: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El porcentaje debe ser mayor a 0'
    },
    max: {
      value: 100,
      message: 'El porcentaje debe ser menor o igual a 100'
    },
    validate: (value) => !isNaN(value) || 'Por favor, introduce un número'
  },
  pagodirecto_percentage: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El porcentaje debe ser mayor a 0'
    },
    max: {
      value: 100,
      message: 'El porcentaje debe ser menor o igual a 100'
    },
    validate: (value) => !isNaN(value) || 'Por favor, introduce un número'
  },
  concession_percentage: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El porcentaje debe ser mayor a 0'
    },
    max: {
      value: 100,
      message: 'El porcentaje debe ser menor o igual a 100'
    },
    validate: (value) => !isNaN(value) || 'Por favor, introduce un número'
  }
}

const initialState = {
  loading: false,
  areasOptions: [],
  loadingUsers: false,

  data: [],

  filters: defaultFilter,

  selectedArea: null,

  nextPageExists: false,
  actions: defaultActions,

  handleErrors,

  subzoneTypeOptions: defaultSubzoneTypeOptions,
  subzoneBillingType: defaultSubzoneBillingType,

  defaultValues
}

export default initialState
