export const defaultFilter = {
  type: 'all', // "collected" | "non-collected" | "all"
  pagination: {
    page: 1,
    limit: 25
  }
}
export const parkingLotDefaultFilter = {
  fromDate: '',
  endDate: '',
  status: null,
  type: '',
  transaction_tag: '',
  query: '',
  ...defaultFilter
}

export const withdrawObject = {
  bank_account_id: '',
  commerces: [],
  currency: '',
  amount: 0
}
export const initialState = {
  isLoadingWallet: false,
  isLoadingWithdraw: false,
  isSaving: false,
  errorMsg: null,
  nextPageExists: false,
  formFilters: defaultFilter,
  withdrawObject,
  commercesAssociated: [],
  movementsList: [],
  totalAmount: 0,
  commerceId: '',
  bankAccounts: [],
  childrenBill: []
}
