import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'
import { errors } from '@src/assets/constants/text'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import {
  setLoadingPenalty,
  setNextPageExistsPenalty,
  setPenaltyData,
  setPenaltyTypeOptions
} from './slice'
import {
  getPenaltiesTypesAdmin,
  getPenaltiesTypesSuperadmin,
  getPenaltyList,
  getPenaltySuperadminList
} from '../../../services/parking-meters/penalty'

const endpoints = {
  getPenaltiesOptions: `${parkingMetersURL}/superadmin/penalties/types`
}

export const getPenaltiesOptions = () => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      const { ok, res } = isSuperadmin
        ? await getPenaltiesTypesSuperadmin()
        : await getPenaltiesTypesAdmin()

      dispatch(setPenaltyTypeOptions(res.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getPenalties = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingPenalty(true))

      const filters = getState().penaltyReducer.filters
      const { isSuperadmin } = getState().superadmin

      const { ok, res, error } = isSuperadmin
        ? await getPenaltySuperadminList(filters)
        : await getPenaltyList(filters)

      if (!ok) throw error

      dispatch(setPenaltyData(res.data.items))
      dispatch(setNextPageExistsPenalty(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoadingPenalty(false))
    }
  }
}
