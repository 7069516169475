// ** React Imports
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Utils
import {
  getOperatorData,
  getCommerceData,
  getHomeRouteForLoggedInUser
} from '@utils'

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover
  } = props

  // ** Vars
  const operator = getOperatorData()
  const commerce = getCommerceData()
  const category = commerce?.category
  const [icon, setIcons] = useState('')
  const skin = JSON.parse(localStorage.getItem('skin'))
  const setIcon = () => {
    const skin = JSON.parse(localStorage.getItem('skin'))
      ? JSON.parse(localStorage.getItem('skin'))
      : 'light'

    const defaultIcon =
      require(`@src/assets/images/icons/PosDirecto${skin}.png`).default
    const iconsByCategory = {
      peaje: require(`@src/assets/images/icons/Peajes${skin}.png`).default,
      parquimetro:
        require(`@src/assets/images/icons/PosDirectoParquimetros${skin}.png`)
          .default,
      estacionamiento:
        require(`@src/assets/images/icons/PosDirectoEstacionamientos${skin}.png`)
          .default
    }
    const icon = iconsByCategory[category] || defaultIcon
    setIcons(icon)
  }

  useEffect(() => {
    setIcon()
  }, [skin])

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }
  return (
    <div className='navbar-header' style={{ height: '10vh' }}>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item me-auto w-75'>
          <NavLink
            to={operator ? getHomeRouteForLoggedInUser(operator) : '/'}
            className='navbar-brand'
          >
            <span className='brand-logo'>
              <img
                src={
                  !menuCollapsed || menuHover
                    ? icon
                    : themeConfig.app.appLogoImageResponsive
                }
                style={
                  !menuCollapsed || menuHover
                    ? {
                        maxWidth: '250px',
                        height: '6vh',
                        paddingLeft: '1vw'
                      }
                    : {}
                }
                alt='logo'
              />
            </span>
            <h2 className='brand-text mb-0'>{themeConfig.app.appName}</h2>
          </NavLink>
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <Toggler />
            <X
              onClick={() => setMenuVisibility(false)}
              className='toggle-icon icon-x d-block d-xl-none'
              size={20}
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
