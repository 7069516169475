import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'mobilePaid',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setMobilePaid: (state, action) => {
      state.data = action.payload
    },
    setMobilePaidFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedMobilePaid: (state, action) => {
      state.selectedMobilePaid = action.payload
    }
  }
})

export const {
  setLoading,
  setMobilePaid,
  setMobilePaidFilters,
  setNextPageExists,
  setSelectedMobilePaid
} = slice.actions

export const mobilePaid = slice.reducer
