import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'associatedCompany',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAssociatedCompany: (state, action) => {
      state.data = action.payload
    },
    setAssociatedCompanyFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedAssociatedCompany: (state, action) => {
      state.selectedAssociatedCompany = action.payload
    },
    setAssociatedCompanyOptions: (state, action) => {
      state.associatedCompanyOptions = action.payload
    }
  }
})

export const {
  setAssociatedCompanyFilters,
  setLoading,
  setAssociatedCompany,
  setNextPageExists,
  setSelectedAssociatedCompany,
  setAssociatedCompanyOptions
} = slice.actions

export const associatedCompany = slice.reducer
