import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const slice = createSlice({
  name: 'parkingLotTickets',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedTicket: (state, action) => {
      state.selectedTicket = action.payload
      state.selectedAccess = action.payload?.building?.accesses.map(
        (access) => {
          if (access.type === 'exit') {
            return {
              label: access.name,
              value: access.mac
            }
          }
        }
      )
    },
    setTicketsList: (state, action) => {
      state.ticketsList = action.payload
    },
    setSearchParam: (state, action) => {
      state.searchParam = action.payload
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setSearchForm: (state, action) => {
      state.searchForm = action.payload
    },
    setTicketOptionLoading: (state, action) => {
      state.ticketOptionLoading = action.payload
    },
    setTicketsBuilding: (state, action) => {
      state.building = action.payload
    },
    setTicketAmount: (state, action) => {
      state.ticketAmount = action.payload
    },
    setPhysicalTicketBuildingsOptions: (state, action) => {
      state.physicalTicketBuildingsOptions = action.payload
    },
    setSelectedPhysicalTicket: (state, action) => {
      state.selectedPhysicalTicket = action.payload
    },
    setSelectedPhysicalBuilding: (state, action) => {
      state.selectedPhysicalBuilding = action.payload
    }
  }
})

export const {
  setLoading,
  setSelectedTicket,
  setTicketsList,
  setPagination,
  setSearchBy,
  setSearchParam,
  setDateRange,
  setSearchForm,
  setTicketOptionLoading,
  setTicketsBuilding,
  setTicketAmount,
  setPhysicalTicketBuildingsOptions,

  setSelectedPhysicalBuilding,
  setSelectedPhysicalTicket
} = slice.actions

export const parkingLotTickets = slice.reducer
