import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'
import { setArea, setAreas, setOpenModal, setUpdate } from './slice'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'

const endpoints = {
  loadAreas: `${parkingMetersURL}/superadmin/areas/list`,
  addAreas: `${parkingMetersURL}/superadmin/areas`,
  deleteArea: `${parkingMetersURL}/superadmin/areas`,
  updateArea: `${parkingMetersURL}/superadmin/areas/update`
}

const filters = {
  concession_commerce_id: '63882e1dbb7e036a8ee93fe5',
  pagination: {
    page: 1,
    limit: 6
  },
  sorting: {
    code: 'created_at',
    sortOrder: 'desc'
  },
  filterOptions: {
    code: '',
    display_name: ''
  }
}

const getAreas = async (defaultFilters) => {
  try {
    const response = await apiMethods.authPostTemp({
      url: endpoints.loadAreas,
      body: defaultFilters
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadAreas = (defaultFilters) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await getAreas(defaultFilters)

      if (ok) {
        dispatch(setAreas(res.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const addArea = (body) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authPostTemp({
        url: endpoints.addAreas,
        body
      })

      if (ok) {
        const { res } = await getAreas(filters)
        dispatch(setAreas(res.data))

        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Área añadida exitosamente'}
            type={'success'}
          />
        ))
      } else {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Ocurrió un error al registrar el área'}
            type={'danger'}
          />
        ))
      }
      // if (ok) {
      //   dispatch(setAreas(res.data))
      // }
    } catch (error) {
      console.log(error)
    }
  }
}

export const softDeleteArea = (id) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authDelete({
        url: `${endpoints.deleteArea}/${id}`
      })

      if (ok) {
        const { res } = await getAreas(filters)
        dispatch(setAreas(res.data))

        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Área eliminada exitosamente'}
            type={'success'}
          />
        ))
      } else {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Ocurrió un error al registrar el área'}
            type={'danger'}
          />
        ))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const updateArea = (body) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authPut({
        url: `${endpoints.updateArea}`,
        body
      })

      if (ok) {
        const { res } = await getAreas(filters)
        dispatch(setAreas(res.data))

        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Área eliminada exitosamente'}
            type={'success'}
          />
        ))
      } else {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Ocurrió un error al registrar el área'}
            type={'danger'}
          />
        ))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const openModal = (action) => {
  return async (dispatch) => {
    dispatch(setOpenModal(action))
  }
}

export const setAreaForm = (body) => {
  return async (dispatch) => {
    dispatch(setArea(body))
  }
}

export const setUpdateForm = (action) => {
  return async (dispatch) => {
    dispatch(setUpdate(action))
  }
}
