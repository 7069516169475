import { lazy } from 'react'
import { Navigate } from 'react-router-dom'


const prefix = '/sspos'

const List = lazy(() => import('@ssposViews/sspos-list'))

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const ParkingLotRoutes = [
  {
    element: <List />,
    path: `${prefix}/list`,
    meta: {
      actions: [ability.MANAGER, ability.SYSTEM],
      resources: ['Common']
    }
  }
]

export default ParkingLotRoutes
