import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd02 } from '@configs/apis'

const endpoints = {
  amountHistory: `${baseV2URL}/superadmin/dashboard/amount-history`,
  accountStatus: `${micropd02}/superadmin/bank-transaction/account-status`,
  bankTransaction: `${micropd02}/superadmin/bank-transaction/list`
}

export const amountHistoryService = async (body) => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.amountHistory)
    return response
  } catch (error) {
    return error
  }
}

export const bankTransactionService = async (body) => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.bankTransaction)
    return response
  } catch (error) {
    return error
  }
}

export const accountStatusService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.accountStatus,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

