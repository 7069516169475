import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL } from '@configs/apis'

const urlBase = `${baseV1URL}`

const endpoints = {
  notificationConstants: `${urlBase}/notification/constants`,
  sendNotification: `${urlBase}/notifications/send`,
  massiveNotification: `${baseV2URL}/superadmin/push
`
}

export const notificationConstantsServices = async () => {
  try {
    const response = await apiMethods.authGetFetch(
      endpoints.notificationConstants
    )
    return response
  } catch (error) {
    return error
  }
}

export const sendNotificationServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.sendNotification,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const sendMassiveNotificationServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetchFormData({
      url: endpoints.massiveNotification,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}
