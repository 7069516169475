// ** Icons Import
import {
  Bluetooth,
  Download,
  Settings,
  Smartphone,
  List,
  Bookmark,
  Star,
  Circle,
  CheckSquare,
  DollarSign,
  FileText
} from 'react-feather'

const commercePrepaid = [
  {
    header: 'Estacionamiento',
    action: 'comercio-estacionamiento-prepaid',
    resource: 'Common'
  },
  {
    id: 'TicketsDigitizePrepaid',
    title: 'Digitalizar Tickets',
    action: 'comercio-estacionamiento-prepaid',
    resource: 'Common',
    icon: <Smartphone size={20} />,
    navLink: '/parking-lot/tickets-digitize'
  }
]

const commercePostpaid = [
  {
    header: 'Estacionamiento',
    action: 'comercio-estacionamiento-postpaid',
    resource: 'Common'
  },
  {
    id: 'TicketsDigitizePost',
    title: 'Digitalizar Tickets',
    action: 'comercio-estacionamiento-postpaid',
    resource: 'Common',
    icon: <Smartphone size={20} />,
    navLink: '/parking-lot/tickets-digitize'
  },
  {
    id: 'TicketsInvoices',
    title: 'Recibos',
    action: 'comercio-estacionamiento-postpaid',
    resource: 'Common',
    icon: <DollarSign size={20} />,
    navLink: '/parking-lot/invoices'
  }
]

export default [
  {
    header: 'Estacionamiento',
    action: 'estacionamiento-level-0',
    resource: 'Common'
  },
  {
    id: 'TicketsHistory',
    title: 'Historial de Tickets',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <List size={20} />,
    navLink: '/parking-lot/tickets-history'
  },

  {
    id: 'TicketsDigitize',
    title: 'Digitalizar Tickets',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Smartphone size={20} />,
    navLink: '/parking-lot/tickets-digitize'
  },
  // {
  //   id: 'PayParkingTickets',
  //   title: 'Pagar tickets',
  //   action: 'estacionamiento-level-0',
  //   resource: 'Common',
  //   icon: <Smartphone size={20} />,
  //   navLink: '/parking-lot/tickets-pay'
  // },
  // {
  //   id: 'InfoParkingTicketsPhysic',
  //   title: 'Ticket físico info',
  //   action: 'estacionamiento-level-0',
  //   resource: 'Common',
  //   icon: <FileText size={20} />,
  //   navLink: '/parking-lot/validate-ticket'
  // },
  {
    id: 'Premium',
    title: 'Premium',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Star size={20} />,
    children: [
      {
        id: 'Activation',
        title: 'Activación',
        action: 'estacionamiento-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/parking-lot/premium/activation'
      },
      {
        id: 'History',
        title: 'Historial',
        action: 'estacionamiento-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/parking-lot/premium/history'
      }
      /*       {
        id: 'Invoices',
        title: 'Recibos',
        action: 'estacionamiento-level-0',
        resource: 'Common',
        icon: <Circle size={12} />,
        navLink: '/parking-lot/premium/invoices'
      } */
    ]
  },
  {
    id: 'ConnectionReports',
    title: 'Informes de conexión',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Bluetooth size={20} />,
    navLink: '/parking-lot/connection-reports'
  },
  {
    id: 'ParkingLotCommerces',
    title: 'Commercios',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Bookmark size={20} />,
    navLink: '/parking-lot/commerces'
  },
  {
    id: 'ParkingLotReports',
    title: 'Reportes',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Download size={20} />,
    navLink: '/parking-lot/reports'
  },
  {
    id: 'ParkingLotSettings',
    title: 'Ajustes',
    action: 'estacionamiento-level-0',
    resource: 'Common',
    icon: <Settings size={20} />,
    navLink: '/parking-lot/settings'
  },
  ...commercePostpaid,
  ...commercePrepaid
]
