// ** Navigation imports
import tolls from './tolls'
import commerces, { mainCommercesItems } from './commerces'
import forms from './forms'
import parkingMeters from './parkingMeters'
import parkingLot from './parkingLot'
import superadmin from './superadmin'
import insurance from './insurance'

export default [
  ...mainCommercesItems,
  ...parkingMeters,
  ...tolls,
  ...forms,
  ...parkingLot,
  ...superadmin,
  ...insurance,
  ...commerces
]
