import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import { setLoading, setNextPageExists, setSelectedSspos, setSspos } from '.'
import {
  changeSsposPasswordServices,
  createSspos,
  deleteSsposService,
  getSsposByIdService,
  getSsposList,
  resetSsposPasswordService,
  updateSsposService
} from '../../../services/sspos'

export const getSspos = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().sspos.filters

      const { ok, res, error } = await getSsposList(filters)

      if (!ok) throw error

      dispatch(setSspos(res.ssposList))
      dispatch(setNextPageExists(res.nextPageExist))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getSsposById = (id) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getSsposByIdService(id)
      if (!ok) throw error
      dispatch(setSelectedSspos(res.sspos))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
    }
  }
}

export const addSspos = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createSspos(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Sspos creada correctamente')

      dispatch(getSspos())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateSspos = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedSspos = getState().sspos.selectedSspos

      const sendBody = {
        posname: body.posname,
        building_id: body.building,
        serial_pos: body.serial_pos,
        pos_id: selectedSspos._id
      }

      const { ok, res, error } = await updateSsposService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Sspos actualizada correctamente')

      dispatch(getSspos())
      dispatch(setSelectedSspos(res.sspos))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateSsposStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedSspos = getState().sspos.selectedSspos

      const sendBody = {
        pos_id: selectedSspos._id,
        posname: selectedSspos.posname,
        status: selectedSspos.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = await updateSsposService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Sspos actualizada correctamente')

      dispatch(getSspos())
      dispatch(setSelectedSspos(res.sspos))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteSspos = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedSspos = getState().sspos.selectedSspos

      const sendBody = {
        sspos_id: selectedSspos._id
      }

      const { ok, res, error } = await deleteSsposService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Sspos eliminado correctamente')

      dispatch(getSspos())
      dispatch(setSelectedSspos(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const resetSsposPassword = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedSspos = getState().sspos.selectedSspos

      const sendBody = {
        sspos_id: selectedSspos._id
      }

      const { ok, res, error } = await resetSsposPasswordService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Contraseña restablecida correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const changePassword = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const selectedSspos = getState().sspos.selectedSspos

      const sendBody = {
        password: body.password,
        sspos_id: selectedSspos._id
      }

      const { ok, res, error } = await changeSsposPasswordServices(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Contraseña cambiada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
