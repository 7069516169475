// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense } from 'react'

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const ability = useContext(AbilityContext)
  const operator = JSON.parse(localStorage.getItem('operator'))
  if (route) {
    let actions = null
    let resources = null
    let restrictedRoute = false

    if (route.meta) {
      actions = route.meta.actions
      resources = route.meta.resources
      restrictedRoute = route.meta.restricted
    }
    if (!operator) {
      return <Navigate to='/login' />
    }
    if (operator && restrictedRoute) {
      return <Navigate to='/' />
    }
    if (operator && actions && resources) {
      for (const resource of resources) {
        for (const action of actions) {
          if (ability.can(action, resource)) {
            return <Suspense fallback={null}>{children}</Suspense>
          }
        }
      }
    }
  }

  if (operator && !ability.can('level-0', 'Common')) {
    return <Navigate to='/auth/not-authorized' replace />
  }
  return <Navigate to='/commerces/access-control' replace />
}

export default PrivateRoute
