import { apiMethods } from '@services/methods'
import { baseV2URL } from '@configs/apis'

const endpoints = {
  getAccounts: `${baseV2URL}/superadmin/commerces/list/category`,
  getMovements: `${baseV2URL}/superadmin/commerces/movements`,
  previewWithdraw: `${baseV2URL}/superadmin/withdraw/preview`
}

export const getAccountsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getAccounts,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getMovementsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getMovements,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const previewWithdrawService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.previewWithdraw,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
