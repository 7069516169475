import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  setNextPageExists,
  setSubzone,
  setLoading,
  setSelectedSubzone,
  setSubzoneOptions
} from './'
import {
  associateCompanyPenaltyToSubzoneService,
  associateCompanyToSubzoneService,
  associateTowPenaltyToSubzoneService,
  createSubzone,
  deleteSubzoneService,
  getSubzonesList,
  updateSubzoneService
} from '../../../services/parking-meters/subzones'
import { associateCompanyToAreaService } from '../../../services/parking-meters'

export const getSubzones = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().subzones.filters

      const { ok, res, error } = await getSubzonesList(filters)

      if (!ok) throw error

      dispatch(setSubzone(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getSubzonesOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().subzones.filters

      const { ok, res, error } = await getSubzonesList(filters)

      if (!ok) throw error

      const subzoneOptions = res.data.items.map((subzone) => ({
        label: subzone.display_name,
        value: subzone._id,
        code: subzone.subzone_code,
        item: subzone
      }))
      dispatch(setSubzoneOptions(subzoneOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addSubzone = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createSubzone(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Subzona creada correctamente')

      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateSubzone = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await updateSubzoneService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Subzona editada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateSubzoneStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedSubzone } = getState().subzones

      const body = {
        ...selectedSubzone,
        active: !selectedSubzone.active
      }

      const { ok, res, error } = await updateSubzoneService(body)
      if (!ok) throw error

      toast.success(res.message ?? 'Subzona editada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteSubzone = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedSubzone } = getState().subzones

      const subzoneId = selectedSubzone._id
      console.log(subzoneId)
      const { ok, res, error } = await deleteSubzoneService(subzoneId)

      if (!ok) throw error

      toast.success(res.message ?? 'Subzona eliminada correctamente')
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const associatedCompanyToSubzone = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await associateCompanyToSubzoneService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía asociada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const associatedCompanyPenaltyToSubzone = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const sendBody = {
        subzone_id: body.subzone_id,
        allow_penalty_subzone_distribution: true,
        penalty_profit_distribution: body.profit_distribution
      }

      const { ok, res, error } = await associateCompanyPenaltyToSubzoneService(
        sendBody
      )

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía asociada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const associatedTowPenaltyToSubzone = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const sendBody = {
        subzone_id: body.subzone_id,
        allow_tow_penalty_subzone_distribution: true,
        tow_penalty_profit_distribution: body.profit_distribution
      }

      const { ok, res, error } = await associateTowPenaltyToSubzoneService(
        sendBody
      )

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía asociada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updatedProfitRule = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedSubzone } = getState().subzones

      const body = {
        subzone_id: selectedSubzone._id,
        allow_subzone_distribution: !selectedSubzone.allow_subzone_distribution,
        profit_distribution: selectedSubzone?.transaction_distribution
      }

      const { ok, res, error } = await associateCompanyToSubzoneService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Subzona editada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updatedProfitPenaltyRule = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedSubzone } = getState().subzones

      const body = {
        subzone_id: selectedSubzone._id,
        allow_penalty_subzone_distribution:
          !selectedSubzone?.allow_penalty_subzone_distribution,
        penalty_profit_distribution:
          selectedSubzone?.penalty_transaction_distribution
      }

      const { ok, res, error } = await associateCompanyPenaltyToSubzoneService(
        body
      )

      if (!ok) throw error

      toast.success(res.message ?? 'Subzona editada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updatedTowProfitPenaltyRule = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedSubzone } = getState().subzones

      const body = {
        subzone_id: selectedSubzone._id,
        allow_tow_penalty_subzone_distribution:
          !selectedSubzone?.allow_tow_penalty_subzone_distribution,
        tow_penalty_profit_distribution:
          selectedSubzone?.tow_transaction_distribution
      }

      const { ok, res, error } = await associateTowPenaltyToSubzoneService(body)

      if (!ok) throw error

      toast.success(res.message ?? 'Subzona editada correctamente')

      dispatch(setSelectedSubzone(res.data))
      dispatch(getSubzones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
