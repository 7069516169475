import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getAssociatedCompany: `${parkingMetersURL}/superadmin/commerce/associated-company/list`,
  createAssociatedCompany: `${parkingMetersURL}/superadmin/commerce/associated-company`,
  updateAssociatedCompany: `${parkingMetersURL}/superadmin/commerce/associated-company`
}

export const getAssociatedCompanyList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getAssociatedCompany,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createAssociatedCompany = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createAssociatedCompany,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateAssociatedCompanyService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.updateAssociatedCompany,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
