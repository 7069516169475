export * from './utils'
import { apiMethods } from '@services/methods'
import { baseV2URL } from '@configs/apis'

const endpoints = {
  autocomplete: `${baseV2URL}/parking/commerces/autocomplete`,
  dateRange: `${baseV2URL}/parking/commerces/digitize`,
  tickets: `${baseV2URL}/parking/commerces/tickets`
}

export const plCommercesAutocomplete = async ({
  searchParam: input,
  building: building_id
}) => {
  try {
    const url = endpoints.autocomplete
    const body = {
      input,
      building_id
    }
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
export const plCommercesByDate = async ({
  building: building_id,
  fromDate: start_date,
  endDate: end_date,
  page: skip,
  show: limit
}) => {
  const url = endpoints.dateRange
  const body = {
    building_id,
    start_date,
    end_date,
    skip,
    limit
  }
  const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
  return { ok, res, error }
}

export const plCommercesTickets = async ({ commerce: commerce_id, date }) => {
  const url = endpoints.tickets
  const body = {
    commerce_id,
    date
  }
  const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
  return { ok, res, error }
}
