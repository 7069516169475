// ** React Imports
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Layout from '@layouts/VerticalLayout'
import navigation from '@src/navigation/vertical'
import { getOperatorData } from '@utils'
import { joinCommerceSocket, removeSocketListener } from '@services/websocket'

const VerticalLayout = (props) => {
  const operator = getOperatorData()
  useEffect(() => {
    if (operator && operator.token) {
      joinCommerceSocket(operator.token, (socket_res) => {
        console.log(socket_res.message)
      })
    }

    return () => {
      removeSocketListener()
    }
  }, [])
  return (
    <Layout menuData={navigation} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
