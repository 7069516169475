import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'parkingLotDashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setxAxisData: (state, action) => {
      state.xAxisData = action.payload
    },
    setCurrentPeriodData: (state, action) => {
      state.currentPeriodData = action.payload
    },
    setPreviusPeriodData: (state, action) => {
      state.previusPeriodData = action.payload
    },
    setTemporalAxisData: (state, action) => {
      state.xTemporalAxisData = action.payload
    },
    setTemporalPeriodData: (state, action) => {
      state.temporalPeriodData = action.payload.tickets
      state.temporalPeriodDataAmount = action.payload.amount
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setLastTickets: (state, action) => {
      state.lastTickets = action.payload
    },
    setClosingHistory: (state, action) => {
      state.closingHistory = action.payload
    },
    setSelectedClosingHistory: (state, action) => {
      state.selectedClosingHistory = action.payload
    },
    setTotalAmount: (state, action) => {
      state.totalAmount = action.payload
    },
    setTicketsTotal: (state, action) => {
      state.totalTickets = action.payload
    },
    setPreviousAmount: (state, action) => {
      state.previousAmount = action.payload
    },
    setPreviousTickets: (state, action) => {
      state.previousTickets = action.payload
    }
  }
})

export const {
  setLoading,
  setNextPageExists,
  setxAxisData,
  setCurrentPeriodData,
  setPreviusPeriodData,
  setTemporalAxisData,
  setTemporalPeriodData,
  setFilters,
  setLastTickets,
  setClosingHistory,
  setSelectedClosingHistory,
  setTotalAmount,
  setTicketsTotal,
  setPreviousAmount,
  setPreviousTickets
} = slice.actions

export const parkingLotDashboard = slice.reducer
