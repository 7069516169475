export const defaultFilter = {
  date_range: {
    fromDate: null,
    untilDate: null
  },
  filterOptions: {
    campaign_name: ''
  },
  pagination: {
    page: 1,
    limit: 10
  }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar extensión' },
  update: { action: 'update', message: 'Actualizar extensión' },
  blocked: { action: 'blocked', message: 'actualizar' },
  delete: { action: 'deleted', message: 'eliminar' },
  enablePin: { action: 'enablePin', message: 'actualizar' },
  recharge: { action: 'recharge', message: 'Recargar Wallet' }
}

const defaultGenderOptions = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Femenino' },
  { value: 'O', label: 'Otro' }
]

const defaultStatusOptions = [
  { value: 'active', label: 'Activo' },
  { value: 'inactive', label: 'Inactivo' }
]

const typeLimitExtensionOptions = {
  none: { value: 'none', label: 'Ninguno' },
  daily: { value: 'daily', label: 'Diario' },
  byTransaction: { value: 'byTransaction', label: 'Por transacción' }
}

const initialState = {
  loading: false,
  data: [],

  typeLimitExtensionOptions,

  filters: defaultFilter,

  selectedExtensionList: [],
  selectedExtension: null,
  selectedUserExtension: null,

  schoolOptions: [],

  nextPageExists: false,
  actions: defaultActions,

  genderOptions: defaultGenderOptions,

  statusOptions: defaultStatusOptions
}

export default initialState
