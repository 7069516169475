import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd03, baseV1URL } from '@configs/apis'

const endpoints = {
  dailyCollection: `${micropd03}/superadmin/advertisement-metrics/daily-collect`
}

export const advertisingDailyCollectionService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.dailyCollection,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getComparativeAdvertisingDistribution = async (query, body) => {
  try {
    const url = `${micropd03}/superadmin/advertisement-metrics/get-${query}-comparative-distribution`

    const response = await apiMethods.authPostFetch({
      url,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getTemporalAdvertisingDistribution = async (query, body) => {
  try {
    const url = `${micropd03}/superadmin/advertisement-metrics/get-${query}-temporal-distribution`

    const response = await apiMethods.authPostFetch({
      url,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
