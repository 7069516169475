import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setAccountOptions,
  setData,
  setSelectedAccount,
  setWithdrawAccount
} from './slice'

import {
  getAccountsService,
  getMovementsService,
  previewWithdrawService
} from '../../../services/wallet/accounts'
import { handleShowAmountBs } from '../../../utility/Utils'

export const getAccounts = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const body = {
        category: 'pagodirecto'
      }

      const { ok, res, error } = await getAccountsService(body)

      if (!ok) throw error

      const commercesOptions = res?.commerces?.map((commerce) => ({
        value: commerce._id,
        label: `${commerce.name}: ${handleShowAmountBs(
          commerce.amounts[0].funds
        )}`,
        commerce
      }))

      dispatch(setAccountOptions(commercesOptions ?? []))
      dispatch(setNextPageExists(true))
    } catch (err) {
      toast.error(
        err?.description ?? 'Error al buscar los datos de las cuentas bancarias'
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getMovements = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().pdAccounts

      const { ok, res, error } = await getMovementsService(filters)
      if (!ok) throw error

      dispatch(setData(res?.transactions ?? []))
    } catch (err) {
      toast.error(err?.description ?? 'Error al realizar la transferencia')
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const previewWithdraw = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().pdAccounts

      const body = {
        commerce_id: filters.customer_id
      }

      const { ok, res, error } = await previewWithdrawService(body)
      if (!ok) throw error
      console.log(res)
      dispatch(setWithdrawAccount(res))
    } catch (err) {
      toast.error(err?.description ?? 'Error al realizar la transferencia')
    } finally {
      dispatch(setLoading(false))
    }
  }
}
