import {
  setLoading,
  setCustomersList,
  setSelectedCustomer
} from '@store/customers'
import { setSelectedTicket } from '@store/parking-lot/tickets'
import {
  customersAutocomplete,
  parkingLotTicketAvailability,
  addCustomer
} from '@services/customers'

import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import {
  addCustomerWithoutPasswordService,
  debitCustomerService,
  deleteCustomerExtensionService,
  deleteVehicleService,
  handlePromotionalTicketService,
  rechargeWalletService,
  resetCustomerPasswordServices,
  updateCustomerServices,
  updateVehicleService
} from '../../services/customers'
import { searchUserService } from '../../services/parking-lot/coupons'
import { setSelectedRecharge, setSelectedVehicle } from './slice'
import { setSelectedUser } from '../users/pay-event'
import { doc } from 'prettier'
import { getUserByDocumentService } from '../../services/parking-lot/tickets'
import { setSelectedUserExtension } from '../users/extensions/slice'
import { errors } from '@src/assets/constants/text'

export const getCustomerByDocument = (document) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await getUserByDocumentService(document)
      if (!ok) throw error

      const username = res?.data?.username
      if (!username) {
        toast.error('Usuario no encontrado')
        throw new Error('Usuario no encontrado')
      }

      dispatch(setSelectedCustomer(res?.data))
      dispatch(setSelectedUser(res?.data))

      toast.success('Usuario encontrado')

      return res
    } catch (error) {
      throw error
    }
  }
}

export const handlePromotionalTicket = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedCustomer } = getState().customers

      const sendBody = {
        ...body,
        customers: [selectedCustomer._id]
      }

      const { ok, res, error } = await handlePromotionalTicketService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Asignación exitosa')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const debitCustomerAmount = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedCustomer } = getState().customers

      const sendBody = {
        user_id: selectedCustomer._id,
        ...body
      }

      const { ok, res, error } = await debitCustomerService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Débito exitoso')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const resetCustomerPassword = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCustomer } = getState().customers

      const { ok, res, error } = await resetCustomerPasswordServices({
        user_id: selectedCustomer._id
      })

      if (!ok) throw error
      toast.success(res.message ?? 'Contraseña restablecida exitosamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCustomer = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCustomer } = getState().customers

      const sendBody = {
        ...body,
        user_id: selectedCustomer._id
      }

      const { ok, res, error } = await updateCustomerServices(sendBody)

      if (!ok) throw error
      dispatch(setSelectedCustomer(res?.user ?? null))
      toast.success(res.message ?? 'Usuario actualizado exitosamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCustomerStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCustomer } = getState().customers

      const body = {
        user_id: selectedCustomer._id,
        status: selectedCustomer.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = await updateCustomerServices(body)

      if (!ok) throw error
      dispatch(setSelectedCustomer(res?.user ?? null))
      toast.success(res.message ?? 'Usuario actualizado exitosamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateUserVehicleStatus = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedVehicle } = getState().customers
      const body = {
        user_id: selectedVehicle.pd_user,
        vehicle_id: selectedVehicle._id,
        is_active: !selectedVehicle.is_active
      }

      dispatch(setLoading(true))

      const { ok, res, error } = await updateVehicleService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Vehículo actualizado exitosamente')
      dispatch(setSelectedVehicle(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
export const deleteUserVehicle = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedVehicle } = getState().customers
      const body = {
        user_id: selectedVehicle.pd_user,
        vehicle_id: selectedVehicle._id
      }

      dispatch(setLoading(true))

      const { ok, res, error } = await deleteVehicleService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Vehículo eliminado exitosamente')
      dispatch(setSelectedVehicle(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateUserVehicle = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await updateVehicleService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Vehículo exitosamente')

      // dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const rechargeWallet = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedRecharge } = getState().customers
      const { selectedUserExtension } = getState().extensions

      const amount = selectedRecharge.amount
        .replace(/\./g, '')
        .replace(/,/g, '.')

      const sendBody = {
        ...selectedRecharge,
        amount,
        username: selectedUserExtension.username,
        customer: selectedUserExtension._id
      }

      const { ok, res, error } = await rechargeWalletService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Recarga exitosa')

      const { res: response } = await searchUserService(
        `uuid${selectedUserExtension._id}`
      )

      dispatch(setCustomersList(response.users))
      dispatch(setSelectedUserExtension(response.users[0]))

      // dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setSelectedRecharge(null))
      dispatch(setLoading(false))
    }
  }
}

export const addNewCustomer = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await addCustomer(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Usuario creado exitosamente')

      // dispatch(getCoupons(isPrivate))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
export const addNewCustomerWithoutPassword = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const docNumber = body?.doc?.slice(1) ?? ''

      const { ok, res, error } = await addCustomerWithoutPasswordService(body)

      if (!ok) throw error
      dispatch(getCustomerByDocument(docNumber))
    } catch (err) {
      toast.error(err?.extra.message ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCustomers = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      dispatch(setCustomersList([]))
      
      const searchParam = getState().customers.autocomplete

      if (!searchParam || searchParam.length < 4) {
        toast.error('Ingrese un valor valido de búsqueda')
        return
      }

      const { ok, res } = await customersAutocomplete({
        searchParam,
        show: 20,
        page: 1
      })

      if (!ok) toast.error(err?.description ?? errors.GENERAL_ERR)
      if (ok) dispatch(setCustomersList(res.users))
      if (res.users.length === 0) toast.error('No se encontraron resultados')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const checkParkingLotTicketAvailability = (customer) => {
  return async (dispatch) => {
    const { ok, res } = await parkingLotTicketAvailability(customer.username)
    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={
            res?.error?.id === 'ANOTHER_TICKET_IS_READY'
              ? `@${customer.username} ya tiene un ticket activo`
              : res?.error?.description ||
                'Ocurrió un error al verificar si el usuario ya tiene un ticket activo'
          }
          type={'danger'}
        />
      ))
      //dispatch(setSelectedCustomer(null))
      return
    }

    setTimeout(() => {
      dispatch(setSelectedCustomer(customer))
      dispatch(setSelectedTicket(null))
    }, 50)
  }
}
