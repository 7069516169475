import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const parkingMeterSlice = createSlice({
  name: 'parkingMeters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setLocationFilters: (state, action) => {
      state.locationFilters = action.payload
    },
    setEntities: (state, action) => {
      state.entitiesOptions = action.payload
    },
    setAreas: (state, action) => {
      state.areaOptions = action.payload
    },
    setZones: (state, action) => {
      state.zonesOptions = action.payload
    },
    setSubzones: (state, action) => {
      state.subzonesOptions = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    setSelectedTransactions: (state, action) => {
      state.selectedTransaction = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setCommerce: (state, action) => {
      state.entity_commerces_ids = action.payload
    },
    setCommerceOptions: (state, action) => {
      state.commercesOptions = action.payload
    }
  }
})

export const {
  setFilters,
  setLocationFilters,
  setEntities,
  setAreas,
  setZones,
  setSubzones,
  setLoading,
  setNextPageExists,
  setData,
  setTransactions,
  setSelectedTransactions,
  setCommerce,
  setCommerceOptions
} = parkingMeterSlice.actions

export const parkingMeters = parkingMeterSlice.reducer
