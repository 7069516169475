export const deafultFilter = {
  filterOptions: {
    fiscal_name: ''
  },
  pagination: {
    page: 1,
    limit: 10
  }
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

export const defaultValues = {
  username: '',
  email: '',
  phone: '',
  pre_username: '',
  custom_name: '',
  local_number: '',
  doc_type: 'V',
  doc_id: ''
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar entidad' },
  update: { action: 'update', message: 'Actualizar entidad' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  delete: { action: 'deleted', message: 'eliminar' }
}

const defaultSubzoneTypeOptions = [
  { value: 'residential', label: 'Residencial' },
  { value: 'commercial', label: 'Comercial' }
]

const defaultSubzoneBillingType = [
  { value: 'fixed-rate', label: 'Tarifa plana' },
  { value: 'hourly', label: 'Tarifa por hora' }
]

const handleErrors = {
  username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    pattern: {
      value: /^\S+@\S+$/i,
      message: 'Correo electrónico inválido'
    }
  },
  phone: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  pre_username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  custom_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  local_number: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  doc_id: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const initialState = {
  loading: false,
  entitiesOptions: [],
  defaultValues,
  data: [],
  filters: deafultFilter,
  selectedEntity: null,
  nextPageExists: false,
  actions: defaultActions,
  handleErrors,
  subzoneTypeOptions: defaultSubzoneTypeOptions,
  subzoneBillingType: defaultSubzoneBillingType
}

export default initialState
