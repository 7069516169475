import { apiMethods } from '@services/methods'
import { baseV2URL, baseV1URL } from '@configs/apis'

const endpoints = {
  autocomplete: `${baseV2URL}/users/autocomplete`,
  createCustomer: `${baseV1URL}/commerces/signup/user`,
  createCustomerWithoutPassword: `${baseV2URL}/superadmin/user/signup`,
  parkingLotTicketAvailability: `${baseV2URL}/users/tickets/availability`,
  rechargeWallet: `${baseV2URL}/recharges`,
  updateVehicle: `${baseV2URL}/superadmin/users/vehicles/update`,
  deleteVehicle: `${baseV2URL}/superadmin/users/vehicles/delete`,
  updateCustomer: `${baseV2URL}/superadmin/users/update`,
  resetPasswordCustomer: `${baseV2URL}/superadmin/users/reset-password`,
  debit: `${baseV2URL}/superadmin/transactions/discount`,
  promotionalTicket: `${baseV2URL}/superadmin/users/ticket-promotional`
}

export const handlePromotionalTicketService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.promotionalTicket,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const debitCustomerService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.debit,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const resetCustomerPasswordServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.resetPasswordCustomer,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateCustomerServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.updateCustomer,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteVehicleService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.deleteVehicle,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const updateVehicleService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.updateVehicle,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const rechargeWalletService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.rechargeWallet,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const addCustomerWithoutPasswordService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createCustomerWithoutPassword,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const customersAutocomplete = async ({
  searchParam,
  show = 20,
  page = 1
}) => {
  try {
    const url = `${endpoints.autocomplete}?${new URLSearchParams({
      input: searchParam,
      show,
      page
    })}`

    const { ok, res } = await apiMethods.authGetFetch(url)
    return { ok, res }
  } catch (error) {
    return error
  }
}
export const parkingLotTicketAvailability = async (username) => {
  const url = `${endpoints.parkingLotTicketAvailability}?${new URLSearchParams({
    username
  })}`
  const { ok, res } = await apiMethods.authGetFetch(url)
  return { ok, res }
}

export const addCustomer = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createCustomer,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
