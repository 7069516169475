import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  setEntitiesOptions,
  setEntity,
  setLoading,
  setNextPageExists,
  setSelectedEntity
} from './'
import {
  createEntity,
  getEntitiesList,
  updateEntityService
} from '../../../services/parking-meters'

export const getEntities = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().entities.filters

      const { ok, res, error } = await getEntitiesList(filters)

      if (!ok) throw error

      dispatch(setEntity(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getEntitiesOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().entities.filters
      const { pagination, ...filtersWithOutPagination } = filters

      const { ok, res, error } = await getEntitiesList(filtersWithOutPagination)

      if (!ok) throw error

      const entitiesOptions = res.data.items.map((entity) => ({
        value: entity._id,
        label: entity.name
      }))

      dispatch(setEntitiesOptions(entitiesOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addEntity = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createEntity(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Entidad creada correctamente')

      dispatch(getEntities())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateEntity = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await updateEntityService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Entidad actualizada correctamente')

      dispatch(getEntities())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateEntityStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedEntity = getState().entities.selectedEntity

      const sendBody = {
        ...selectedEntity,
        status: selectedEntity.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = await updateEntityService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Entidad actualizada correctamente')

      dispatch(getEntities())
      dispatch(setSelectedEntity(res?.data?.updatedEntity || null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteEntity = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedEntity = getState().entities.selectedEntity

      const sendBody = {
        ...selectedEntity,
        status: 'deleted'
      }

      const { ok, res, error } = await updateEntityService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Entidad eliminada correctamente')

      dispatch(getEntities())
      dispatch(setSelectedEntity(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

