import { getCommerceData } from '@utils'

const commerce = getCommerceData()
const building = commerce?.parking?.building._id ?? ''

const creationRuleOptions = [
  { value: 'all_tickets', label: 'Todos', isFixed: true },
  {
    value: 'digitized_tickets',
    label: 'Digitalizados',
    isFixed: true
  },
  { value: 'normal_tickets', label: 'Creados', isFixed: true }
]

const statusOptions = [
  { value: 'ready', label: 'Por Pagar', isFixed: true, color: '#365af6' },
  { value: 'paid', label: 'Pagados', isFixed: true, color: '#365af6' },
  { value: 'closed', label: 'Cerrados', isFixed: true, color: '#365af6' },
  {
    value: 'closed_superadmin',
    label: 'Habilitados por ATC',
    isFixed: true,
    color: '#365af6'
  },
  { value: 'discarded', label: 'Descartados', isFixed: true, color: '#365af6' }
]

const typesOptions = [
  { value: 'regular', label: 'Regulares', isFixed: true, color: '#365af6' },
  { value: 'gift', label: 'Especiales', isFixed: true, color: '#365af6' },
  { value: 'premium', label: 'Premium', isFixed: true, color: '#365af6' },
  {
    value: 'monthly_premium',
    label: 'Puestos Fijos',
    isFixed: true,
    color: '#365af6'
  }
]

export const initialPagination = {
  page: 0,
  show: 8,
  nextPageExists: false
}

const dateRange = {
  fromDate: '',
  endDate: ''
}

const searchForm = {
  status: [],
  types: [],
  creationRule: 'all_tickets',
  newUser: false
}

const actions = {
  add: { action: 'add', message: 'Pagar ticket fisico' }
}

export const initialState = {
  loading: false,
  ticketOptionLoading: false,
  selectedTicket: null,
  selectedAccess: null,
  ticketsList: [],
  building,
  searchForm,
  pagination: { ...initialPagination },
  dateRange,
  searchParam: '',
  searchBy: 'date',
  statusOptions,
  typesOptions,
  creationRuleOptions,
  commerce: '',
  ticketAmount: 0,
  selectedPhysicalBuilding: null,
  selectedPhysicalTicket: null,

  actions,
  physicalTicketBuildingsOptions: []
}
