const defaultActions = {
  pay: { action: 'add', message: 'pagar el evento' },
  recharge: { action: 'recharge', message: 'recargar' },
  changeUser: { action: 'changeUser', message: 'Cambiar receptor' }
}

const eventsOptions = []

const initialState = {
  loading: false,
  data: eventsOptions,
  selectedEvent: null,
  selectedStock: null,
  stockOptions: [],
  selectedUser: null,
  selectedReceptor: null,
  extraFields: [],
  formExtraFields: {},

  validateEventDetails: null,

  actions: defaultActions,
  exchangeRate: 0
}

export default initialState
