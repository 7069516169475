import axios from 'axios'
import { getOperatorData } from '@utils'

export const apiCallFetch = async (url, options) => {
  try {
    const response = await fetch(url, options)
    const res = await response.json()

    if (res.error) throw res.error
    if (!response.ok) throw res
    return {
      ok: true,
      res
    }
  } catch (error) {
    return {
      ok: false,
      error
    }
  }
}

export const apiCallFetchLambda = async (url, options) => {
  try {
    const response = await fetch(url, options)
    const res = await response.json()

    if (res.error) throw res.error
    if (res?.code === 'BAD_REQUEST') throw res
    if (!response.ok) throw res
    return {
      ok: true,
      res
    }
  } catch (error) {
    return {
      ok: false,
      error
    }
  }
}

export const apiCall = async (options) => {
  try {
    const response = await axios(options)
    return {
      ok: true,
      res: response.data
    }
  } catch (error) {
    return {
      ok: false,
      res: error?.response?.data?.error?.description ?? error,
      extra: error?.response?.data?.error?.extra ?? null
    }
  }
}

export const noAuthGet = ({ url }) => {
  const options = {
    url,
    method: 'GET'
  }
  return apiCall(options)
}
export const noAuthPost = async ({ url, body }) => {
  const options = {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  }
  return apiCall(options)
}

export const noAuthPostFetch = async ({ url, body }) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }
  return apiCallFetch(url, options)
}

export const authGet = async ({ url, params }) => {
  const { token } = getOperatorData()
  const options = {
    url,
    method: 'GET',
    headers: {
      'x-access-token': token,
      'Access-Control-Allow-Origin': '*'
    }
  }
  if (params) {
    options.params = params
  }
  return apiCall(options)
}
export const authPost = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    data: body
  }

  return apiCall(options)
}

export const authPostTemp = async ({ url, body }) => {
  const { token } = getOperatorData()

  const options = {
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: return value to token
      'x-access-token': token
    },
    data: body
  }

  return apiCall(options)
}

export const authPostTemp2 = async ({ url, body }) => {
  const { token } = getOperatorData()

  const options = {
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    data: body
  }

  return apiCall(options)
}

export const authDelete = async ({ url, body }) => {
  const { token } = getOperatorData()

  const options = {
    url,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    }
  }

  return apiCall(options)
}

export const authPut = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    url,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    data: body
  }

  return apiCall(options)
}

export const authPostUser = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'x-access-token': token
    },
    data: body
  }

  const t = await axios(options)
}

export const authPatch = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    data: body
  }

  return apiCall(options)
}

export const authPostFetch = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(body)
  }

  return apiCallFetch(url, options)
}

export const authPutFetch = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(body)
  }

  return apiCallFetch(url, options)
}

export const authPostFetchLambda = async ({ url, body }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-key': process.env.REACT_APP_TKTFISICO_ADMIN_KEY,
      'x-access-secret': process.env.REACT_APP_TKTFISICO_SECRET
    },
    body: JSON.stringify(body)
  }

  return apiCallFetchLambda(url, requestOptions)
}

export const authPatchFetch = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(body)
  }

  return apiCallFetch(url, options)
}
export const noAuthGetFetch = async (url) => {
  const options = {
    method: 'GET'
  }

  return apiCallFetch(url, options)
}
export const authGetFetch = async (url) => {
  const { token } = getOperatorData()
  const options = {
    method: 'GET',
    headers: {
      'x-access-token': token
    }
  }

  return apiCallFetch(url, options)
}

export const authDeleteFetch = async ({ url, body }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(body)
  }

  return apiCallFetch(url, options)
}

export const authPostFetchFormData = async ({ url, formData }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'POST',
    headers: {
      'x-access-token': token
    },
    body: formData
  }

  return apiCallFetch(url, options)
}

export const authPutFetchFormData = async ({ url, formData }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'PUT',
    headers: {
      'x-access-token': token
    },
    body: formData
  }

  return apiCallFetch(url, options)
}

export const authPatchFetchFormData = async ({ url, formData }) => {
  const { token } = getOperatorData()
  const options = {
    method: 'PATCH',
    headers: {
      'x-access-token': token
    },
    body: formData
  }

  return apiCallFetch(url, options)
}

export const authPutAxios = async ({ url, formData }) => {
  const { token } = getOperatorData()
  const options = {
    url,
    method: 'POST',
    headers: {
      'x-access-token': token
    },
    body: formData
  }

  return apiCall(options)
}
