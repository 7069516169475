export const defaultFilter = {
  reference: '',
  date_from: null,
  date_to: null,
  status: ['pending', 'approved', 'rejected'],
  page: 1,
  limit: 10
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar extensión' },
  approved: { action: 'approved', message: 'Esta seguro de aprobar la transacción' },
  rejected: { action: 'rejected', message: 'Esta seguro de rechazar la transacción' },
  deferred: { action: 'deferred', message: 'Esta seguro de diferir la transacción' }
}

const transfersOptions = {
  approved: { label: 'Aprobado', color: 'light-success' },
  pending: { label: 'Pendiente', color: 'light-warning' },
  deferred: { label: 'Diferido', color: 'light-danger' },
  rejected: { label: 'Rechazado', color: 'light-danger' }
}

const initialState = {
  loading: false,
  data: [],
  selectedTransfer: null,

  filters: defaultFilter,
  transfersOptions,
  nextPageExists: false,
  actions: defaultActions
}

export default initialState
