const deafultFilter = {
  filterOptions: {},
  pagination: {
    limit: 11,
    page: 1
  }
}

export const initialState = {
  loading: false,
  data: [],
  filters: deafultFilter,
  nextPageExists: false,

  methodOptions: [
    { label: 'App', value: 'app' },
    { label: 'Tag', value: 'tag' }
  ],

  isAddBundleOpen: false,

  selectedBundle: null,
  tollRates: []
}
