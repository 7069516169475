import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'towCompany',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTowCompany: (state, action) => {
      state.data = action.payload
    },
    setTowCompanyFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedTowCompany: (state, action) => {
      state.selectedTowCompany = action.payload
    }
  }
})

export const {
  setTowCompanyFilters,
  setLoading,
  setTowCompany,
  setNextPageExists,
  setSelectedTowCompany
} = slice.actions

export const towCompany = slice.reducer
