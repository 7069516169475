import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'advertisingDashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotalMetrics: (state, action) => {
      state.totalMetrics = action.payload
    },
    setAdvertising: (state, action) => {
      state.data = action.payload
    },

    setAdvertisingOptions: (state, action) => {
      state.advertisingOptions = action.payload
    },

    setAdvertisingFilters: (state, action) => {
      state.filters = action.payload
    },
    setTemporalXAxis: (state, actions) => {
      state.temporalXAxis = actions.payload
    },
    setTemporalYAxisTotal: (state, actions) => {
      state.temporalYAxisTotal = actions.payload
    },
    setTemporalYAxisClicks: (state, actions) => {
      state.temporalYAxisClicks = actions.payload
    },
    setTemporalYAxisViews: (state, actions) => {
      state.temporalYAxisViews = actions.payload
    },
    setTemporalYAxisReactions: (state, actions) => {
      state.temporalYAxisReactions = actions.payload
    },
    setComparativeXAxis: (state, actions) => {
      state.comparativeXAxis = actions.payload
    },
    setComparativeYAxisTotal: (state, actions) => {
      state.comparativeYAxisTotal = actions.payload
    },
    setComparativeYAxisClicks: (state, actions) => {
      state.comparativeYAxisClicks = actions.payload
    },
    setComparativeYAxisViews: (state, actions) => {
      state.comparativeYAxisViews = actions.payload
    },
    setComparativeYAxisReactions: (state, actions) => {
      state.comparativeYAxisReactions = actions.payload
    }
  }
})

export const {
  setLoading,
  setTemporalXAxis,
  setTotalMetrics,
  setAdvertisingFilters,
  setTemporalYAxisTotal,
  setTemporalYAxisClicks,
  setTemporalYAxisViews,
  setTemporalYAxisReactions,
  setComparativeXAxis,
  setComparativeYAxisTotal,
  setComparativeYAxisClicks,
  setComparativeYAxisViews,
  setComparativeYAxisReactions
} = slice.actions

export const advertisingDashboard = slice.reducer
