import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setBilling: (state, action) => {
      state.data = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setBillingFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setCurrentExchangeRate: (state, action) => {
      state.currentExchangeRate = action.payload
    },
    setSelectedBilling: (state, action) => {
      state.selectedBilling = action.payload
    },
    setCurrentBillingForm: (state, action) => {
      state.currentBillingForm = action.payload
    },
    setPreviewBilling: (state, action) => {
      state.previewBilling = action.payload
    }
  }
})

export const {
  setBilling,
  setLoading,
  setBillingFilters,
  setNextPageExists,
  setSelectedBilling,
  setCurrentExchangeRate,
  setCurrentBillingForm,
  setPreviewBilling
} = slice.actions

export const billing = slice.reducer
