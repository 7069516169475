import { useState, useEffect } from 'react'

const useResponsive = (limit = 768) => {
  const [isMobile, setIsMobile] = useState(false)
  const setViewType = () => {
    const documentElement = document.documentElement
    const width = window.innerWidth || documentElement.clientWidth
    setIsMobile(width <= limit)
  }

  useEffect(() => {
    window.addEventListener('resize', setViewType)
    setViewType()
    return () => {
      window.removeEventListener('resize', setViewType)
    }
  }, [])

  return { isMobile }
}

export default useResponsive
