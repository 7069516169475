// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const reportPenaltySlice = createSlice({
  name: 'reportPenaltyReducer',
  initialState,
  reducers: {
    setLoadingReports: (state, action) => {
      state.loading = action.payload
    },
    setReportsData: (state, action) => {
      state.data = action.payload
    },

    setSelectedReports: (state, action) => {
      state.selectedReport = action.payload
    },
    setNextPageExistsReports: (state, action) => {
      state.nextPageExists = action.payload
    },
    setFiltersReports: (state, action) => {
      state.filters = action.payload
    }
  }
})

export const {
  setLoadingReports,
  setReportsData,
  setSelectedReports,
  setNextPageExistsReports,
  setFiltersReports
} = reportPenaltySlice.actions

export const reportPenaltyReducer = reportPenaltySlice.reducer
