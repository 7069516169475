import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'usersDashboard',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setComparativeData: (state, action) => {
      state.comparativeData = action.payload
    },
    setActiveUsers: (state, action) => {
      state.activeUsers = action.payload
    },
    setNewUsers: (state, action) => {
      state.newUsers = action.payload
    },
    setUserHistory: (state, action) => {
      state.userHistory = action.payload
    },
    setUsersType: (state, action) => {
      state.usersType = action.payload
    },

    setUsersDashboardFilters: (state, action) => {
      state.filters = action.payload
    }
  }
})

export const {
  setLoading,
  setData,
  setUsersDashboardFilters,
  setComparativeData,
  setActiveUsers,
  setNewUsers,
  setUserHistory,
  setUsersType
} = slice.actions

export const usersDashboard = slice.reducer
