import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export const defaultFilter = {
  pagination: {
    limit: 10,
    page: 1
  }
}

const defaultValues = {
  pre_username: '',
  username: '',
  custom_name: '',
  name: '',
  short_name: '',
  email: '',
  doc_type: 'J',
  doc_id: '',
  phone: '',
  local_number: '',
  address: '',
  category: '',
  building_id: ''
}

const defaultActions = {
  add: { action: 'add', message: 'Crear cuenta digital' },
  updateFee: { action: 'updateFee', message: 'Actualizar cuenta digital' },
  update: { action: 'update', message: 'Actualizar cuenta digital' },
  delete: { action: 'deleted', message: 'Eliminar cuenta digital' }
}

const statusOptions = {
  pending: { label: 'Pendiente', color: 'light-warning' },
  paid: { label: 'Pagada', color: 'light-success' }
}
const initialState = {
  loading: false,
  defaultValues,
  statusOptions,
  filters: defaultFilter,
  actions: defaultActions,
  selectedDigitalAccount: null,
  digitalAccountList: []
}

export default initialState
