import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd03 } from '@configs/apis'

const endpoints = {
  getTransactions: `${baseV2URL}/superadmin/recharges/mobilepaid`,
  getTransactionsDetail: `${baseV2URL}/superadmin/recharges/mobilepaid/details`
}

export const getMobilePaidTransactions = async (filters) => {
  try {
    const { page, show, reference, amount, phone, status } = filters

    const url = `${endpoints.getTransactions}?page=${page}&show=${show}&reference=${reference}&amount=${amount}&phone=${phone}&status=${status}`
    const response = await apiMethods.authGetFetch(url)
    return response
  } catch (error) {
    return error
  }
}

export const getTransactionsDetailServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getTransactionsDetail}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
