import { apiMethods } from '@services/methods'
import { baseV2URL, reportsURL } from '@configs/apis'

const endpoints = {
  bills: `${baseV2URL}/bills`,
  generateExcel: `${reportsURL}/tickets-bill-commerce`
}

export const invoices = async ({
  fromDate: from_date,
  endDate: end_date,
  page: skip,
  show: limit,
  building,
  commerce: commerce_id
}) => {
  try {
    const url = endpoints.bills
    const body = {
      from_date,
      end_date,
      skip: skip * limit,
      limit,
      building,
      commerce_id
    }
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const invoiceById = async ({ id, commerce: commerce_id }) => {
  try {
    const url = endpoints.bills
    const body = {
      id,
      commerce_id
    }
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}

export const generateExcel = async (body) => {
  try {
    const url = endpoints.generateExcel
    const { ok, res, error } = await apiMethods.authPostFetch({ url, body })
    return { ok, res, error }
  } catch (error) {
    return error
  }
}
