// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const bundleSlices = createSlice({
  name: 'bundles',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedBundle: (state, action) => {
      state.selectedBundle = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },

    setBundleData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setLoading,
  setBundleData,
  setPagination,
  setSelectedBundle,
  setNextPageExists,
  setFilters
} = bundleSlices.actions

export const bundles = bundleSlices.reducer
