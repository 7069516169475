import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd03, baseV1URL } from '@configs/apis'

const endpoints = {
  createAdvertising: `${micropd03}/superadmin/advertisements`,
  getAdvertising: `${micropd03}/superadmin/advertisements/list`,
  deleteAdvertising: `${micropd03}/superadmin/advertisements`,
  getService: `${baseV1URL}/customer/services/information`
}

export const getServices = async () => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.getService)
    return response
  } catch (error) {
    return error
  }
}

export const createAdvertisingService = async (body) => {
  try {
    const response = await apiMethods.authPostFetchFormData({
      url: endpoints.createAdvertising,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getAdvertisingList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getAdvertising,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteAdvertisingService = async (id) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: `${endpoints.deleteAdvertising}/${id}`
    })

    return response
  } catch (error) {
    return error
  }
}

export const updateAdvertisingService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetchFormData({
      url: endpoints.createAdvertising,
      formData: body
    })
    return response
  } catch (error) {
    return error
  }
}

// export const getCouponsUsesHistoryList = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetch({
//       url: endpoints.getCouponsUsesHistoryList,
//       body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const searchUserService = async (body) => {
//   try {
//     const response = await apiMethods.authGetFetch(
//       `${endpoints.searchUser}?input=${body}&show=0&page=100`
//     )
//     return response
//   } catch (error) {
//     return error
//   }
// }
