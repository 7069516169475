import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd02 } from '@configs/apis'

const endpoints = {
  getTransfers: `${micropd02}/superadmin/bank-movement/list`,
  changeTransferStatus: `${micropd02}/superadmin/bank-movement/change-status`
}

export const getTransfersService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getTransfers}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const changeTransferStatusService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.changeTransferStatus}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
