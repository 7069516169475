import { getCommerceData } from '@utils'

const commerce = getCommerceData()
const building = commerce?.parking?.building._id ?? ''

const pagination = {
  page: 1,
  show: 20
}
const dateRange = {
  fromDate: '',
  endDate: ''
}
const initialState = {
  selectedCommerce: null,
  commerces: [],
  loading: false,
  loadingTickets: false,
  selectedTicket: null,
  building,
  searchParam: '',
  pagination,
  dateRange
}

export default initialState
