import { getCommerceData } from '@utils'

const commerce = getCommerceData()

const deafultFilter = {
  concession_commerce_id: commerce?.commerce_associated?._id,
  pagination: {
    page: 1,
    limit: 8
  },
  sorting: {
    sortBy: 'created_at',
    sortOrder: 'desc'
  },
  filterOptions: {
    autocomplete: '',
    amount: '',
    fromDate: '',
    untilDate: '',
    type: []
  }
}

export const initialState = {
  loading: false,
  selectedBundle: null,
  data: [],
  vehiclesTypes: [],
  filters: deafultFilter,

  nextPageExists: false
  // commerces,
  // isSuperadmin
}
