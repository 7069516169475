export const defaultFilter = {
  input: '',
  building: '',
  status: [],
  limit: 10,
  page: 1
}

export const statusOptions = {
  active: { label: 'Activo', color: 'light-success' },
  inactive: { label: 'Inactivo', color: 'light-secondary' },
  deleted: { label: 'Inactivo', color: 'light-secondary' }
}

const defaultValues = {
  posname: '',
  password: '',
  building: '',
  serial_pos: '',
  confirm_password: ''
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar sspos' },
  update: { action: 'update', message: 'Actualizar sspos' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  resetPassword: { action: 'resetPassword', message: 'restablecer' },
  changePassword: { action: 'changePassword', message: 'Cambiar contraseña' },

  delete: { action: 'deleted', message: 'eliminar' }
}

const defaultSubzoneTypeOptions = [
  { value: 'residential', label: 'Residencial' },
  { value: 'commercial', label: 'Comercial' }
]

const defaultSubzoneBillingType = [
  { value: 'fixed-rate', label: 'Tarifa plana' },
  { value: 'hourly', label: 'Tarifa por hora' }
]

const handleErrors = {
  code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  subzone_code: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  short_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  display_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  entity_commerce_id: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  description: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  entity_percentage: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El porcentaje debe ser mayor a 0'
    },
    max: {
      value: 100,
      message: 'El porcentaje debe ser menor o igual a 100'
    },
    validate: (value) => !isNaN(value) || 'Por favor, introduce un número'
  },
  pagodirecto_percentage: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El porcentaje debe ser mayor a 0'
    },
    max: {
      value: 100,
      message: 'El porcentaje debe ser menor o igual a 100'
    },
    validate: (value) => !isNaN(value) || 'Por favor, introduce un número'
  },
  concession_percentage: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    min: {
      value: 0,
      message: 'El porcentaje debe ser mayor a 0'
    },
    max: {
      value: 100,
      message: 'El porcentaje debe ser menor o igual a 100'
    },
    validate: (value) => !isNaN(value) || 'Por favor, introduce un número'
  }
}

const initialState = {
  loading: false,
  areasOptions: [],
  loadingUsers: false,

  data: [],

  filters: defaultFilter,

  selectedSspos: null,

  nextPageExists: false,
  actions: defaultActions,

  handleErrors,
  defaultValues
}

export default initialState
