import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'commerceDigitalAccount',
  initialState,
  reducers: {
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedDigitalAccount: (state, action) => {
      state.selectedDigitalAccount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setDigitalAccountList: (state, action) => {
      state.digitalAccountList = action.payload
    }
  }
})

export const {
  setNextPageExists,
  setSelectedDigitalAccount,
  setDigitalAccountList,
  setLoading
} = slice.actions

export const commerceDigitalAccount = slice.reducer
