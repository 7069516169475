import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'plBundles',
  initialState,
  reducers: {
    setSelectedBundle: (state, action) => {
      state.selectedBundle = action.payload
    },
    setSelectedHistoryBundle: (state, action) => {
      state.selectedHistoryBundle = action.payload
    },
    setBundles: (state, action) => {
      state.bundles = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setLoadingDetails: (state, action) => {
      state.loadingDetails = action.payload
    },
    setInvoicesLoading: (state, action) => {
      state.loadingInvoices = action.payload
    },
    setActivationLoading: (state, action) => {
      state.activationLoading = action.payload
    },
    setHistoryLoading: (state, action) => {
      state.historyLoading = action.payload
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setBundlesTitles: (state, action) => {
      state.bundlesTitles = action.payload
    },
    setTitlesSelected: (state, action) => {
      state.titlesSelected = action.payload
    },
    setBundlesHistory: (state, action) => {
      state.bundlesHistory = action.payload
    },
    setSearchParam: (state, action) => {
      state.searchParam = action.payload
    },
    setSearchInvoicesBy: (state, action) => {
      state.searchInvoicesBy = action.payload
    },
    setSelectedInvoiceHistoryBundle: (state, action) => {
      state.selectedInvoiceHistoryBundle = action.payload
    },
    setBundlesInvoicesHistory: (state, action) => {
      state.bundlesInvoicesHistory = action.payload
    },
    setInvoicesStatus: (state, action) => {
      state.statusInvoices = action.payload
    }
  }
})

export const {
  setSelectedBundle,
  setBundles,
  setLoading,
  setActivationLoading,
  setSearchBy,
  setBundlesTitles,
  setSearchParam,
  setDateRange,
  setBundlesHistory,
  setHistoryLoading,
  setTitlesSelected,
  setPagination,
  setLoadingDetails,
  setSelectedHistoryBundle,
  setSearchInvoicesBy,
  setSelectedInvoiceHistoryBundle,
  setInvoicesLoading,
  setBundlesInvoicesHistory,
  setInvoicesStatus
} = slice.actions

export const plBundles = slice.reducer
