import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'enableRecharge',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setEnableRecharge: (state, action) => {
      state.data = action.payload
    },
    setSelectedEnableRecharge: (state, action) => {
      state.selectedEnableRecharge = action.payload
    },
    setEnableRechargeFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    }
  }
})

export const {
  setLoading,
  setEnableRecharge,
  setSelectedEnableRecharge,
  setEnableRechargeFilters,
  setNextPageExists
} = slice.actions

export const enableRecharge = slice.reducer
