import {Coffee, X, CheckCircle, AlertTriangle, XSquare} from "react-feather"
import toast from "react-hot-toast"
import Avatar from '@components/avatar'

export const BasicToastContent = ({ t, message, type }) => {

    const icons = {
        success: <CheckCircle size={12} />,
        warning: <AlertTriangle size={12} />,
        danger: <XSquare size={12} />
    }
    const defaultIcon = <Coffee size={12} />
    const icon = icons[type] || defaultIcon
    return (
        <div className='d-flex'>
            <div className='me-1'>
                <Avatar size='sm' color={type} icon={icon} />
            </div>
            <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <p>{message}</p>
                    <X
                        size={12}
                        className='cursor-pointer'
                        onClick={() => toast.dismiss(t.id)}
                    />
                </div>
            </div>
        </div>
    )
}