import { getCommerceData } from '@utils'

const commerce = getCommerceData()
const building = commerce?.parking?.building._id ?? ''

export const initialPagination = {
  page: 0,
  show: 8,
  nextPageExists: false
}

const dateRange = {
  fromDate: '',
  endDate: ''
}

const defaultFilters = {
  buildings_ids: [building]
}

const initialState = {
  loading: false,
  filters: defaultFilters,
  xAxisData: [],
  currentPeriodData: [],
  previusPeriodData: [],
  xTemporalAxisData: [],
  temporalPeriodData: [],
  temporalPeriodDataAmount: [],
  lastTickets: [],
  closingHistory: [],
  selectedClosingHistory: null,

  totalAmount: 0,
  totalTickets: 0,
  previousAmount: 0,
  previousTickets: 0,

  daysOptions: [
    { label: 'Día', value: 'daily' },
    { label: 'Semana', value: 'weekly' },
    { label: 'Mes', value: 'monthly' },
    { label: 'Rango de fecha', value: 'custom' }
  ]
}

export default initialState
