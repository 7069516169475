import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    }
  }
})

export const { setLoading, setParams } = slice.actions

export const reports = slice.reducer
