import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setEnableRecharge,
  setSelectedEnableRecharge
} from './slice'

import { t } from 'i18next'
import {
  getMobilePaidTransactions,
  getTransactionsDetailServices
} from '../../../services/users/mobilePaid'

import {
  enableMobilePaidService,
  enableRechargeService,
  getDollarPaymentsService,
  getRechargeService
} from '../../../services/users/enable-recharge'

export const getEnableRecharges = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().enableRecharge
      const { ok, res, error } = await getRechargeService(filters)

      if (!ok) throw error

      dispatch(setNextPageExists(res?.data?.nextPageExists ?? false))
      dispatch(setEnableRecharge(res?.recharges ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getDollarPayments = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().enableRecharge
      const sendFilters = {
        page: filters?.page,
        limit: filters?.limit
      }
      const { ok, res, error } = await getDollarPaymentsService(sendFilters)

      if (!ok) throw error

      dispatch(setNextPageExists(res?.data?.nextPageExists ?? false))
      dispatch(setEnableRecharge(res?.data?.dollar_payments ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const enableMobilePhone = (username) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedEnableRecharge } = getState().enableRecharge

      const body = {
        id: selectedEnableRecharge?._id,
        payment_ref: selectedEnableRecharge?.payment_ref,
        recharge_id: selectedEnableRecharge?._id,
        username
      }

      const { ok, res, error } = await enableMobilePaidService(body)

      if (!ok) throw error

      toast.success(res?.message ?? 'La recarga fue aprobada correctamente')

      dispatch(setSelectedEnableRecharge(res?.rechargeUpdated))
      dispatch(getEnableRecharges())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const enableRecharges = (status) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedEnableRecharge } = getState().enableRecharge

      const body = {
        id: selectedEnableRecharge?._id,
        amount: selectedEnableRecharge?.amount,
        reason: '',
        status
      }

      const { ok, res, error } = await enableRechargeService(body)

      if (!ok) throw error

      toast.success(res?.message ?? 'La recarga fue aprobada correctamente')

      dispatch(setSelectedEnableRecharge(res?.recharge))
      dispatch(getEnableRecharges())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
