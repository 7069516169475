import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  setNextPageExists,
  setSubzone,
  setLoading,
  setTowCompany,
  setSelectedTowCompany
} from './'

import {
  createTowCompany,
  getTowCompanyList
} from '../../../services/parking-meters/tow-company'
import { updateEntityService } from '../../../services/parking-meters'

export const getTowCompany = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().towCompany.filters

      const { ok, res, error } = await getTowCompanyList(filters)

      if (!ok) throw error

      dispatch(setTowCompany(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addTowCompany = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createTowCompany(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía gruera creada correctamente')

      dispatch(getTowCompany())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateTowCompany = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await updateEntityService(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía actualizada correctamente')

      dispatch(getTowCompany())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateTowCompanyStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedTowCompany } = getState().towCompany

      const sendBody = {
        ...selectedTowCompany,
        status: selectedTowCompany.status === 'active' ? 'inactive' : 'active'
      }

      const { ok, res, error } = await updateEntityService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía actualizada correctamente')

      dispatch(getTowCompany())
      dispatch(setSelectedTowCompany(res?.data?.updatedEntity || null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteTowCompony = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { selectedTowCompany } = getState().towCompany

      const sendBody = {
        ...selectedTowCompany,
        status: 'deleted'
      }

      const { ok, res, error } = await updateEntityService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Compañía eliminada correctamente')

      dispatch(getTowCompany())
      dispatch(setSelectedTowCompany(null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
