import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd02 } from '@configs/apis'

const endpoints = {
  getRecharges: `${baseV2URL}/superadmin/recharges`,
  getDollarPayments: `${micropd02}/superadmin/dollar-payment/order/list`,
  enableMobilePaid: `${baseV2URL}/superadmin/recharges/pending`,
  enableRecharge: `${baseV2URL}/superadmin/recharges`
  // bodyMobilephone: {id: , payment_ref, recharge_id, username}
  // transferBody: {id: , amount, reason, status}
}

export const getRechargeService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getRecharges}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const enableRechargeService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: `${endpoints.enableRecharge}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const enableMobilePaidService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: `${endpoints.enableMobilePaid}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getDollarPaymentsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getDollarPayments}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

// export const changeTransferStatusService = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetch({
//       url: `${endpoints.changeTransferStatus}`,
//       body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }
