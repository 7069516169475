// You can customize the template with the help of this file

import { getOperatorData } from '@utils'

const operator = getOperatorData()

//Template config options
const setIcon = () => {
  const skin = JSON.parse(localStorage.getItem('skin'))
    ? JSON.parse(localStorage.getItem('skin'))
    : 'light'

  const icon = operator?.ability.find(
    (ability) => ability.subject === 'parkingMeterSystem'
  )
    ? require(`@src/assets/images/icons/PosDirectoParquimetros${skin}.png`)
        .default
    : require(`@src/assets/images/icons/Peajes${skin}.png`).default

  return icon
}

const themeConfig = {
  app: {
    appName: '',
    appLogoImage: setIcon(),
    appLogoImageResponsive: require('@src/assets/images/logo/Circle_azul.png')
      .default
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    type: 'vertical', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'top-right' // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
