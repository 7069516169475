import { lazy } from 'react'

const RegisterVehicles = lazy(() => import('@views/tolls/vehicles/register'))
const TripsHistory = lazy(() => import('@views/tolls/trips'))
const BundleHistory = lazy(() => import('@views/tolls/bundles'))
const Dashboard = lazy(() => import('@views/tolls/dashboard'))
const Reports = lazy(() => import('@views/tolls/reports'))

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const TollsRoutes = [
  {
    path: '/tolls/tolls-reports',
    element: <Reports />,
    meta: {
      actions: [
        ability.MANAGER,
        ability.SALES,
        ability.MARKETING,
        ability.FINANCE
      ],
      resources: ['Common']
    }
  },
  {
    path: '/tolls/dashboard',
    element: <Dashboard />,
    meta: {
      actions: ['concesion-level-1', 'peaje-level-1'],
      resources: ['tollSystem']
    }
  },
  {
    element: <TripsHistory />,
    path: '/tolls/tickets/list',
    meta: {
      actions: [
        'concesion-level-0',
        'peaje-level-0',
        ability.MANAGER,
        ability.ATC,
        ability.SATC
      ],
      resources: ['tollSystem', 'Common']
    }
  },

  {
    element: <BundleHistory />,
    path: '/tolls/bundles/history',
    meta: {
      actions: [
        'concesion-level-0',
        ability.MANAGER,
        ability.ATC,
        ability.SATC
      ],
      resources: ['tollSystem', 'Common']
    }
  },

  {
    path: '/tolls/vehicles/register',
    element: <RegisterVehicles />,
    meta: {
      actions: ['concesion-level-0'],
      resources: ['tollSystem']
    }
  }
]

export default TollsRoutes
