export const defaultFilter = {
  period: 'daily',
  start_date: null,
  end_date: null
}

const initialState = {
  loading: false,
  data: [],
  filters: defaultFilter,
  comparativeData: {
    yAxisCurrent: [],
    yAxisPrevious: [],
    xAxis: []
  },
  activeUsers: {
    yAxis: [],
    xAxis: []
  },
  newUsers: {
    yAxis: [],
    xAxis: []
  },
  userHistory: {
    list: [],
    currentQty: '',
    previousQty: ''
  },
  usersType: {}
}

export default initialState
