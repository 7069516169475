import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd02 } from '@configs/apis'

const endpoints = {
  historyBankTransfers: `${baseV2URL}/superadmin/bank/transfer/transactions-pd`,
  makeTransfers: `${micropd02}/superadmin/bank-transaction/transfer`
}

export const historyBankTransfersService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.historyBankTransfers,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const postTransfersService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.makeTransfers,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
