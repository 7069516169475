import { apiMethods } from '@services/methods'
import { tollsURL } from '@configs/apis'

import {
  setConcessionsList,
  setLoading,
  setNextPageExists,
  setTolls,
  setVehiclesTypes,
  setTollsList,
  setTollRates,
  setStateOptions,
  setSelectedToll,
  setSelectedAccess,
  setAccessList
} from './slice'
import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import { log, logError } from '@craco/craco/lib/logger'
import { get } from 'jquery'

const endpoints = {
  bundlesTransactions: `${tollsURL}/admin/transactions/bundles`,
  bundles: `${tollsURL}/admin/premium/bundles`,
  superadminBundles: `${tollsURL}/superadmin/premium/bundles`,
  typeVehicles: `${tollsURL}/admin/vehicles/types`,
  typeVehiclesSuperadmin: `${tollsURL}/superadmin/vehicles/types`,

  history: `${tollsURL}/admin/tickets/list`,
  superadminHistory: `${tollsURL}/superadmin/tickets`,
  concessions: `${tollsURL}/superadmin/commerce/list-concession-commerces`,
  tolls: `${tollsURL}/superadmin/tolls/list`,
  typeVehiclesSuperadmin: `${tollsURL}/superadmin/vehicles/types`,
  typeVehicles: `${tollsURL}/admin/vehicles/types`,
  getTollRates: `${tollsURL}/superadmin/toll-rates/list`,

  getState: `${tollsURL}/superadmin/states/list`,
  addToll: `${tollsURL}/superadmin/tolls`,
  addTollAcces: `${tollsURL}/superadmin/tolls/add-access`,

  updateToll: `${tollsURL}/superadmin/tolls/update`,
  updateAccess: `${tollsURL}/superadmin/tolls/update-accesses`
}

export const getTolls = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      // TODO: Change filters
      const { isSuperadmin, filters } = getState().tolls

      if (isSuperadmin) {
        const { ok, res } = await apiMethods.authPost({
          url: endpoints.tolls,
          body: filters
        })

        if (!ok) throw res
        dispatch(setTolls(res.data.items))
        dispatch(setNextPageExists(res.data.nextPageExists))
        dispatch(setLoading(false))

        return
      }
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={error} type={'danger'} />)
    }
  }
}

export const getTollsList = () => {
  return async (dispatch, getState) => {
    try {
      // TODO: Change filters
      const { filters } = getState().tolls
      const { isSuperadmin } = getState().superadmin

      if (isSuperadmin) {
        const { ok, res } = await apiMethods.authPost({
          url: endpoints.tolls,
          body: { ...filters, pagination: { page: 1, limit: 100 } }
        })

        if (!ok) throw res

        const tollsList = res.data.items.map((item) => ({
          value: item._id,
          label: item.name,
          code: item.code,
          accesses: item.accesses
        }))

        dispatch(setTollsList(tollsList))
        return
      }

      const selectedCommerce = JSON.parse(
        localStorage.getItem('selectedCommerce')
      )

      const tolls = selectedCommerce.associated_commerces

      const tollsList = tolls?.map((item) => ({
        value: item?._id,
        label: item?.name
      }))

      dispatch(setTollsList(tollsList))
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={error} type={'danger'} />)
    }
  }
}

export const getConcession = () => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().tolls
      if (isSuperadmin) {
        const { ok, res } = await apiMethods.authPost({
          url: endpoints.concessions
        })

        if (!ok) throw res

        const concessions = res.data.items.map((item) => ({
          value: item._id,
          label: item.fiscal_name
        }))

        dispatch(setConcessionsList(concessions))
      }
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Error al intentar obtener información'}
          type={'danger'}
        />
      ))
    }
  }
}

export const getTypesVehicles = () => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().tolls

      const { ok, res } = await apiMethods.authGet({
        url: isSuperadmin
          ? endpoints.typeVehiclesSuperadmin
          : endpoints.typeVehicles
      })

      if (!ok) throw res

      const typesVehicles = res.data.map((item) => ({
        value: item.id,
        label: item.name
      }))

      dispatch(setVehiclesTypes(typesVehicles))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Error al intentar obtener información'}
          type={'danger'}
        />
      ))
    }
  }
}

export const getTollsAmountsRequest = (body) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.getTollRates,
        body
      })

      dispatch(setTollRates(res.data.items))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={
            'Error al intentar obtener información la referencia del peaje'
          }
          type={'danger'}
        />
      ))
    }
  }
}

export const getStates = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().tolls

      const { ok, res } = await apiMethods.authPost({
        url: endpoints.getState,
        body: filters
      })

      const states = res.data.items.map((state) => {
        const newItem = {
          value: state._id,
          label: state.name
        }
        return newItem
      })

      dispatch(setStateOptions(states))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={
            'Error al intentar obtener información la referencia del peaje'
          }
          type={'danger'}
        />
      ))
    }
  }
}

export const addTollAccess = (body) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.addTollAcces,
        body
      })

      if (!ok) throw res

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Acceso agregado de forma exitosa'}
          type={'success'}
        />
      ))

      dispatch(getTolls())
      dispatch(getTollsList())
      dispatch(setAccessList(res.data.accesses))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={error ?? 'No fue posible agregar el acceso '}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}

export const addToll = (body) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.addToll,
        body
      })

      if (!ok) throw res

      dispatch(getTolls())
      dispatch(getTollsList())
      dispatch(setSelectedToll(res.data.newToll))

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Peaje agregado de forma exitosa'}
          type={'success'}
        />
      ))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'No fue posible agregar el peaje '}
          type={'danger'}
        />
      ))
      throw error
    }
  }
}

export const updateToll = (body) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authPatch({
        url: endpoints.updateToll,
        body
      })

      if (!ok) throw res

      dispatch(getTolls())
      dispatch(getTollsList())
      dispatch(setSelectedToll(null))

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Peaje editado de forma exitosa'}
          type={'success'}
        />
      ))
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={res} type={'danger'} />)
      throw res
    }
  }
}

export const updateAccess = (body) => {
  return async (dispatch, getState) => {
    try {
      const { selectedAccess } = getState().tolls
      const { ok, res } = await apiMethods.authPatch({
        url: endpoints.updateAccess,
        body
      })

      if (!ok) throw res

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Se actualizó el acceso correctamente'}
          type={'success'}
        />
      ))

      const accesses = res.data.accesses
      const updatedAccess = accesses.find(
        (access) => access._id === selectedAccess._id
      )

      dispatch(getTolls())
      dispatch(getTollsList())
      dispatch(setAccessList(res.data.accesses))
      dispatch(setSelectedAccess(updatedAccess))
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={error} type={'danger'} />)
      throw error
    }
  }
}
