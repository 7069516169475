export const defaultFilter = {
  pagination: {
    page: 1,
    limit: 9
  },
  filterOptions: {
    // penalty_type: 'fine',
    areas_ids: [],
    zones_ids: [],
    subzones_ids: []
    // fromDate: '',
    // untilDate: '',
    // license_plate: ''
  }
}

export const initialState = {
  data: [],
  loading: true,
  nextPageExists: false,
  filters: defaultFilter,

  selectedReport: null
}