import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getPenalty: `${parkingMetersURL}/admin/penalties/list`,
  getPenaltySuperadmin: `${parkingMetersURL}/superadmin/penalties/list`,
  getPenaltiesOptionsSuperadmin: `${parkingMetersURL}/superadmin/penalties/types`,
  getPenaltiesOptionsAdmin: `${parkingMetersURL}/admin/penalties/types`
}

export const getPenaltyList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getPenalty,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getPenaltySuperadminList = async (body) => {
  try {
    const response = await apiMethods.authPost({
      url: endpoints.getPenaltySuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getPenaltiesTypesSuperadmin = async (body) => {
  try {
    const response = await apiMethods.authGet({
      url: endpoints.getPenaltiesOptionsSuperadmin
    })
    return response
  } catch (error) {
    return error
  }
}

export const getPenaltiesTypesAdmin = async (body) => {
  try {
    const response = await apiMethods.authGet({
      url: endpoints.getPenaltiesOptionsAdmin
    })
    return response
  } catch (error) {
    return error
  }
}
