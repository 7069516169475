export const defaultFilter = {
  startDate: null,
  endDate: null,
  page: 1,
  limit: 100
}

const defaultActions = {
  approve: { action: 'approve', message: 'aprobar' }
}

const transfersOptions = {
  approved: { label: 'Aprobado', color: 'light-success' },
  pending: { label: 'Pendiente', color: 'light-warning' },
  deferred: { label: 'Diferido', color: 'light-danger' },
  rejected: { label: 'Rechazado', color: 'light-danger' }
}

const initialState = {
  loading: false,
  data: [],
  selectedApproveTransaction: null,
  filters: defaultFilter,
  transfersOptions,
  nextPageExists: false,
  actions: defaultActions
}

export default initialState
