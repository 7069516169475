import { act } from 'react'

export const defaultFilter = {
  company: 'Seguros Caracas',
  policy_number: '',
  usename: '',
  doc_type: '',
  doc_id: '',
  limit: 9,
  page: 1
}

const transfersOptions = {
  pending: { label: 'Pendiente', color: 'light-warning' },
  approved: { label: 'Aprobado', color: 'light-success' },
  rejected: { label: 'Rechazado', color: 'light-danger' }
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar publicidad' },
  update: { action: 'update', message: 'Actualizar publicidad' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' },
  updateImages: { action: 'updateImages', message: 'Actualizar imagenes' },
  showImages: { action: 'showImages', message: 'Ver imagenes' },
  delete: { action: 'deleted', message: 'eliminar' }
}

const initialState = {
  loading: false,
  data: [],
  selectedTransaction: null,
  transfersOptions,
  metrics: {},

  actions: defaultActions,

  filters: defaultFilter,
  nextPageExists: false
}

export default initialState
