export const deafultFilter = {
  date_range: {
    fromDate: null,
    untilDate: null
  },
  filter_coupon_type: '',
  pagination: {
    page: 1,
    limit: 10
  }
}

export const deafultFilterCouponsUsesHistorys = {
  date_range: {
    fromDate: null,
    untilDate: null
  },
  pagination: {
    page: 1,
    limit: 9
  }
}

export const deafultFilterCouponsPrivate = {
  date_range: {
    fromDate: null,
    untilDate: null
  },
  filter_coupon_type: 'private',
  pagination: {
    page: 1,
    limit: 9
  }
}

  export const statusOptions = {
    active: { label: 'Activo', color: 'light-success' },
    completed: { label: 'Completado', color: 'light-primary' },
    inactive: { label: 'Inactivo', color: 'light-secondary' },
    deleted: { label: 'Inactivo', color: 'light-secondary' }
  }

const defaultActions = {
  add: { action: 'add', message: 'Agregar cupon' },
  update: { action: 'update', message: 'Actualizar datos del cupon' },
  delete: { action: 'deleted', message: 'eliminar' },
  updateStatus: { action: 'updateStatus', message: 'actualizar' }
}

const defaultValues = {
  name: '',
  expiration_date: '',
  status: 'active',
  vertical_type: '',
  service_names: [],
  discount_type: 'fixed',
  coupon_type: '',
  value: '',
  maximum_uses: '',
  pd_percentage: 100,
  assign_massively: false,
  avoid_fee: true,
  coupon_owner: '',
  private_type: '',
  new_user: false
}

const handleErrors = {
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  value: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },
  maximum_uses: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    // value musth be greater than 0
    min: {
      value: 0,
      message: 'El valor debe ser mayor a 0'
    }
  },

  vertical_type: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  new_user: {
    validate: (value) => value !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  service_names: {
    validate: (value) => value.length !== null,
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },

  expiration_date: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const couponsOptions = [
  { value: 'public', label: 'Publico' },
  { value: 'private', label: 'Privado' },
  { value: 'referred', label: 'Referido' },
  { value: 'referrer', label: 'Referente' }

]

const initialState = {
  loading: false,
  loadingUsers: false,

  data: [],
  privateCoupons: [],
  couponsUsesHistory: [],

  handleErrors,
  defaultValues,

  couponsOptions,

  filters: deafultFilter,
  filtersCouponsUsesHistory: deafultFilterCouponsUsesHistorys,
  filtersCouponsPrivate: deafultFilterCouponsPrivate,

  selectedCouponsUsesHistory: null,
  selectedCouponsPrivate: null,

  nextPageExists: false,
  selectedCoupon: null,
  actions: defaultActions,
  couponUsers: [],

  discountOptions: [
    { value: 'fixed', label: 'Fijo' },
    { value: 'percentage', label: 'Porcentaje' }
  ],
  codeOptions: [
    { value: 'public', label: 'Publico' },
    { value: 'private', label: 'Privado' }
  ],
  statusOptions: [
    { value: 'active', label: 'Activo' },
    { value: 'inactive', label: 'Inactivo' }
  ]
}

export default initialState
