import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'withdrawalHistory',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setWithdrawalHistory: (state, action) => {
      state.data = action.payload
    },
    setWithdrawalHistoryFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedWithdrawalHistory: (state, action) => {
      state.selectedWithdrawalHistory = action.payload
    },
    setEventOptions: (state, action) => {
      state.eventOptions = action.payload
    }
  }
})

export const {
  setLoading,
  setEventOptions,
  setWithdrawalHistory,
  setWithdrawalHistoryFilters,
  setNextPageExists,
  setSelectedWithdrawalHistory
} = slice.actions

export const withdrawalHistory = slice.reducer
