import * as api from '.'
import moment from 'moment'

const commonTimeout = 5000
export const getReport = async ({
  reportValue,
  file,
  fromDate,
  endDate,
  transaction_tag,
  schoolId,
  buildingCode,
  buildingId,
  transactionTag,
  username,
  commercesIds,
  tollsCodes,
  userEmail
}) => {
  const datesArgs = {
    fecha_inicio: moment(fromDate, 'YYYY/MM/DD').format('DD-MM-YYYY'),
    fecha_fin: moment(endDate, 'YYYY/MM/DD').add(1, 'days').format('DD-MM-YYYY')
  }
  const schoolArgs = {
    escuela_id: schoolId
  }
  const logsArgs = {
    building_code: buildingCode
  }

  const buildingArgs = {
    building_id: buildingCode
  }

  const tagsArgs = {
    transaction_tag: transactionTag
  }
  const usernameArgs = {
    username
  }

  const commercesArgs = {
    comercios: commercesIds
  }

  const parkingMetersArgs = {
    comercio_id: commercesIds
  }

  const tollsArgs = {
    peajes: tollsCodes
  }

  const userParkingArgs = {
    usuario: username,
    email_usuario: userEmail,
    attachment_filetype: 'pdf'
  }

  const formData = new FormData()
  Object.entries(datesArgs).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('archivotxt', file)

  const reports = {
    transactionsCustomerInsurance: {
      service: api.transactionsCustomerInsurance,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    userParkingTickets: {
      service: api.userParkingTickets,
      args: {
        ...datesArgs,
        ...userParkingArgs
      },
      timeout: commonTimeout
    },
    tollTickets: {
      service: api.tollTickets,
      args: {
        ...datesArgs,
        ...tollsArgs
      },
      timeout: commonTimeout
    },
    transactionsCommercesTransfers: {
      service: api.transactionsCommercesTransfers,
      args: {
        ...datesArgs,
        ...commercesArgs
      },
      timeout: commonTimeout
    },
    parkingMeters: {
      service: api.parkingMeters,
      args: {
        ...datesArgs,
        ...parkingMetersArgs
      },
      timeout: commonTimeout
    },
    parkingMetersLogs: {
      service: api.parkingMetersLogs,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    verifiedRegisteredUsers: {
      service: api.verifiedRegisteredUsers,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    unverifiedRegisteredUsers: {
      service: api.unverifiedRegisteredUsers,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    unverifiedRegisteredUsersByOffice: {
      service: api.unverifiedRegisteredUsersByOffice,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    userActivities: {
      service: api.userActivities,
      args: formData,
      timeout: 20000
    },

    transactionsCustomer: {
      service: api.transactionsCustomer,
      args: { ...datesArgs, ...tagsArgs },
      timeout: commonTimeout
    },
    servicePayment: {
      service: api.servicePayment,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    walletRecharges: {
      service: api.walletRecharges,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    transactionsCreditPremium: {
      service: api.transactionsCreditPremium,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    transactionsSchools: {
      service: api.transactionsSchools,
      args: {
        ...datesArgs,
        ...schoolArgs
      },
      timeout: commonTimeout
    },

    premiumTicketsQtyByUser: {
      service: api.premiumTicketsQtyByUser,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    approvedRechargesSuperadmin: {
      service: api.approvedRechargesSuperadmin,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    tickets: {
      service: api.tickets,
      args: {
        ...datesArgs
      },
      timeout: commonTimeout
    },
    parkingLogs: {
      service: api.parkingLogs,
      args: {
        ...datesArgs,
        ...logsArgs
      },
      timeout: commonTimeout
    },
    parkingLogsBuildingPhysicalAccess: {
      service: api.parkingLogsBuildingPhysicalAccess,
      args: {
        ...datesArgs,
        ...buildingArgs
      },
      timeout: commonTimeout
    },
    parkingLogsUserPhysicalAccess: {
      service: api.parkingLogsUserPhysicalAccess,
      args: {
        ...datesArgs,
        ...usernameArgs
      },
      timeout: commonTimeout
    },
    ticketsBuilding: {
      service: api.ticketsBuilding,
      args: {
        ...datesArgs,
        ...logsArgs
      },
      timeout: commonTimeout
    }
  }

  try {
    const request = reports[reportValue].service(reports[reportValue].args)
    const timeoutRequest = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject({
          ok: false,
          timeout: true,
          message:
            'Agotado el tiempo de espera del sistema, espere el e-mail en 5 minutos'
        })
      }, reports[reportValue].timeout)
    })
    const response = await Promise.race([timeoutRequest, request])
    if (response.error) throw response.error
    return {
      ...response,
      result: 'success'
    }
  } catch (error) {
    return {
      ok: false,
      error,
      result: error.timeout ? 'warning' : 'danger'
    }
  }
}

export const commerces = async () => {
  try {
    const response = await api.getCommerces({})
    const res = await response.json()
    if (res.error) throw res.error
    return {
      ok: true,
      res
    }
  } catch (error) {
    return {
      ok: false,
      response: error,
      result: error.timeout ? 'alert' : 'error'
    }
  }
}
