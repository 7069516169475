// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const tripsSlices = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedTrip: (state, action) => {
      state.selectedTrip = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },

    // TODO: Add thunk
    setTripData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const {
  setLoading,
  setTripData,
  setPagination,
  setSelectedTrip,
  setNextPageExists,
  setFilters
} = tripsSlices.actions

export const trips = tripsSlices.reducer
