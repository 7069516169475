import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTransfers: (state, action) => {
      state.data = action.payload
    },
    setSelectedTransfer: (state, action) => {
      state.selectedTransfer = action.payload
    },
    setTransfersFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    }

  }
})

export const {
  setLoading,
  setTransfers,
  setSelectedTransfer,
  setTransfersFilters,
  setNextPageExists
} = slice.actions

export const transfers = slice.reducer
