import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd03, baseV1URL } from '@configs/apis'

const endpoints = {
  validateEventDetails: `${baseV2URL}/superadmin/events/ticket`,
  unValidateEventDetails: `${baseV2URL}/superadmin/events/tickets/unvalidate`,
  listEvent: `${baseV2URL}/commerces/events/list`,
  eventInfo: `${baseV2URL}/commerces/events/info`,
  buyEvent: `${baseV2URL}/commerces/events/buy`,
  exchangeUsd: `${baseV2URL}/exchange-usd`
}

export const getExchangeRateService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.exchangeUsd}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getEventListService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.listEvent}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const getEventDetailService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.eventInfo}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const buyEventService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.buyEvent}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
export const validateEventDetails = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.validateEventDetails}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const unValidateEventDetails = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.unValidateEventDetails}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
