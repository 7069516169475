export const defaultFilter = {
  reference: '',
  amount: '',
  phone: '',
  status: ['approved', 'deferred', 'pending'],
  page: 1,
  show: 10
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar extensión' },
  update: { action: 'update', message: 'Actualizar extensión' },
  delete: { action: 'deleted', message: 'eliminar' },
  reversePremium: { action: 'reversePremium', message: 'reversar premium' },
  reverseParkingMeter: {
    action: 'reverseParkingMeter',
    message: 'reversar parquímetros'
  },
  reverseToll: { action: 'reverseToll', message: 'reversar peaje' }
}

const mobilePaidOptions = {
  approved: { label: 'Aprobado', color: 'light-success' },
  pending: { label: 'Pendiente', color: 'light-warning' },
  deferred: { label: 'Diferido', color: 'light-danger' }
}

const initialState = {
  loading: false,
  data: [],

  mobilePaidOptions,

  filters: defaultFilter,

  transactionTagsOptions: [],
  descriptionTagsOptions: [],

  selectedExtensionList: [],
  selectedMobilePaid: null,

  nextPageExists: false,
  actions: defaultActions
}

export default initialState
