import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'advertising',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAdvertising: (state, action) => {
      state.data = action.payload
    },
    setAdvertisingOptions: (state, action) => {
      state.advertisingOptions = action.payload
    },

    setAdvertisingFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedAdvertising: (state, action) => {
      state.selectedAdvertising = action.payload
    },
    setServicesOptions: (state, action) => {
      state.servicesOptions = action.payload
    }
  }
})

export const {
  setAdvertisingFilters,
  setLoading,
  setAdvertisingOptions,
  setAdvertising,
  setNextPageExists,
  setSelectedAdvertising,
  setServicesOptions
} = slice.actions

export const advertising = slice.reducer
