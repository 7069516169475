import { apiMethods } from '@services/methods'

import { parkingMetersURL } from '@configs/apis'

import { setZone, setZones, setOpenModal, setSubzones } from './slice'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'

const endpoints = {
  loadZonesList: `${parkingMetersURL}/superadmin/zones`,
  loadSubzones: `${parkingMetersURL}/superadmin/subzones/list`,
  addSubzones: `${parkingMetersURL}/superadmin/subzones`,
  deleteZone: `${parkingMetersURL}/superadmin/zones`,
  updateZone: `${parkingMetersURL}/superadmin/areas/update`
}

const filters = {
  area_id: '',
  concession_commerce_id: '63882e1dbb7e036a8ee93fe5',
  pagination: {
    page: 1,
    limit: 6
  },
  sorting: {
    code: 'createdAt',
    sortOrder: 'desc'
  },
  filterOptions: {
    code: '',
    display_name: ''
  }
}

const getSubzone = async (defaultFilters) => {
  try {
    const response = await apiMethods.authPostTemp({
      url: endpoints.loadSubzones,
      body: defaultFilters
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const loadSubzones = (defaultFilters) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await getSubzone(defaultFilters)

      if (ok) {
        dispatch(setSubzones(res.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const loadZones = () => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authGet({
        url: endpoints.loadZonesList
      })

      if (ok) {
        const zoneList = [{ value: undefined, label: 'Seleccione' }]

        res.data.items.map((area) => {
          zoneList.push({ value: area._id, label: area.display_name })
        })

        dispatch(setZone(zoneList))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const addSubzone = (body) => {
  return async (dispatch) => {
    try {
      const { ok, res } = await apiMethods.authPostTemp({
        url: endpoints.addSubzones,
        body
      })

      if (ok) {
        // const { res } = await getZones(filters)
        // dispatch(setZones(res.data))

        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Subzona añadida exitosamente'}
            type={'success'}
          />
        ))
      } else {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'Ocurrió un error al registrar la subzona'}
            type={'danger'}
          />
        ))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const openModal = (action) => {
  return async (dispatch) => {
    dispatch(setOpenModal(action))
  }
}

// export const softDeleteZone = (id) => {
//   return async (dispatch) => {
//     const { ok, res } = await apiMethods.authDelete({
//       url: `${endpoints.deleteZone}/${id}`
//     })

//     if (ok) {
//       const { res } = await getZones(filters)
//       // dispatch(setZones(res.data))

//       toast((t) => (
//         <BasicToastContent
//           t={t}
//           message={'Zona eliminada exitosamente'}
//           type={'success'}
//         />
//       ))
//     } else {
//       toast((t) => (
//         <BasicToastContent
//           t={t}
//           message={'Ocurrió un error al eliminar la zona'}
//           type={'danger'}
//         />
//       ))
//     }
//   }
// }
// export const setAreaForm = (body) => {
//   return async (dispatch) => {
//     dispatch(setArea(body))
//   }
// }

// export const setUpdateForm = (action) => {
//   return async (dispatch) => {
//     dispatch(setUpdate(action))
//   }
// }
