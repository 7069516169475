import { getCommerceData } from '@utils'

export const initialOperator = () => {
  const operator = JSON.parse(window.localStorage.getItem('operator'))
  const isSuperadmin = operator?.commerce_associated?.category === 'pagodirecto'
  return isSuperadmin
}

const commerce = getCommerceData()

const deafultFilter = {
  pagination: {
    page: 1,
    limit: 11
  },
  sorting: {
    sortBy: 'created_at',
    sortOrder: 'desc'
  },
  filterOptions: {
    autocomplete: '',
    amount: '',
    fromDate: '',
    untilDate: '',
    type: []
  }
}

const defaultActions = {
  addToll: {
    action: 'add toll',
    message: 'Añadir peaje'
  },
  updateToll: {
    action: 'update toll',
    message: 'Actualizar datos del peaje'
  },
  addAccess: {
    action: 'update access',
    message: 'Añadir acceso del peaje'
  },
  updateAccess: {
    action: 'update access',
    message: 'Actualizar datos del acceso'
  }
}

export const initialState = {
  loading: false,
  data: [],
  vehiclesTypes: [],
  filters: deafultFilter,

  concessionsList: [],
  tolls: [],
  tollsList: [],
  stateOptions: [],
  tollRates: null,
  selectedToll: null,

  accessList: [],
  selectedAccess: null,

  actions: defaultActions,

  macOptions: [
    { label: 'Arduin', value: 'arduin' },
    { label: 'Beacon', value: 'beacon' }
  ],

  isSuperadmin: false,
  nextPageExists: false
}
