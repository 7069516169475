// ** Icons Import
import {
  FileText,
  Circle,
  Navigation2,
  DollarSign,
  Users,
  Activity,
  Package,
  Clipboard
} from 'react-feather'

export default [
  {
    id: 'Tickets',
    title: 'Transacciones',
    action: 'seguros-level-2',
    resource: 'Common',
    icon: <FileText size={20} />,
    navLink: '/insurance/transactions'
  },
  {
    id: 'insurance-reports',
    title: 'Reportes',
    action: 'seguros-level-2',
    resource: 'Common',
    icon: <FileText size={20} />,
    navLink: '/insurance/insurance-reports'
  }
]
