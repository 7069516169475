import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd03, micropd04 } from '@configs/apis'

const endpoints = {
  getTransactions: `${micropd04}/admin/insurances/list`,
  getMetrics: `${micropd04}/admin/insurances/metrics`
}

export const getInsuranceTransactionsServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getTransactions,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getInsuranceMetricsServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getMetrics,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
