// ** Redux Imports
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiMethods } from '@services/methods'
import { tollsURL } from '@configs/apis'
// ** Axios Imports
import axios from 'axios'
import {
  addUser,
  setBundleModal,
  setFilterBundles,
  setUpdateModal,
  setUser,
  setVehicle,
  setVehicles,
  setVehicleTypeOptions,
  showUser,
  showVehicle
} from './slice'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'

const endpoints = {
  autocomplete: `${tollsURL}/admin/users/autocomplete`,
  createVehicle: `${tollsURL}/admin/vehicles/with-tag`,
  addUser: `${tollsURL}/admin/users/commerce/user-signup`,
  getUser: `${tollsURL}/admin/users`,
  deleteVehicle: `${tollsURL}/admin/vehicles/user-remove`,
  updateVehicle: `${tollsURL}/admin/vehicles/edit`,
  getBundles: `${tollsURL}/admin/bundles/get-bundles`,
  addBundles: `${tollsURL}/admin/bundles/activate-tag`,
  getVehiclesType: `${tollsURL}/admin/vehicles/types`,
  addTag: `${tollsURL}/admin/tags/add-vehicle`
}

export const deleteVehicleThuk = (vehicleId) => {
  return async (dispatch, getState) => {
    const userId = getState().usersList.selectedUser._id

    const body = {
      vehicle_id: vehicleId,
      customer_id: userId
    }

    const { ok, res } = await apiMethods.authPost({
      url: endpoints.deleteVehicle,
      body
    })

    if (ok) {
      const id = getState().usersList.selectedUser._id
      const { res: response } = await apiMethods.authGet({
        url: `${endpoints.getUser}/${id}`
      })
      dispatch(setVehicles(response.data.vehicles))

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'El vehículo se eliminó de forma exitosa'}
          type={'success'}
        />
      ))
    } else {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'No fue posible eliminar el vehículo'}
          type={'danger'}
        />
      ))
    }
  }
}

export const updateVehicleThuk = (body) => {
  return async (dispatch, getState) => {
    const { ok, res } = await apiMethods.authPost({
      url: endpoints.updateVehicle,
      body
    })

    if (ok) {
      const id = getState().usersList.selectedUser._id
      const { res: response } = await apiMethods.authGet({
        url: `${endpoints.getUser}/${id}`
      })

      dispatch(
        setUser({
          flag: true,
          data: res.data
        })
      )
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'El vehículo se actualizó de forma exitosa'}
          type={'success'}
        />
      ))

      dispatch(setVehicles(response.data.vehicles))
    } else {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'No fue posible actualizar el vehículo'}
          type={'danger'}
        />
      ))
    }
  }
}

export const addUserThunk = (show) => {
  return async (dispatch) => {
    dispatch(addUser(show))
  }
}

export const showUserListThunk = (show) => {
  return async (dispatch) => {
    dispatch(showUser(show))
  }
}

export const showVehicleListThunk = (show) => {
  return async (dispatch) => {
    dispatch(showVehicle(show))
  }
}

export const setUserThunk = (body, flag) => {
  return async (dispatch) => {
    const { ok, res } = await apiMethods.authGet({
      url: `${endpoints.getUser}/${body._id}`
    })

    dispatch(
      setUser({
        data: body,
        vehicles: res.data.vehicles,
        flag
      })
    )
  }
}

export const addUserRequest = createAsyncThunk(
  'appUsers/addUserRequest',
  async (body) => {
    const { ok, res } = await apiMethods.authPost({
      url: endpoints.addUser,
      body
    })

    if (!ok) {
      toast((t) => <BasicToastContent t={t} message={res} type={'danger'} />)
      return {
        data: null,
        list: [],
        flag: false
      }
    }

    toast((t) => (
      <BasicToastContent t={t} message={'Registro exitoso'} type={'success'} />
    ))

    return {
      data: res.data,
      list: [res.data],
      flag: true
    }
  }
)

export const getAllData = createAsyncThunk(
  'appUsers/getAllData',

  async (body) => {
    const { res } = await apiMethods.authPost({
      url: endpoints.autocomplete,
      body
    })

    return res.data.items
  }
)

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)

  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const getBundles = createAsyncThunk(
  'appUsers/getBundles',
  async (id) => {
    const { res, ok } = await apiMethods.authPost({
      url: endpoints.getBundles
    })

    const bundleOptions = []
    res.data.items.map((bundle) => {
      const newItem = {
        label: `${bundle.usages} Accesos - ${bundle.amount} Bss`,
        value: bundle._id,
        type: bundle.type
      }
      bundleOptions.push(newItem)
    })
    return bundleOptions
  }
)

export const selectUser = createAsyncThunk(
  'appUsers/selectUser',
  async (user) => {
    const { res } = await apiMethods.authGet({
      url: `${endpoints.getUser}/${user._id}`
    })
    return res.data
  }
)

export const addVehicle = createAsyncThunk(
  'appUsers/addVehicle',
  async (formValues, { dispatch, getState }) => {
    const { ok, res } = await apiMethods.authPost({
      url: endpoints.createVehicle,
      body: formValues
    })

    if (!ok) {
      toast((t) => <BasicToastContent t={t} message={res} type={'danger'} />)
      return
      // return {
      //   flag: true,
      //   data: getState().usersList.selectedUser
      // }
    }

    const id = getState().usersList.selectedUser._id
    const { res: response } = await apiMethods.authGet({
      url: `${endpoints.getUser}/${id}`
    })
    dispatch(setVehicles(response.data.vehicles))
    dispatch(addUser(false))
    dispatch(showVehicle(true))

    toast((t) => (
      <BasicToastContent t={t} message={'Registro exitoso'} type={'success'} />
    ))
  }
)

export const deleteUser = createAsyncThunk(
  'appUsers/deleteUser',
  async (id, { dispatch, getState }) => {
    await axios.delete('/apps/users/delete', { id })
    await dispatch(getData(getState().users.params))
    // await dispatch(builder.getAllData())
    return id
  }
)

export const usersAutocomplete = createAsyncThunk(
  'appUsers/usersAutocomplete',
  async (body) => {
    const { ok, res } = await apiMethods.authPost({
      url: endpoints.autocomplete,
      body
    })

    if (!ok) {
      return []
    }

    return res.data
  }
)

export const setModal = (action) => {
  return async (dispatch) => {
    dispatch(setUpdateModal(action))
  }
}

export const setBundleM = (action) => {
  return async (dispatch) => {
    dispatch(setBundleModal(action))
  }
}

export const setVehicleThunk = (body) => {
  return async (dispatch) => {
    dispatch(setVehicle(body))
  }
}

export const addBundlesToVehicle = (body) => {
  return async (dispatch, getState) => {
    const data = {
      bundle: body.bundle.value,
      tag: getState().usersList.selectedVehicle.tag._id,
      customer: getState().usersList.selectedUser._id
    }

    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.addBundles,
        body: data
      })

      if (!ok) {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'No se pudo asignar el paquete'}
            type={'danger'}
          />
        ))
        return
      }

      const id = getState().usersList.selectedUser._id
      const { res: response } = await apiMethods.authGet({
        url: `${endpoints.getUser}/${id}`
      })
      dispatch(setVehicles(response.data.vehicles))

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Paquete asignado'}
          type={'success'}
        />
      ))
    } catch (error) {
      console.log(error)
    }
  }
}

export const addTagToVehicle = (body) => {
  return async (dispatch, getState) => {
    const data = {
      tag_id: body,
      vehicle: getState().usersList.selectedVehicle._id
    }

    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.addTag,
        body: data
      })

      if (!ok) {
        toast((t) => (
          <BasicToastContent
            t={t}
            message={'No se pudo asignar el tag'}
            type={'danger'}
          />
        ))
        return
      }

      const id = getState().usersList.selectedUser._id
      const { res: response } = await apiMethods.authGet({
        url: `${endpoints.getUser}/${id}`
      })
      dispatch(setVehicles(response.data.vehicles))

      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Tag asignado'}
          type={'success'}
        />
      ))
    } catch (error) {
      console.log(error)
    }
  }
}

export const filterBundle = (body) => {
  return async (dispatch, getState) => {
    const bundles = getState().usersList.bundles
    const filterBundles = bundles.filter((bundle) => {
      return bundle.type === body
    })

    dispatch(setFilterBundles(filterBundles))
  }
}

export const getVehicleTypes = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await apiMethods.authGet({
        url: endpoints.getVehiclesType
      })
      const options = []

      res.data.map((item) => {
        const newOption = { value: item.id, label: item.name }
        options.push(newOption)
      })
      dispatch(setVehicleTypeOptions(options))
    } catch (error) {
      console.log(error)
    }
  }
}
