import { tollsURL } from '@configs/apis'
import { apiMethods } from '@services/methods'

import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'

const endpoints = {
  addBundle: `${tollsURL}/superadmin/bundles`,

  getConcession: `${tollsURL}/superadmin/commerce/list-concession-commerces`,
  getBundle: `${tollsURL}/superadmin/bundles/get-bundles`,
  getVehiclesType: `${tollsURL}/superadmin/vehicles/types`
}

import { setBundles, setNextPageExists } from './slice'

export const getBundles = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().bundlesAdmin
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.getBundle,
        body: filters
      })
      if (!ok) throw res

      dispatch(setBundles(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (error) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'Error al intentar obtener información'}
          type={'danger'}
        />
      ))
    }
  }
}

export const addBundle = (body) => {
  return async (dispatch, getState) => {
    try {
      const { ok, res } = await apiMethods.authPost({
        url: endpoints.addBundle,
        body
      })

      if (!ok) throw res

      toast((t) => (
        <BasicToastContent t={t} message={res.message} type={'success'} />
      ))

      dispatch(getBundles())
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={error} type={'danger'} />)
      throw error
    }
  }
}
