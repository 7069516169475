import {
  setLoading,
  setSelectedInvoice,
  setInvoices,
  setPagination
} from '@store/parking-lot/invoices'
import {
  invoices,
  invoiceById,
  generateExcel
} from '@services/parking-lot/invoices'

import toast from 'react-hot-toast'
import { errors } from '@src/assets/constants/text'

export const invoicesHistory = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { parkingLotInvoices, commerces, parkingLotBuildings } = getState()
      const { pagination, dateRange } = parkingLotInvoices
      const { selectedCommerce: commerce } = commerces
      const { selectedBuilding: building } = parkingLotBuildings
      const { ok, res, error } = await invoices({
        ...dateRange,
        ...pagination,
        commerce: commerce._id,
        building
      })

      if (!ok) throw error
      const nextPageExists = res?.bills?.length >= pagination.show
      dispatch(setPagination({ ...pagination, nextPageExists }))
      dispatch(setInvoices(res?.bills ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const invoiceId = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { parkingLotInvoices } = getState()
      const { commerce } = parkingLotInvoices

      const { ok, res, error } = await invoiceById({
        id,
        commerce: commerce._id
      })

      if (!ok) throw error
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const sendExcelByEmail = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { parkingLotInvoices } = getState()
      const { commerce, selectedInvoice } = parkingLotInvoices

      const body = {
        commerce_id: commerce._id,
        bill_code: selectedInvoice.code
      }
      const { ok, res, error } = await generateExcel(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Correo enviado con éxito')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}
