import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setCouponUsers,
  setSelectedAdvertising,
  setLoadingUsers,
  setAdvertising,
  setServicesOptions,
  setAdvertisingOptions,
  setTemporalXAxis,
  setTemporalYAxisTotal,
  setTemporalYAxisClicks,
  setTemporalYAxisViews,
  setTemporalYAxisReactions,
  setComparativeXAxis,
  setComparativeYAxisTotal,
  setComparativeYAxisClicks,
  setComparativeYAxisViews,
  setComparativeYAxisReactions,
  setTotalMetrics
} from './slice'
import {
  createAdvertisingService,
  deleteAdvertisingService,
  getAdvertisingList,
  getServices,
  updateAdvertisingService
} from '../../../services/users/advertising/advertising'
import {
  advertisingDailyCollectionService,
  getComparativeAdvertisingDistribution,
  getTemporalAdvertisingDistribution
} from '../../../services/users/advertising-dashboard/advertising'
import moment from 'moment'

export const getAdvertisingTemporalDistribution = (query = 'daily') => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().advertisingDashboard

      const { ok, res, error } = await getTemporalAdvertisingDistribution(
        query,
        filters
      )

      const metrics = ['clicks', 'views', 'reactions', 'total']
      const data = metrics.reduce(
        (acc, metric) => ({
          ...acc,
          [metric]: {
            total: [],
            totalPDAmount: [],
            totalAmount: []
          }
        }),
        {}
      )

      const xAxis = []

      if (ok && query === 'daily') {
        const temporalDistribution = res?.data[0].metrics
        const fromDate = moment(res?.data[0].from_date, 'YYYY-MM-DD')
        temporalDistribution.forEach((item) => {
          const formattedDate = fromDate.format('YYYY-MM-DD')
          xAxis.push(formattedDate)
          metrics.forEach((metric) => {
            data[metric].total.push(item[metric].number)
            data[metric].totalPDAmount.push(item[metric].pdAmountCollected)
            data[metric].totalAmount.push(item[metric].totalAmountCollected)
          })

          fromDate.add(1, 'days')
        })
      }

      if (ok && query === 'weekly') {
        const temporalDistribution = res?.data
        temporalDistribution.forEach((item) => {
          const from = moment(item.from_date, 'YYYY-MM-DD')
          const until = moment(item.until_date, 'YYYY-MM-DD')
          xAxis.push(
            `De ${from.format('YYYY-MM-DD')} a ${until.format('YYYY-MM-DD')}`
          )

          metrics.forEach((metric) => {
            data[metric].total.push(item.current_metrics[metric].number)
            data[metric].totalPDAmount.push(
              item.current_metrics[metric].pdAmountCollected
            )
            data[metric].totalAmount.push(
              item.current_metrics[metric].totalAmountCollected
            )
          })
        })
      }

      if (ok && query === 'monthly') {
        const temporalDistribution = res?.data
        temporalDistribution.forEach((item) => {
          const from = moment(item.from_date, 'YYYY-MM-DD')
          const until = moment(item.until_date, 'YYYY-MM-DD')
          xAxis.push(
            `De ${from.format('YYYY-MM-DD')} a ${until.format('YYYY-MM-DD')}`
          )

          metrics.forEach((metric) => {
            data[metric].total.push(item.current_metrics[metric].number)
            data[metric].totalPDAmount.push(
              item.current_metrics[metric].pdAmountCollected
            )
            data[metric].totalAmount.push(
              item.current_metrics[metric].totalAmountCollected
            )
          })
        })
      }

      dispatch(setTemporalXAxis(xAxis))
      dispatch(setTemporalYAxisClicks(data.clicks))
      dispatch(setTemporalYAxisViews(data.views))
      dispatch(setTemporalYAxisReactions(data.reactions))
      dispatch(setTemporalYAxisTotal(data.total))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAdvertisingComparativeDistribution = (query = 'weekly') => {
  return async (dispatch, getState) => {
    try {
      if (query === 'custom') return

      const { filters } = getState().advertisingDashboard

      const requestQuery = query === 'daily' ? 'weekly' : query

      const { ok, res, error } = await getComparativeAdvertisingDistribution(
        requestQuery,
        filters
      )

      if (!ok) throw new Error(error)

      const metrics = ['clicks', 'views', 'reactions', 'total']
      const data = metrics.reduce(
        (acc, metric) => ({
          ...acc,
          [metric]: {
            current: { item: [], pdAmount: [], totalAmount: [] },
            previous: { item: [], pdAmount: [], totalAmount: [] }
          }
        }),
        {}
      )
      const processMetrics = (metricsData, period) => {
        Object.entries(metricsData).forEach(([key, value]) => {
          metrics.forEach((metric) => {
            data[metric][period].item.push(value?.[metric]?.number)
            data[metric][period].pdAmount.push(
              value?.[metric]?.pdAmountCollected
            )
            data[metric][period].totalAmount.push(
              value?.[metric]?.totalAmountCollected
            )
          })
        })
      }

      processMetrics(res.data.current_metrics, 'current')
      processMetrics(res.data.previous_metrics, 'previous')

      const daysOfWeekMapping = {
        monday: 'Lun',
        tuesday: 'Mar',
        wednesday: 'Mie',
        thursday: 'Jue',
        friday: 'Vie',
        saturday: 'Sáb',
        sunday: 'Dom'
      }

      const xAxis =
        query === 'monthly'
          ? Object.keys(res.data.current_metrics)
          : Object.keys(res.data.current_metrics).map(
              (day) => daysOfWeekMapping[day]
            )

      const calculateTotal = (arr) => arr.reduce((acc, val) => acc + val, 0)

      if (query !== 'daily') {
        const totals = {}

        for (const metric of metrics) {
          totals[metric] = {
            item: calculateTotal(data[metric].current.item),
            pdAmount: calculateTotal(data[metric].current.pdAmount),
            totalAmount: calculateTotal(data[metric].current.totalAmount)
          }
        }

        dispatch(setTotalMetrics(totals))
      }

      dispatch(setComparativeXAxis(xAxis))
      dispatch(setComparativeYAxisClicks(data.clicks))
      dispatch(setComparativeYAxisViews(data.views))
      dispatch(setComparativeYAxisReactions(data.reactions))
      dispatch(setComparativeYAxisTotal(data.total))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const advertisingDailyCollection = (query = 'daily') => {
  return async (dispatch, getState) => {
    try {
      if (query !== 'daily') return
      const { filters } = getState().advertisingDashboard
      const { ok, res, error } = await advertisingDailyCollectionService(
        filters
      )
      const response = {
        clicks: {
          item: res?.data?.clicks?.number,
          pdAmount: res?.data?.clicks?.pdAmountCollected,
          totalAmount: res?.data?.clicks?.totalAmountCollected
        },
        reactions: {
          item: res?.data?.reactions?.number,
          pdAmount: res?.data?.reactions?.pdAmountCollected,
          totalAmount: res?.data?.reactions?.totalAmountCollected
        },
        views: {
          item: res?.data?.views?.number,
          pdAmount: res?.data?.views?.pdAmountCollected,
          totalAmount: res?.data?.views?.totalAmountCollected
        },
        total: {
          item: res?.data?.total?.number,
          pdAmount: res?.data?.total?.pdAmountCollected,
          totalAmount: res?.data?.total?.totalAmountCollected
        }
      }

      dispatch(setTotalMetrics(response))
    } catch (error) {
      console.log(error)
    }
  }
}
