import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getZonesSuperadmin: `${parkingMetersURL}/superadmin/subzones/list`,
  getZones: `${parkingMetersURL}/admin/subzones/list`,
  getZonesPos: `${parkingMetersURL}/pos/subzones/list`,

  createSubzone: `${parkingMetersURL}/superadmin/subzones`,
  deleteSubzone: `${parkingMetersURL}/superadmin/subzones`,
  updateSubzone: `${parkingMetersURL}/superadmin/subzones`,
  associate: `${parkingMetersURL}/superadmin/subzones/profit-distribution`,
  associatePenalty: `${parkingMetersURL}/superadmin/subzones/penalty-profit-distribution`,
  associateTowPenalty: `${parkingMetersURL}/superadmin/subzones/tow-profit-distribution`
}

export const associateTowPenaltyToSubzoneService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.associateTowPenalty,
      body
    })
    return response
  } catch (error) {
    return error
  }
}


export const associateCompanyPenaltyToSubzoneService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.associatePenalty,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const associateCompanyToSubzoneService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.associate,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getSubzonesList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getZonesSuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getSubzonesAdmin = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getZones,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getSubzonesPos = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getZonesPos,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createSubzone = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createSubzone,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateSubzoneService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.updateSubzone,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteSubzoneService = async (id) => {
  try {
    const response = await apiMethods.authDelete({
      url: `${endpoints.deleteSubzone}/${id}`
    })
    return response
  } catch (error) {
    return error
  }
}
