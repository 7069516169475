import { apiMethods } from '@services/methods'
import { tollsURL } from '@configs/apis'

import {
  setBundleData,
  setConcessionsList,
  setLoading,
  setNextPageExists,
  setTolls,
  setTollsList,
  setTripData,
  setVehiclesTypes
} from './slice'
import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import { log, logError } from '@craco/craco/lib/logger'

const endpoints = {
  history: `${tollsURL}/admin/tickets/list`,
  superadminHistory: `${tollsURL}/superadmin/tickets`,
  concessions: `${tollsURL}/superadmin/commerce/list-concession-commerces`,
  tolls: `${tollsURL}/superadmin/tolls/list`,
  typeVehiclesSuperadmin: `${tollsURL}/superadmin/vehicles/types`,
  typeVehicles: `${tollsURL}/admin/vehicles/types`
}

export const getTrips = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().trips
      const { isSuperadmin } = getState().tolls

      // TODO: Fix back isue to remove
      let newFilters = { ...filters }
      if (isSuperadmin) {
        const { commerces, ...rest } = newFilters
        newFilters = rest
      }

      const { ok, res } = await apiMethods.authPost({
        url: isSuperadmin ? endpoints.superadminHistory : endpoints.history,
        body: newFilters
      })

      if (!ok) throw res

      dispatch(setTripData(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
      dispatch(setLoading(false))
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={error} type={'danger'} />)
    }
  }
}
