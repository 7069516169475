import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setTransactions,
  setTransactionTagsOptions,
  setMobilePaid,
  setSelectedMobilePaid
} from './slice'

import { t } from 'i18next'
import {
  getMobilePaidTransactions,
  getTransactionsDetailServices
} from '../../../services/users/mobilePaid'

export const getMobilePaid = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().mobilePaid
      const { ok, res, error } = await getMobilePaidTransactions(filters)

      if (!ok) throw error

      dispatch(setNextPageExists(res?.nextPageExists ?? false))
      dispatch(setMobilePaid(res?.mobilePayments ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getTransactionDetails = (id) => {
  return async (dispatch, getState) => {
    try {
      const { selectedMobilePaid } = getState().mobilePaid
      const body = { mobile_payment_id: id }
      const { ok, res, error } = await getTransactionsDetailServices(body)

      if (!ok) throw error
      const state = {
        ...selectedMobilePaid,
        ...res?.recharge,
        _id: res?.recharge?.mobile_payment_ref
      }
      dispatch(setSelectedMobilePaid(state ?? null))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    }
  }
}
