import { lazy } from 'react'
import { Navigate } from 'react-router-dom'


const prefix = '/users'

const Extensions = lazy(() => import('@usersViews/extensions'))
const SearchUsers = lazy(() => import('@usersViews/search-users'))
const Advertising = lazy(() => import('@usersViews/advertising'))
const Vehicles = lazy(() => import('@usersViews/vehicles'))
const Transactions = lazy(() => import('@usersViews/transactions'))
const Reports = lazy(() => import('@usersViews/reports'))
const PayEvent = lazy(() => import('@usersViews/pay-event'))
const RegisterUser = lazy(() => import('@usersViews/register-user'))
const ValidateEvent = lazy(() => import('@usersViews/validate-event'))
const UsersEvent = lazy(() => import('@usersViews/users-events'))
const EventHistory = lazy(() => import('@usersViews/events-history'))
const Notification = lazy(() => import('@usersViews/notifications'))

const MobilePaid = lazy(() => import('@usersViews/mobilePaid'))
const Transfers = lazy(() => import('@usersViews/transfers'))
const EnableRecharge = lazy(() => import('@usersViews/enable-recharge'))
const ApproveTransactions = lazy(() =>
  import('@usersViews/approve-transactions')
)
const AdvertisingDashboard = lazy(() =>
  import('@usersViews/advertising-dashboard')
)
const UsersDashboard = lazy(() => import('@usersViews/dashboard'))

// const SearchUsers = lazy(() => import('@usersViews/search-users'))

const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const ParkingLotRoutes = [
  {
    element: <UsersDashboard />,
    path: `${prefix}/users-dashboard`,
    meta: {
      actions: ['pagodirecto-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <AdvertisingDashboard />,
    path: `${prefix}/advertising-dashboard`,
    meta: {
      actions: [ability.MANAGER],
      resources: ['Common']
    }
  },
  {
    element: <ApproveTransactions />,
    path: `${prefix}/approve-transactions`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <EnableRecharge />,
    path: `${prefix}/enable-recharge`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },

  {
    element: <Transfers />,
    path: `${prefix}/transfers`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },

  {
    element: <Notification />,
    path: `${prefix}/notifications`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.MARKETING, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <EventHistory />,
    path: `${prefix}/event-history`,
    meta: {
      actions: ['pagodirecto-level-0'],
      resources: ['Common']
    }
  },
  {
    element: <ValidateEvent />,
    path: `${prefix}/validate-event`,
    meta: {
      actions: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <RegisterUser />,
    path: `${prefix}/register-user`,
    meta: {
      actions: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <PayEvent />,
    path: `${prefix}/pay-event`,
    meta: {
      actions: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <UsersEvent />,
    path: `${prefix}/event`,
    meta: {
      actions: [ability.MANAGER, ability.PROMOTER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },

  {
    element: <Reports />,
    path: `${prefix}/users-reports`,
    meta: {
      actions: [ability.MANAGER, ability.SALES, ability.MARKETING],
      resources: ['Common']
    }
  },
  {
    element: <Transactions />,
    path: `${prefix}/transactions`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <MobilePaid />,
    path: `${prefix}/mobile-paid`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },

  {
    element: <Advertising />,
    path: `${prefix}/advertising`,
    meta: {
      actions: [ability.MANAGER, ability.SALES, ability.MARKETING],
      resources: ['Common']
    }
  },
  {
    element: <Vehicles />,
    path: `${prefix}/vehicles`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <Extensions />,
    path: `${prefix}/extensions`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <SearchUsers />,
    path: `${prefix}/search-users`,
    meta: {
      actions: [ability.MANAGER, ability.ATC, ability.SATC],
      resources: ['Common']
    }
  }
]

export default ParkingLotRoutes
