import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'
import {
  setNextPageExists,
  setZone,
  setLoading,
  setZonesOptions,
  setSelectedZone
} from './'
import {
  createZone,
  deleteZoneService,
  getZonesList,
  updateZoneService
} from '../../../services/parking-meters/zones'

export const getZones = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().zones.filters

      const { ok, res, error } = await getZonesList(filters)

      if (!ok) throw error

      dispatch(setZone(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getZonesOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().zones.filters
      const { pagination, ...filtersWithOutPagination } = filters

      const { ok, res, error } = await getZonesList(filtersWithOutPagination)

      if (!ok) throw error

      const zonesOptions = res.data.items.map((zone) => ({
        value: zone._id,
        label: zone.display_name,
        entity_commerce_id: zone?.entity_commerce?._id
      }))

      dispatch(setZonesOptions(zonesOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addZone = (body) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await createZone(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Zona creada correctamente')

      dispatch(getZones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateZone = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedZone = getState().zones.selectedZone

      const sendBody = {
        ...body,
        _id: selectedZone._id
      }

      const { ok, res, error } = await updateZoneService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Zona actualizada correctamente')

      dispatch(getZones())
      dispatch(setSelectedZone(res.data))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateZoneStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedZone = getState().zones.selectedZone

      const sendBody = {
        _id: selectedZone._id,
        active: !selectedZone.active
      }

      const { ok, res, error } = await updateZoneService(sendBody)

      if (!ok) throw error
      toast.success(res.message ?? 'Zona actualizada correctamente')

      dispatch(getZones())
      dispatch(setSelectedZone(res.data))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteZone = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const selectedZone = getState().zones.selectedZone

      const id = selectedZone._id

      const { ok, res, error } = await deleteZoneService(id)

      if (!ok) throw error
      toast.success(res.message ?? 'Zona eliminada correctamente')

      dispatch(getZones())
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
