import { apiMethods } from '@services/methods'
import { errors } from '@src/assets/constants/text'

import { authenticationURL, parkingMetersURL } from '@configs/apis'
import {
  setCustomerOptions,
  setFinerInfo,
  setLoading,
  setNextPageExists,
  setOperator,
  setRoles,
  setUsers
} from './slice'
import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import { log, logError } from '@craco/craco/lib/logger'
import {
  addPdUserServices,
  getCommerceWorkTeamService,
  getPdRolesServices,
  getPdUsersService,
  resetCommerceUserService,
  updateAbilityService
} from '../../services/work-team'

const endpoints = {
  loadUsers: `${authenticationURL}/admin/operational-users/list-team-members`,
  loadUsersSuperadmin: `${authenticationURL}/superadmin/operational-users/list-team-members`,
  addUsers: `${authenticationURL}/admin/operational-users/add`,
  addUsersSuperadmin: `${authenticationURL}/superadmin/operational-users/add`,
  updateUser: `${authenticationURL}/admin/operational-users/update`,
  updateUserSuperadmin: `${authenticationURL}/superadmin/operational-users/update`,
  addFinder: `${parkingMetersURL}/admin/finers/signup`,
  addFinderSubzones: `${parkingMetersURL}/admin/finers/asign-subzones`,
  resetUserPassword: `${authenticationURL}/admin/operational-users/reset-password`,
  resetUserPasswordSuperadmin: `${authenticationURL}/superadmin/operational-users/reset-password`,
  getFinerInfo: `${parkingMetersURL}/admin/finers/list-finers-subzones`,
  updateFinder: `${parkingMetersURL}/admin/finers/edit-finer`,
  validateCustomer: `${authenticationURL}/admin/operational-users/validate`,
  sendOtp: `${authenticationURL}/admin/users/send-otp`,
  getCommerceWorkTeam: `${parkingMetersURL}/superadmin/commerces/operational-user`
}

export const updateAbilityOperator = (data) => {
  return async (dispatch, getState) => {
    try {
      const { selectedOperator } = getState().workTeam
      dispatch(setLoading(true))

      const body = {
        userId: selectedOperator._id,
        ability: data?.ability
      }

      const { ok, res, error } = await updateAbilityService(body)
      if (!ok) throw error

      toast.success(res?.message || 'Permisos actualizados correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const resetCommerceUser = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedOperator } = getState().workTeam

      dispatch(setLoading(true))

      const body = {
        operational_user_id: selectedOperator?._id
      }
      const { ok, res, error } = await resetCommerceUserService(body)

      if (!ok) throw error

      toast.success(res?.message || 'Contraseña restablecida correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommerceWorkTeam = (id) => {
  return async (dispatch, getState) => {
    try {
      if (!id) return

      dispatch(setLoading(true))
      const body = {
        commerce_id: id
      }
      const { ok, res, error } = await getCommerceWorkTeamService(body)

      if (!ok) throw error

      dispatch(setUsers(res.users ?? []))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getPdUsers = () => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      if (!isSuperadmin) return
      dispatch(setLoading(true))

      const { ok, res, error } = await getPdUsersService()

      if (!ok) throw error

      dispatch(setUsers(res.users))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getPdRoles = () => {
  return async (dispatch, getState) => {
    try {
      const { ok, res, error } = await getPdRolesServices()

      if (!ok) throw error

      const rolesOptions = res.roles.map((role) => {
        return {
          value: role._id,
          label: role.name
        }
      })
      dispatch(setRoles(rolesOptions))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addPdUsers = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { ok, res, error } = await addPdUserServices(body)

      if (!ok) throw error
      toast.success(res.message ?? 'Usuario creado correctamente')
      dispatch(setUsers(res.users))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const sendOtp = () => {
  return async (dispatch, getState) => {
    const body = {
      customerId: getState().workTeam.customerSelected._id
    }

    const { ok, res } = await apiMethods.authPost({
      url: `${endpoints.sendOtp}`,
      body
    })
    console.log(ok, res)

    if (!ok) {
      toast((t) => <BasicToastContent t={t} message={res} type={'danger'} />)
      throw res
    }

    toast((t) => (
      <BasicToastContent t={t} message={res.data.message} type={'success'} />
    ))
  }
}

export const validateCustomer = (body) => {
  return async (dispatch, getState) => {
    const { ok, res, extra } = await apiMethods.authPost({
      url: `${endpoints.validateCustomer}`,
      body
    })

    if (!ok) {
      toast((t) => <BasicToastContent t={t} message={res} type={'danger'} />)

      if (extra) {
        const customerOptions = extra.customers.map((customer) => {
          return {
            value: customer._id,
            label: `${customer.username}`,
            ...customer
          }
        })

        dispatch(setCustomerOptions(customerOptions))
      }
      throw res
    }
  }
}

export const getFinerInfo = () => {
  return async (dispatch, getState) => {
    const body = {
      finerId: getState().workTeam.selectedOperator._id
    }
    const { ok, res } = await apiMethods.authPost({
      url: `${endpoints.getFinerInfo}`,
      body
    })

    ok ? dispatch(setFinerInfo(res.data)) : dispatch(setFinerInfo({}))
  }
}

export const loadUsersRequest = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { isSuperadmin } = getState().superadmin
    const url = isSuperadmin
      ? endpoints.loadUsersSuperadmin
      : endpoints.loadUsers

    const { ok, res } = await apiMethods.authPost({
      url,
      body: getState().workTeam.filters
    })

    dispatch(setLoading(false))
    if (ok) {
      dispatch(setUsers(res.data.users.items))
      dispatch(setNextPageExists(res.data.users.nextPageExists))

      const roles = res.data.roles.map((role) => {
        return { value: role._id, label: role.name }
      })

      dispatch(setRoles(roles))
    }
  }
}

export const addUserRequest = (payload) => {
  return async (dispatch, getState) => {
    try {
      payload.username = payload.username.trim().toLowerCase()
      const { isSuperadmin } = getState().superadmin

      const url = !isSuperadmin
        ? endpoints.addUsers
        : endpoints.addUsersSuperadmin

      const { ok, res } = await apiMethods.authPost({
        url,
        body: {
          ...payload,
          active: true
        }
      })

      if (!ok) throw res
      toast.success(res.message ?? 'Operador creado exitosamente')
      dispatch(setOperator(res.data.new_user))
      dispatch(loadUsersRequest())
    } catch (err) {
      toast.error(err ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addFinerRequest = (payload) => {
  return async (dispatch, getState) => {
    const operator = getState().workTeam.selectedOperator

    const finer = {
      ...payload,
      operational_user_id: operator._id,
      first_name: operator.profile.name,
      second_name: '',
      first_lastname: operator.profile.last_name,
      second_lastname: '',
      username: operator.username,
      email: operator.email,
      associated_entity_commerce: operator.commerce_associated._id
    }

    const { ok, res } = await apiMethods.authPost({
      url: endpoints.addFinder,
      body: finer
    })

    if (!ok) {
      toast((t) => <BasicToastContent t={t} message={res} type={'danger'} />)
      return
    }

    dispatch(getFinerInfo())
    toast((t) => (
      <BasicToastContent
        t={t}
        message={'Multador creado exitosamente'}
        type={'success'}
      />
    ))
  }
}

export const resetUserPassword = () => {
  return async (dispatch, getState) => {
    const body = {
      operational_user_id: getState().workTeam.selectedOperator._id
    }

    const { isSuperadmin } = getState().superadmin
    const url = !isSuperadmin
      ? endpoints.resetUserPassword
      : endpoints.resetUserPasswordSuperadmin

    const { ok, res } = await apiMethods.authPut({
      url,
      body
    })

    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'No fue posible restablecer la contraseña'}
          type={'danger'}
        />
      ))
      return
    }

    toast((t) => (
      <BasicToastContent
        t={t}
        message={'Contraseña restablecida exitosamente'}
        type={'success'}
      />
    ))
  }
}

export const updateFinderZones = (payload) => {
  return async (dispatch, getState) => {
    const body = {
      ...payload,
      finerId: getState().workTeam.selectedOperator._id
    }

    const { ok, res } = await apiMethods.authPost({
      url: `${endpoints.addFinderSubzones}`,
      body
    })

    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'No fue posible asignar los parquímetros'}
          type={'danger'}
        />
      ))
      return
    }

    dispatch(getFinerInfo())

    toast((t) => (
      <BasicToastContent
        t={t}
        message={'Parquímetros asignados exitosamente'}
        type={'success'}
      />
    ))
  }
}

export const updateFinderInfo = (payload) => {
  return async (dispatch, getState) => {
    const body = {
      ...payload,
      finerId: getState().workTeam.selectedOperator._id
    }
    const { ok, res } = await apiMethods.authPost({
      url: `${endpoints.updateFinder}`,
      body
    })

    console.log(ok, res)

    if (!ok) {
      toast((t) => (
        <BasicToastContent
          t={t}
          message={'No fue posible editar al multador'}
          type={'danger'}
        />
      ))
      return
    }
    dispatch(getFinerInfo())
  }
}

export const updateOperatorStatus = (payload, finer) => {
  return async (dispatch, getState) => {
    try {
      const body = {
        user_id: getState().workTeam.selectedOperator._id,
        updateFields: {
          ...payload
        }
      }

      const { isSuperadmin } = getState().superadmin
      const url = !isSuperadmin
        ? endpoints.updateUser
        : endpoints.updateUserSuperadmin

      const { ok, res } = await apiMethods.authPost({
        url,
        body
      })

      if (!ok) throw res
      toast.success(res.message ?? 'Operador editado exitosamente')
      dispatch(setOperator(res.data.updated_user))
      dispatch(loadUsersRequest())
    } catch (error) {
      toast.error(error ?? errors.GENERAL_ERR)
      throw error
    }
  }
}
