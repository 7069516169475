import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'billingHistory',
  initialState,
  reducers: {
    setBillingHistory: (state, action) => {
      state.data = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setBillingHistoryFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedBilling: (state, action) => {
      state.selectedBillingHistory = action.payload
    }
  }
})

export const {
  setBillingHistory,
  setLoading,
  setBillingHistoryFilters,
  setNextPageExists,
  setSelectedBilling
} = slice.actions

export const billingHistory = slice.reducer
