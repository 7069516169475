// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const defaultForm = {
  code: '',
  display_name: '',
  short_name: '',
  description: '',
  active: true,
  area_id: '',
  concession_commerce_id: '63882e1dbb7e036a8ee93fe5'
}

const initialState = {
  data: [],
  statePagination: false,
  showModal: false,
  isUpdate: false,
  zoneForm: defaultForm,
  updateForm: defaultForm,
  areaList: [{ value: undefined, label: 'Seleccione' }]
}

export const superadminZoneSlice = createSlice({
  name: 'superadminZone',
  initialState,
  reducers: {
    setZones: (state, action) => {
      state.data = action.payload.items
      state.statePagination = action.payload.nextPageExists
    },
    setOpenModal: (state, action) => {
      state.showModal = action.payload
    },
    setZone: (state, action) => {
      state.updateForm = action.payload
    },
    setUpdate: (state, action) => {
      state.isUpdate = action.payload
    },
    setArea: (state, action) => {
      state.areaList = action.payload
    }
  }
})

export const { setZones, setOpenModal, setZone, setUpdate, setArea } =
  superadminZoneSlice.actions

export const superadminZone = superadminZoneSlice.reducer
