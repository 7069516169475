import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'bankTransfers',
  initialState,
  reducers: {
    setBankTransfers: (state, action) => {
      state.data = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setAccountStatus: (state, action) => {
      state.accountStatus = action.payload
    },
    setAccountStatusOptions: (state, action) => {
      state.accountStatusOptions = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setBankTransfersFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedBankTransfer: (state, action) => {
      state.selectedBankTransfer = action.payload
    },
    setTransferBody: (state, action) => {
      state.transferBody = action.payload
    },
    setCommercesBancamigaBankOptions: (state, action) => {
      state.commercesBancamigaBankOptions = action.payload
    },
    setCommercesBankOptions: (state, action) => {
      state.commercesBankOptions = action.payload
    },
    setCommercesOtherBankOptions: (state, action) => {
      state.commercesOtherBankOptions = action.payload
    },
    setSelectedBankAccount: (state, action) => {
      state.selectedBankAccount = action.payload
    }
  }
})

export const {
  setBankTransfers,
  setLoading,
  setAccountStatusOptions,
  setAccountStatus,
  setBankTransfersFilters,
  setNextPageExists,
  setSelectedBankTransfer,
  setData,
  setTransferBody,
  setCommercesBancamigaBankOptions,
  setCommercesOtherBankOptions,
  setCommercesBankOptions,
  setSelectedBankAccount
} = slice.actions

export const bankTransfers = slice.reducer
