// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const defaultFilter = {
  pagination: {
    page: 1,
    limit: 13
  },
  filterOptions: {
    areas_ids: [],
    zones_ids: [],
    subzones_ids: [],
    entity_commerces_ids: []
  }
}

const initialState = {
  lastTickets: [],
  loading: true,
  nextPageExists: false,
  filter: defaultFilter,
  daysOptions: [
    { label: 'Día', value: 'daily' },
    { label: 'Semana', value: 'weekly' },
    { label: 'Mes', value: 'monthly' },
    { label: 'Rango de fecha', value: 'custom' }
  ],
  areaOptions: [],
  zonesOptions: [],
  subzonesOptions: [],
  selectedTicket: {},
  xAxisData: [],
  currentPeriodData: [],
  previusPeriodData: [],
  currentPeriodAmount: {},
  usdAmount: 0,
  amount: 0,
  tickets: 0,

  xTemporalAxisData: [],
  temporalPeriodData: [],
  temporalPeriodDataAmount: [],
  zoneDistribution: [],

  entity_commerces_ids: [],

  income: {}
}

export const parkingDashboardSlice = createSlice({
  name: 'ticketReducer',
  initialState,
  reducers: {
    setCommerce: (state, action) => {
      state.entity_commerces_ids = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedTicket: (state, action) => {
      state.selectedTicket = action.payload
    },
    setAreas: (state, action) => {
      state.areaOptions = action.payload
    },
    setZones: (state, action) => {
      state.zonesOptions = action.payload
    },
    setSubzones: (state, action) => {
      state.subzonesOptions = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setLastTickets: (state, action) => {
      state.lastTickets = action.payload
    },
    setxAxisData: (state, action) => {
      state.xAxisData = action.payload
    },
    setCurrentPeriodData: (state, action) => {
      state.currentPeriodData = action.payload
    },
    setPreviusPeriodData: (state, action) => {
      state.previusPeriodData = action.payload
    },
    setCurrentPeriodAmount: (state, action) => {
      state.currentPeriodAmount = action.payload
    },
    setUsdAmount: (state, action) => {
      state.usdAmount = action.payload
    },
    setTickets: (state, action) => {
      state.tickets = action.payload
    },
    setdAmount: (state, action) => {
      state.amount = action.payload
    },
    setTemporalAxisData: (state, action) => {
      state.xTemporalAxisData = action.payload
    },
    setTemporalPeriodData: (state, action) => {
      state.temporalPeriodData = action.payload.tickets
      state.temporalPeriodDataAmount = action.payload.amount
    },
    setZoneDistribution: (state, action) => {
      state.zoneDistribution = action.payload
    },
    setIncome: (state, action) => {
      state.income = action.payload
    }
  }
})

export const {
  setCommerce,
  setFilter,
  setAreas,
  setZones,
  setSubzones,
  setLoading,
  setLastTickets,
  setSelectedTicket,
  setNextPageExists,
  setxAxisData,
  setCurrentPeriodData,
  setPreviusPeriodData,
  setCurrentPeriodAmount,
  setUsdAmount,
  setTickets,
  setdAmount,
  setTemporalAxisData,
  setTemporalPeriodData,
  setZoneDistribution,
  tickets,
  setIncome
} = parkingDashboardSlice.actions

export const parkingDashboard = parkingDashboardSlice.reducer
