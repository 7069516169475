import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getZonesSuperadmin: `${parkingMetersURL}/superadmin/zones/list`,
  getZones: `${parkingMetersURL}/admin/zones/list`,
  getZonesPos: `${parkingMetersURL}/pos/zones/list`,
  createZone: `${parkingMetersURL}/superadmin/zones`,
  updateZone: `${parkingMetersURL}/superadmin/zones`,
  deleteZone: `${parkingMetersURL}/superadmin/zones`
}

export const getZonesList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getZonesSuperadmin,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getZonesAdmin = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getZones,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getZonesPos = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getZonesPos,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createZone = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createZone,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateZoneService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.updateZone,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteZoneService = async (id) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: `${endpoints.deleteZone}/${id}`
    })
    return response
  } catch (error) {
    return error
  }
}
