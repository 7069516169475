import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'subzones',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSubzone: (state, action) => {
      state.data = action.payload
    },
    setSubzoneOptions: (state, action) => {
      state.subzoneOptions = action.payload
    },

    setSubzoneFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedSubzone: (state, action) => {
      state.selectedSubzone = action.payload
    }
  }
})

export const {
  setSubzoneFilters,
  setLoading,
  setSubzone,
  setNextPageExists,
  setSelectedSubzone,
  setSubzoneOptions
} = slice.actions

export const subzones = slice.reducer
