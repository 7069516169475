import { lazy } from 'react'
import { Navigate } from 'react-router-dom'


const History = lazy(() => import('@views/parking-meters/history/history'))
const ZonesAdmin = lazy(() => import('@views/parking-meters/zones-admin'))
const SubzoneAdmin = lazy(() => import('@views/parking-meters/subzone-admin'))
const AreaAdmin = lazy(() => import('@views/parking-meters/areas-admin'))
const ReportFiles = lazy(() =>
  import('@views/pages/parking-meters/reports-files')
)

const ZonesList = lazy(() => import('@views/pages/parking-meters/zones-list'))
const SubzoneList = lazy(() =>
  import('@views/pages/parking-meters/subzones-list')
)
const AreaList = lazy(() => import('@views/pages/parking-meters/areas-list'))
const EntityList = lazy(() => import('@views/pages/parking-meters/entity-list'))
const TowCompanyList = lazy(() =>
  import('@views/pages/parking-meters/tow-company-list')
)
const AssociatedCompany = lazy(() =>
  import('@views/pages/parking-meters/associated-company')
)

const Transactions = lazy(() =>
  import('@views/pages/parking-meters/statistics-transactions')
)
const Dashboard = lazy(() =>
  import('@views/pages/parking-meters/statistics-dashboard')
)
const Penalty = lazy(() =>
  import('@views/pages/parking-meters/vehicle-control-penalty')
)
const Reports = lazy(() =>
  import('@views/pages/parking-meters/vehicle-control-report')
)


const ability = {
  MANAGER: 'manager-level-1',
  SALES: 'sales-level-1',
  MARKETING: 'marketing-level-1',
  ATC: 'atc-level-1',
  SATC: 'satc-level-1',
  FINANCE: 'finance-level-1',
  SYSTEM: 'sistem-level-1',
  PROMOTER: 'promoter-level-1'
}

const ParkingMetersRoutes = [
  {
    element: <ReportFiles />,
    path: '/parking-meters/reports-files',
    meta: {
      actions: [
        ability.MANAGER,
        ability.FINANCE,
        ability.SALES,
        ability.MARKETING,
        ability.SATC
      ],
      resources: ['Common']
    }
  },
  {
    element: <TowCompanyList />,
    path: '/parking-meters/tow-company-list',
    meta: {
      actions: ['pagodirecto-level-1'],
      resources: ['Common']
    }
  },
  {
    element: <AssociatedCompany />,
    path: '/parking-meters/associated-company',
    meta: {
      actions:  [ability.MANAGER, ability.SALES, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <EntityList />,
    path: '/parking-meters/entity-list',
    meta: {
      actions: [ability.MANAGER, ability.SALES, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <ZonesList />,
    path: '/parking-meters/zones-list',
    meta: {
      actions: [ability.MANAGER, ability.SALES, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <SubzoneList />,
    path: '/parking-meters/subzone-list',
    meta: {
      actions: [ability.MANAGER, ability.SALES, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <AreaList />,
    path: '/parking-meters/area-list',
    meta: {
      actions: [ability.MANAGER, ability.SALES, ability.SATC],
      resources: ['Common']
    }
  },
  {
    element: <Dashboard />,
    path: '/parking-meters/dashboard',
    meta: {
      actions: ['ente-level-2', 'pos-level-2'],
      resources: ['parkingMeterSystem', 'Common']
    }
  },
  {
    element: <Penalty />,
    path: '/parking-meters/penalty',
    meta: {
      actions: ['ente-level-0', ability.MANAGER, ability.SATC, ability.ATC],
      resources: ['parkingMeterSystem', 'Common']
    }
  },
  {
    element: <Reports />,
    path: '/parking-meters/reports',
    meta: {
      actions: ['ente-level-0', ability.MANAGER, ability.SATC, ability.ATC],
      resources: ['parkingMeterSystem', 'Common']
    }
  },
  {
    element: <Transactions />,
    path: '/parking-meters/transactions',
    meta: {
      actions: [
        'ente-level-0',
        'pos-level-2',
        ability.MANAGER,
        ability.SATC,
        ability.ATC
      ],
      resources: ['parkingMeterSystem', 'Common']
    }
  },

  {
    element: <History />,
    path: '/parking-meters/list',
    meta: {
      actions: ['concesion-level-1'],
      resources: ['tollSystem']
    }
  },

  {
    element: <ZonesAdmin />,
    path: '/parking-meters/zones',
    meta: {
      actions: ['concesion-level-1'],
      resources: ['tollSystem']
    }
  },
  {
    element: <SubzoneAdmin />,
    path: '/parking-meters/subzone',
    meta: {
      actions: ['concesion-level-1'],
      resources: ['tollSystem']
    }
  },
  {
    element: <AreaAdmin />,
    path: '/parking-meters/area',
    meta: {
      actions: ['concesion-level-1'],
      resources: ['tollSystem']
    }
  }
]

export default ParkingMetersRoutes
