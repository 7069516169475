import { apiMethods } from '@services/methods'
import { couponsURL, baseV2URL, micropd03, baseV1URL } from '@configs/apis'

const endpoints = {
  createExtension: `${baseV2URL}/superadmin/user/extension/add`,
  deleteCustomerExtension: `${baseV2URL}/superadmin/user/extension/delete`,
  updateExtension: `${baseV2URL}/superadmin/user/extension/update`,
  getSchoolsOptions: `${baseV1URL}/child/info`
}

export const getSchoolsOptionsServices = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getSchoolsOptions}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteCustomerExtensionService = async (body) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: `${endpoints.deleteCustomerExtension}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createExtensionService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateExtensionService = async (body) => {
  try {
    const response = await apiMethods.authPatchFetch({
      url: endpoints.updateExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

// export const getExtensionList = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetch({
//       url: endpoints.getExtension,
//       body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const deleteAdvertisingService = async (id) => {
//   try {
//     const response = await apiMethods.authDeleteFetch({
//       url: `${endpoints.deleteAdvertising}/${id}`
//     })

//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const updateAdvertisingService = async (body) => {
//   try {
//     const response = await apiMethods.authPatchFetchFormData({
//       url: endpoints.createExtension,
//       formData: body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const getCouponsUsesHistoryList = async (body) => {
//   try {
//     const response = await apiMethods.authPostFetch({
//       url: endpoints.getCouponsUsesHistoryList,
//       body
//     })
//     return response
//   } catch (error) {
//     return error
//   }
// }

// export const searchUserService = async (body) => {
//   try {
//     const response = await apiMethods.authGetFetch(
//       `${endpoints.searchUser}?input=${body}&show=0&page=100`
//     )
//     return response
//   } catch (error) {
//     return error
//   }
// }
