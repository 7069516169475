import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'accountPosition',
  initialState,
  reducers: {
    setAccountPosition: (state, action) => {
      state.data = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setAccountStatus: (state, action) => {
      state.accountStatus = action.payload
    },
    setAccountStatusOptions: (state, action) => {
      state.accountStatusOptions = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setAccountPositionFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setAccountPosition: (state, action) => {
      state.selectedAccountPosition = action.payload
    }
  }
})

export const {
  setAccountPosition,
  setLoading,
  setAccountStatusOptions,
  setAccountStatus,
  setAccountPositionFilters,
  setNextPageExists,
  setSelectedBilling,
  setData
} = slice.actions

export const accountPosition = slice.reducer
