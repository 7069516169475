import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'payEvent',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setEvents: (state, action) => {
      state.data = action.payload
    },
    setSelectedStock: (state, action) => {
      state.selectedStock = action.payload
    },
    setStockOptions: (state, action) => {
      state.stockOptions = action.payload
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },
    setSelectedReceptor: (state, action) => {
      state.selectedReceptor = action.payload
    },
    setValidateEventDetails: (state, action) => {
      state.validateEventDetails = action.payload
    },
    setExchangeRate: (state, action) => {
      state.exchangeRate = action.payload
    },
    setExtraFields: (state, action) => {
      state.extraFields = action.payload
    },
    setFormExtraFields: (state, action) => {
      state.formExtraFields = action.payload
    }
  }
})

export const {
  setLoading,
  setEvents,
  setSelectedEvent,
  setSelectedUser,
  setSelectedReceptor,
  setValidateEventDetails,
  setSelectedStock,
  setStockOptions,
  setExchangeRate,
  setExtraFields,
  setFormExtraFields
} = slice.actions

export const payEvent = slice.reducer
