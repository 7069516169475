import { apiMethods } from '@services/methods'
import { tollsURL } from '@configs/apis'

import {
  setBundleData,
  setLoading,
  setNextPageExists,
  setVehiclesTypes
} from './slice'
import { BasicToastContent } from '@src/components/basic-toast-content'
import toast from 'react-hot-toast'
import { log, logError } from '@craco/craco/lib/logger'

const endpoints = {
  bundlesTransactions: `${tollsURL}/admin/transactions/bundles`,
  bundles: `${tollsURL}/admin/premium/bundles`,
  superadminBundles: `${tollsURL}/superadmin/premium/bundles`,
  typeVehicles: `${tollsURL}/admin/vehicles/types`,
  typeVehiclesSuperadmin: `${tollsURL}/superadmin/vehicles/types`
}

export const bundle = (body) => {
  return apiMethods.authPost({ url: endpoints.bundles, body })
}

export const SuperadminBundle = (body) => {
  return apiMethods.authPost({ url: endpoints.superadminBundles, body })
}

export const getVehicles = (isSuperadmin) => {
  const url = isSuperadmin
    ? endpoints.typeVehiclesSuperadmin
    : endpoints.typeVehicles

  return apiMethods.authGet({ url })
}

export const getBundles = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().bundles
      const { isSuperadmin } = getState().tolls

      const { ok, res } = await apiMethods.authPost({
        url: isSuperadmin ? endpoints.superadminBundles : endpoints.bundles,
        body: filters
      })

      if (!ok) throw res

      dispatch(setBundleData(res.data.items))
      dispatch(setNextPageExists(res.data.nextPageExists))
      dispatch(setLoading(false))
    } catch (error) {
      toast((t) => <BasicToastContent t={t} message={error} type={'danger'} />)
    }
  }
}
