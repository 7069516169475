import { createSlice } from '@reduxjs/toolkit'
import {
  getAllData,
  getData,
  getUser,
  selectUser,
  usersAutocomplete,
  addUserRequest,
  addVehicle,
  getBundles
} from './thunk'

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    vehicles: [],
    bundles: [],
    filterBundles: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    selectedVehicle: {},
    vehicleResponse: null,
    isAddUser: false,
    showUserList: false,
    showVehiclesList: false,
    updateModal: false,
    bundleModal: false,
    vehicleTypeOptions: [
      { label: 'Vehículo ligero', value: 'light-car' },
      { label: 'Vehículo pesado', value: 'light-duty' }
    ],
    isTagModalOpen: false
  },
  reducers: {
    setIsTagModalOpen: (state, action) => {
      state.isTagModalOpen = action.payload
    },
    addUser: (state, action) => {
      state.isAddUser = action.payload
    },
    showUser: (state, action) => {
      state.showUserList = action.payload
    },
    showVehicle: (state, action) => {
      state.showVehiclesList = action.payload
    },
    setUser: (state, action) => {
      state.selectedUser = action.payload.data
      state.vehicles = action.payload.vehicles
      state.showVehiclesList = action.payload.flag
    },
    setUpdateModal: (state, action) => {
      state.updateModal = action.payload
    },
    setBundleModal: (state, action) => {
      state.bundleModal = action.payload
    },
    setVehicle: (state, action) => {
      state.selectedVehicle = action.payload
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload
    },
    setFilterBundles: (state, action) => {
      state.filterBundles = action.payload
    },
    setVehicleTypeOptions: (state, action) => {
      state.vehicleTypeOptions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserRequest.fulfilled, (state, action) => {
        state.selectedUser = action.payload.data
        state.data = action.payload.list
        state.showUserList = action.payload.flag
      })
      .addCase(addVehicle.fulfilled, (state, action) => {
        // state.selectedUser = action.payload.data
        // state.isAddUser = action.payload.flag
        // state.showVehiclesList = true
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(selectUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(usersAutocomplete.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getBundles.fulfilled, (state, action) => {
        state.bundles = action.payload
      })
  }
})

export const {
  addUser,
  showUser,
  showVehicle,
  setUser,
  setUpdateModal,
  setVehicle,
  setBundleModal,
  setVehicles,
  setFilterBundles,
  setVehicleTypeOptions,
  setIsTagModalOpen
} = appUsersSlice.actions

export const usersList = appUsersSlice.reducer
