import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'extensions',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setExtensions: (state, action) => {
      state.data = action.payload
    },

    setExtensionsFilters: (state, action) => {
      state.filters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedEExtension: (state, action) => {
      state.selectedExtension = action.payload
    },
    setSelectedExtensionList: (state, action) => {
      state.selectedExtensionList = action.payload
    },
    setSchoolOptions: (state, action) => {
      state.schoolOptions = action.payload
    },
    setSelectedUserExtension: (state, action) => {
      state.selectedUserExtension = action.payload
    }
  }
})

export const {
  setExtensionsFilters,
  setLoading,
  setAdvertising,
  setNextPageExists,
  setSelectedAdvertising,
  setSelectedEExtension,
  setSelectedExtensionList,
  setSchoolOptions,
  setSelectedUserExtension
} = slice.actions

export const extensions = slice.reducer
