import { apiMethods } from '@services/methods'
import { parkingMetersURL } from '@configs/apis'

const endpoints = {
  getEntities: `${parkingMetersURL}/superadmin/commerce/entity/list`,
  createEntity: `${parkingMetersURL}/superadmin/commerce/entity`,
  updateEntity: `${parkingMetersURL}/superadmin/commerce/entity`
}

export const getEntitiesList = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getEntities,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const createEntity = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.createEntity,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const updateEntityService = async (body) => {
  try {
    const response = await apiMethods.authPutFetch({
      url: endpoints.updateEntity,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteEntityService = async (body) => {
  try {
    const response = await apiMethods.authDeleteFetch({
      url: endpoints.updateEntity,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
